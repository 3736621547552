@import './index.scss';
.freeze-selector {
  button.freeze {
    border-bottom: 2px solid black;
    font-weight: 500;
  }
}
.restaurant-order-matrix {
  @extend .flex-by-row-left;
  align-items: flex-start;
  width: 100vw;
  padding-left: 200px;

  .restaurant-orders-on-date {
    @extend .flex-by-column-top;
    padding-top: 100px;
    width: 100px;
    position: relative;
    .date,
    .restaurant {
      height: 100px;
      width: 100px;
    }
    .restaurant {
      // border: 1px solid black;
      @extend .text-12-300;
      @extend .flex-by-column-top-left;
      .order-date,
      .order-restaurant {
        @extend .overflow-ellipsis;
      }
      .order-details {
        @extend .flex-by-row-centered;
        .order-dishes {
          font-weight: 500;
          font-size: 16px;
          opacity: 0.8;
        }
        .order-orders {
          font-weight: 500;
          font-size: 16px;
        }
      }
    }
    .restaurant.lunch {
      .order-orders,
      .order-dishes {
        color: var(--background-orange);
      }
    }
    .restaurant.dinner {
      .order-orders,
      .order-dishes {
        color: var(--background-purple);
      }
    }

    .restaurant.empty {
      .order-orders {
        color: transparent;
      }
      .order-dishes {
        color: transparent;
      }
    }
    .date {
      height: 100px;
      background-color: turquoise;
    }
    .date.freeze-date {
      position: fixed;
      top: calc(var(--nav-height) + 20px);
    }
  }
  .restaurant-orders-on-date:nth-child(1) {
    z-index: 100;
  }
  .restaurant-orders-on-date:nth-child(even) {
    .restaurant:nth-child(even) {
      background-color: var(--background-grey);
    }
  }
  .restaurant-orders-on-date:nth-child(odd) {
    .restaurant:nth-child(odd) {
      background-color: var(--background-grey);
    }
  }
  .restaurant-orders-on-date {
    .restaurant:nth-child(1) {
      z-index: 100;
    }
  }
  .restaurant-orders-on-date.restaurant-names {
    width: 200px;
    background-color: wheat;
    .date {
      width: 200px;
      background-color: white;
    }
  }
  .restaurant-orders-on-date.restaurant-names.freeze-restaurant {
    position: fixed;
    left: 0px;
  }
}
