@import "./index.scss";
nav {
  height: auto;
  @extend .flex-by-row-centered;
  padding: 0px;
  z-index: 100;
  border-bottom: 1px solid var(--border-grey);
  .nav-content {
    @extend .flex-by-row-centered;
    height: var(--nav-height);
    padding: 0px 10px 0px 40px;
    width: 100%;
    justify-content: flex-end;
    .logo {
      position: relative;
      // width: 1px;
      height: var(--nav-height);
      // margin-right: calc(50vw - 40px);
      @extend .flex-by-row-centered;
      // margin-right: 30px;
      .background-image {
        display: block;
        background-image: url("../../../assets/images/logo_dark_300x200.png");
        // height: calc(var(--nav-height) - 0px);
        height: 50px;
        width: 75px;
        background-size: cover;
        margin-right: 30px;
        @media only screen and (max-width: 350px) {
          width: 110px;
          height: 60px;
        }
        // position: absolute;
        // right: 0px;
        // top: 5px;
      }
      span {
        display: none;
      }
    }
    .logo.light {
      .background-image {
        background-image: url("../../../assets/images/logo_light_300x200.png");
      }
    }
    .nav-title {
      height: var(--nav-height);
      @extend .flex-by-column-centered;
      // justify-content: space-between;
      // width: 100%;
      width: auto;

      .tag-line {
        // display: none;
        @extend .text-11-300;
        // font-size: 11px;
        letter-spacing: 0.8px;
        text-align: center;
        line-height: 1.5;
        @extend .flex-by-row-centered;
        max-width: 100%;
        flex-wrap: wrap;
        text-transform: capitalize;
        margin: 6px 0px 2px 0px;
        .city-names {
          width: 100%;
          line-height: 1.5;
          font-size: 10px;
          padding: 0px 10px;
          letter-spacing: 0.8px;
          b {
            font-weight: 500;
          }
          text-align: center;
        }
      }
      .stops-to-go {
        @extend .text-16-400;
        text-transform: capitalize;
        b {
          font-weight: 600;
          font-size: 18px;
        }
      }
    }

    button.user {
      position: absolute;
      left: 0px;
      width: 60px;
      height: calc(var(--nav-height) - 1px);
      z-index: 1;
      background-color: transparent;
      // background-color: white;
      box-shadow: none;
      svg {
        font-size: 22px;
        color: white;
      }
      img {
        width: 26px;
        height: 26px;
        border-radius: 50%;
      }
      .avatar {
        width: 27px;
        height: 27px;
      }
      svg:nth-child(2) {
        font-size: 12px;
        margin-left: 8px;
      }
    }

    .cart-thumbnail {
      position: absolute;
      right: 0px;
    }
    .admin-quick-access {
      position: fixed;
      top: auto;
      left: 0px;
      bottom: -1px;
      // width: 100vw;
      height: 100px;
      background-color: var(--background-beige);
      border-top: 1px solid var(--border-grey);
      // height: auto;
      padding-left: 100px;
      button.toggle-quick-access {
        @extend .card;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: black;
        flex-shrink: 0;
        position: fixed;
        left: 20px;
        bottom: 20px;
        color: white;
        @extend .text-12-300;
        line-height: 1.2;
        font-weight: 500;
      }
      .quick-access-container {
        width: auto;
        overflow-y: auto;
        @extend .flex-by-row-left;

        padding: 0px 15px;
        box-sizing: border-box;

        a {
          padding: 0px;
          width: 100px;
          height: 100px;
          @extend .flex-by-column-centered;
          margin-right: 20px;
          svg {
            margin-bottom: 10px;
            font-size: 20px;
          }
          span {
            @extend .text-12-300;
            text-transform: capitalize;
            line-height: 1;
          }
          color: #aaa;
          border-bottom: 2px solid white;
        }
        a:first-child {
          margin-left: 15px;
        }
        a.selected {
          color: black;
        }
      }
      .quick-access-container::-webkit-scrollbar {
        display: none;
      }
    }
    .admin-quick-access.show {
      width: 100vw;
      background-color: var(--background-beige);
      border-top: 1px solid var(--border-grey);
    }
    .admin-quick-access.hide {
      width: 80px;
      background-color: transparent;
      border: none;
    }
    .user-menu {
      left: 0px;
      // background-color: yellow;
      z-index: 102;
      max-height: calc(100vh - var(--nav-height));
      li {
        // .icon-wrapper {
        //   @extend .flex-by-row-between;
        //   position: absolute;
        //   left: 0px;
        display: flex;
        flex-shrink: 1;
        svg {
          // position: relative;
          margin: 0px;
        }
        svg.fa-user {
          margin-left: 3px;
        }
        // svg:last-child {
        //   // position: absolute;
        //   // left: 50px;
        //   margin-left: 4px;
        //   font-size: 12px;
        //   position: relative;
        //   bottom: 3px;
        // }
        // }
      }
    }
    .go-to-weekly {
      // background-color: red;
      svg {
        font-size: 20px;
      }
    }
    .select-city {
      // background-color: yellow;
      position: absolute;
      right: 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.6);
      @extend .flex-by-row-left;
      .hi {
        font-weight: 300;
        font-size: 13px;
        letter-spacing: 0.4px;
        margin-right: 6px;
      }
      .change-city {
        button {
          border: none;
          font-size: 13px;
          letter-spacing: 0.4px;
          @extend .flex-by-row-centered;

          svg {
            margin-left: 6px;
            // margin-bottom: 2px;
            font-size: 16px;
          }
        }
      }
      .change-city.anonymous {
        button {
          svg {
            display: none;
          }
        }
      }
    }
  }
}
nav.transparent {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom: none;
}
nav.no-shadow {
  box-shadow: none;
  // border: none;
}
.driver-title {
  @extend .text-16-400;
  padding-right: 25px;
  text-transform: capitalize;
  .driver-name {
    font-weight: 500;
  }
}
.restaurant-title {
  width: 100%;
  @extend .flex-by-row-centered;
  span {
    display: inline-block;
    width: 100%;
    @extend .text-title-16;
    text-align: center;
    margin: 0px;
  }
}

nav.admin-view,
nav.ops-view,
nav.marketer-view {
  .nav-title {
    display: none;
  }
  height: 50px;
  .nav-content {
    button.user {
      height: 50px;
    }
  }
}

ul.view-day-selections {
  position: absolute;
  top: 2px;
  right: -20px;
  @extend .card;
  width: 150px;
  padding: 15px 20px;
  li {
    width: 100%;
    margin-bottom: 10px;
    button {
      width: 100%;
      padding: 0px;
      @extend .flex-by-row-between;
      span {
        text-align: left;
        @extend .text-12-300;
      }
    }
  }
  li:last-child {
    margin-bottom: 0px;
  }
}
.select-view-mode {
  // width: 250px;
  @extend .flex-by-row-even;
  width: calc(100vw - 140px);
  .by-day {
    .popup-container {
      position: relative;
    }
  }
  button.view-mode {
    @extend .action-underline;
    padding: 0px;
    @extend .text-12-300;
    svg {
      margin-left: 10px;
    }
  }
  button.view-mode:disabled {
    // border-bottom: 1px solid transparent;
    pointer-events: none;
    svg {
      color: transparent;
    }
  }

  button.view-mode.by-week {
    border: 1px solid black;
    opacity: 1;
    span {
      font-weight: 500;
    }
    background-color: white;
    color: black;
    padding: 0px 10px;
    box-sizing: content-box;
  }
  button.view-mode.by-week.selected {
    background-color: black;
    color: white;
  }
}

@media only screen and (max-width: 350px) {
  nav {
    .nav-content {
      padding: 0px 10px 0px 40px;
      .logo {
        .background-image {
          width: 60px;
          height: 40px;
        }
      }
      // .logo {
      //   height: 40px;
      //   width: 60px;
      // }
      // a {
      //   img {
      //     height: 40px;
      //   }
      // }
      button.user {
        width: 60px;
        left: 0px;
      }
      .nav-title {
        .tag-line {
          margin: 0px;
          margin-top: 3px;
          .city-names {
            letter-spacing: 0.6px;
          }
        }
      }

      .cart-thumbnail {
        width: 65px;
        right: 0px;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  nav {
    border-bottom: none;
    .nav-content {
      padding: 0px calc(10% + 10px);
      justify-content: center;
      // .logo {
      //   margin-left: 200px;
      //   span {
      //     display: inline-block;
      //     width: 200px;
      //     // position: absolute;
      //     // top: 20px;
      //     // left: 10px;
      //     // line-height: calc(var(--nav-height) - 10px);
      //     // font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode',
      //     //   Geneva, Verdana, sans-serif;
      //     // @extend .text-title;
      //     font-size: 12px;
      //     // text-transform: uppercase;
      //     letter-spacing: 1px;
      //     margin-left: 10px;
      //   }
      // }
      .my-orders {
        display: flex;
      }
      button.user {
        left: calc(10% + 10px);
      }
      .select-city {
        position: fixed;
        right: calc(10% + 10px);
        top: 23px;
      }
      .admin-quick-access {
        left: calc(10% + 100px);
        right: auto;
        left: 0px;

        a {
          margin-left: 20px;
        }
      }

      .cart-thumbnail {
        right: calc(10% + 10px);
      }
      .select-view-mode {
        button.view-mode {
          span {
            font-size: 12px;
            letter-spacing: 1.2px;
          }
        }
        button.view-mode.by-day {
          margin-right: 30px;
        }
      }
      .nav-title {
        .website-name {
          display: inline-block;
        }
        .tag-line {
          display: block;
          text-transform: none;
          .city-names {
            font-size: 12px;
          }
        }
      }
    }
  }
  nav.admin-view,
  nav.ops-view {
    .nav-content {
      .admin-quick-access {
        position: fixed;
        // right: calc(50vw - 500px);
        // top: calc(var(--nav-height) + var(--subnav-height));
      }
    }
  }
}
