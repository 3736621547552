@import "./index.scss";
.change {
  position: relative;
  z-index: 103;
  button.selected {
    @extend .action-underline;
    padding: 0px;

    @extend .text-12-300;
    svg {
      margin-left: 10px;
    }
  }
  button:disabled {
    border-bottom: 1px solid transparent;
    pointer-events: none;
    svg {
      color: transparent;
    }
  }
  .popup-container {
    width: calc(100% + 40px);
    left: 0px;
    ul.option-list {
      position: absolute;
      top: 27px;
      // left: -20px;
      right: 20px;
      left: auto;
      @extend .card;
      width: calc(100% + 0px);
      padding: 15px 20px;
      z-index: 100;
      li {
        width: 100%;
        margin-bottom: 10px;
        button {
          width: 100%;
          padding: 0px;
          @extend .flex-by-row-between;
          span {
            text-align: left;
            @extend .text-12-300;
          }
        }
      }
      li:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
