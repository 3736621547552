@import "../../index.scss";
@import "../tasting_menu/components/index.scss";

.anonymous-footer {
  // position: absolute;
  // bottom: 10px;
  width: 100vw;
  @extend .flex-by-row-centered;
  flex-wrap: wrap;
  @extend .text-12-300;
  line-height: 1.5;
  font-size: 8px;
  // text-transform: capitalize;
  padding: 0px 10px;
  margin: 50px 0px;
  .footer-field {
    margin: 2px 8px;
  }
  @media only screen and (min-width: 1000px) {
    // position: absolute;
    font-size: 10px;
    bottom: 40px;
    padding: 0px 40px;
    .footer-field {
      margin: 2px 10px;
    }
  }
}
.anonymous-footer.mobile {
  @media only screen and (min-width: 1000px) {
    display: none;
  }
}
.anonymous-footer.desktop {
  display: none;
  @media only screen and (min-width: 1000px) {
    display: flex;
  }
}
.footer {
  @extend .flex-by-column-left;
  align-items: flex-start;
  position: absolute;
  // flex-wrap: wrap;
  width: 100%;
  // bottom: 121px;
  bottom: 0px;
  padding: 0px 50px;
  height: var(--footer-height);
  background-color: var(--background-beige);
  // padding-bottom: 10px;
  .footer-section {
    width: 100%;
    @extend .flex-by-column-left;
    border-bottom: 1px solid rgb(235, 235, 235);
    padding: 20px 0px;
    align-self: stretch;
    justify-content: flex-start;
    align-items: flex-start;
    .footer-field {
      @extend .text-12-300;
      color: grey;
      padding-bottom: 20px;
      text-transform: capitalize;
      margin-right: 20px;
      font-size: 14px;
      svg {
        margin-right: 5px;
      }
    }
    .footer-field:last-child {
      margin-right: 0px;
      padding-bottom: 0px;
    }
    .footer-field.social-network {
      @extend .flex-by-row-left;
      svg {
        font-size: 18px;
      }
    }
    .footer-field.image {
      height: 18px;
      width: 18px;
      margin-right: 6px;
      padding: 0;
    }
    .footer-field.phone {
      svg {
        font-size: 18px;
        top: 3px;
        position: relative;
      }
    }
    .footer-field.email {
      text-transform: lowercase;
      svg {
        font-size: 18px;
        top: 2px;
        position: relative;
      }
    }
  }
  .footer-section:last-child {
    border-bottom: none;
  }
}
.feed {
  .footer {
    padding-bottom: calc(var(--quick-post-height) + 10px);
  }
}

// //desktop
// @media only screen and (min-width: 500px) {
//   .footer {
//     // flex-direction: row;
//     // justify-content: center;
//     // align-items: flex-end;
//     .footer-field {
//       margin-left: 30px;
//     }
//   }
//   .footer-field:first-child {
//     margin-left: 0px;
//   }
// }
@media only screen and (min-width: 600px) {
  .footer {
    flex-direction: row;
    align-items: center;
    height: var(--footer-height);
    padding-left: 14%;
    .footer-section {
      width: 33.33%;
      border-bottom: none;
      padding-top: 80px;
    }
  }
}

// @media only screen and (min-width: 1300px) {
//   .footer {
//     padding-bottom: 10px;
//   }
// }
