@import './index.scss';
.restaurant-order-matrix {
  @extend .flex-by-row-left;
  width: 100vw;
  .restaurant-orders-on-date {
    @extend .flex-by-column-top;
  }
  .date,
  .user {
    height: 100px;
  }
}
