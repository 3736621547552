@import './index.scss';
.share-button {
  width: 100vw;
}
.share-section {
  .share-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0px;
    left: 0px;
  }
  @extend .flex-by-column-centered;
  position: relative;
  padding: 60px 30px 10px 30px;
  // margin: 20px 20px 0px 20px;
  @extend .card;
  // width: calc(100% - 40px);
  width: 100%;
  border: 1px solid var(--border-grey);
  height: 260px;
  // background-image: url('https://i.pinimg.com/originals/83/bd/26/83bd260641d38e8ea834df2711ebf881.jpg');
  // background-image: url('https://i.pinimg.com/originals/7d/dd/48/7ddd4840ef122d2d89defa37dc2c6853.jpg');
  // background-image: url('https://i.pinimg.com/originals/ee/ff/e2/eeffe21e96b331bf63630aaba5604c2f.jpg');
  background-image: url('https://images.pexels.com/photos/1310777/pexels-photo-1310777.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');

  background-size: cover;
  background-position: 500px 0px;
  .smiley-star {
    display: none;
    background-image: url('https://i.pinimg.com/originals/b4/5b/d2/b45bd29e7953b573bacfe9e9dada497d.jpg');
    width: 43px;
    height: 55px;
    position: absolute;
    top: 15px;
    z-index: 10;
    background-size: cover;
    background-position: center;
  }
  .redeem-title {
    @extend .text-title;
    margin: 5px 0px 10px 0px;
    z-index: 2;
    color: white;
    font-size: 37px;
  }
  .share-banner {
    z-index: 2;
    @extend .flex-by-column-centered;
    // @extend .card;
    margin: 0px 20px;
    height: auto;
    background-color: var(--background-yellow);

    // background-color: #f7bc2c;
    // background-color: #ee6346;

    // border-left: 1px solid var(--border-grey);
    // border-right: 1px solid var(--border-grey);
    width: auto;
    min-width: 160px;
    // color: #bb4653;
    // color: white;
    // padding-left: var(--nav-height);
    padding: 10px 30px;
    position: relative;
    span {
      @extend .text-13-400;
      letter-spacing: 1.5px;
    }
    svg {
      position: absolute;
      right: 20px;
    }
  }
  .share-notes {
    @extend .text-11-300;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
    margin-bottom: 15px;
    width: auto;
    color: white;
    z-index: 2;
  }
  .share-actions {
    width: 100%;
    max-width: 300px;
    margin: 10px 0px;
    @extend .flex-by-row-even;
    z-index: 2;
    .share-action {
      @extend .flex-by-column-centered;
      // background-color: var(--background-grey);
      background-color: transparent;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      svg {
        font-size: 20px;
        margin-bottom: 2px;
        // color: rgba(0, 0, 0, 0.8);
        color: white;
      }
      span {
        @extend .text-11-300;
        display: none;
      }
    }
  }
}
// .share-banner::before,
// .share-banner::after {
//   content: '';
//   width: 16px;
//   height: 16px;
//   background-color: white;
//   z-index: 1;
//   position: absolute;
//   border-radius: 50%;
//   // border: 1px solid var(--border-grey);
// }
// .share-banner::before {
//   left: -8px;
// }
// .share-banner::after {
//   right: -8px;
// }
.social-actions {
  z-index: 10;
  @extend .flex-by-column-centered;
  position: fixed;
  top: calc(50vh - 200px);
  left: calc(50vw - 25px);
  .social-action {
    margin: 20px 0px;
  }
}
.share-card {
  width: 100vw;

  // min-height: 100vh;
  //   @extend .card;
  z-index: 1;
  // position: fixed;
  //   top: calc(50vh - 250px);

  // top: 0px;
  overflow-y: scroll;

  @extend .flex-by-column-between;
  padding: 20px 0px 100px 0px;

  .share-banner {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    max-width: 100vw;
    z-index: 1;
  }
  .share-rules {
    // position: fixed;
    width: calc(100% - 40px);
    max-width: 400px;
    top: 60px;
    margin-bottom: 40px;
    @extend .flex-by-column-between;

    .rules-title {
      //   @extend .text-title;
      background-color: var(--background-yellow);
      z-index: 1;
      position: fixed;
      top: 0px;
      left: 0px;
      margin-bottom: 20px;
      width: 100%;
      @extend .flex-by-column-centered;
      height: var(--nav-height);
      border-bottom: 1px solid var(--border-grey);
      span {
        text-align: center;
        font-size: 14px;
        line-height: var(--nav-height);
        font-weight: 700;
        font-family: 'Rock Salt';
        letter-spacing: 2.5px;
      }
      svg {
        position: fixed;
        right: 15px;
        color: var(--background-coral);
        --fa-secondary-opacity: 1;
        --fa-secondary-color: #ee6346;
        --fa-primary-color: #4934c6;
        font-size: 40px;
      }
      padding-left: 10px;
      height: var(--nav-height);
      @extend .card;
    }
    span {
      @extend .text-13-400;
      letter-spacing: 0.6px;
      line-height: 22px;
    }
  }
  .back-button {
    position: fixed;
    left: 0px;
    top: 0px;
    z-index: 2;
  }
  .share-action {
    @extend .action-black;
    margin: 60px 0px;
    svg {
      margin-left: 10px;
      font-size: 16px;
    }
  }
  .preview-section {
    @extend .flex-by-column-centered;
    .preview-title {
      @extend .text-title;
      // @extend .text-13-400;
      font-size: 16px;
      margin-bottom: 15px;
    }
    .preview {
      @extend .card;
      background-color: white;
      position: relative;
      border-radius: 5px;
      border: 1px solid var(--border-grey);
      width: calc(100vw - 40px);
      max-width: 400px;
      padding: 10px 0px;
      @extend .flex-by-column-centered;
      font-family: Helvetica, Arial, sans-serif;
      .quote {
        padding: 0px 10px;
        font-size: 14px;
        line-height: 18px;
        color: rgb(28, 30, 33);
      }
      .image {
        margin: 10px 0px;
        width: 100%;
        max-width: 400px;
        height: calc(50vw - 20px);
        max-height: 200px;
        background-size: cover;
        background-image: url('https://i.pinimg.com/originals/68/99/31/6899316cff06ab1a1731cc978c282e66.jpg');
      }
      .preview-footer {
        //   position: absolute;
        //   bottom: 0px;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 100%;
        padding: 0px 10px;

        font-size: 12px;
        .slogan {
          color: rgb(28, 30, 33);
        }
        .website {
          color: rgba(96, 103, 112, 0.5);
        }
      }
    }
  }
}

.social-buttons {
  width: calc(100% - 40px);
  @extend .flex-by-row-between;
  margin: 30px 0px 100px 0px;
  max-width: 400px;
  flex-wrap: wrap;
  button {
    margin: 10px 20px;
  }
}
@media only screen and (min-width: 500px) {
  .share-button {
    width: 400px;
    .share-banner {
      width: 400px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .share-section {
    // margin-bottom: 20px;
    box-shadow: none;
    border: none;
    margin: 0px;
    width: 100%;
    background-position: 0px 600px;
    // transform: scale(1.2);
    // background-image: url('https://images.pexels.com/photos/9578722/pexels-photo-9578722.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  }
}

@media only screen and (min-width: 1200px) {
  .share-button {
    top: calc(var(--nav-height) + 7px);
    right: 7px;
    z-index: 10;
    // width: 300px;
    // height: var(--nav-height);
    // height: 300px;
    width: auto;

    .share-banner {
      // width: 400px;
      height: auto;
      // height: var(--nav-height);
      // background-color: white;
      border: none;
      box-sizing: border-box;
      box-shadow: none;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 50px;
      width: auto;

      svg {
        position: absolute;
        // margin-bottom: 20px;
        right: 15px;
        top: 10px;
      }
    }
  }
}
