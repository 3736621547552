@import '../../../index.scss';
.bookmark-list {
  @extend .flex-by-column-centered;
  width: 100vw;
  position: relative;
  .bookmark-thumbnail {
    @extend .card;
    @extend .flex-by-column-centered;
    width: calc(100% - 32px);
    margin-bottom: 16px;
    padding: 16px 0px;
    position: relative;
    // .profile-link {
    //   width: calc(100% - 40px);
    // }
    .recommender.statement {
      @extend .flex-by-row-left;
      padding: 0px 20px;
      height: 26px;
      box-sizing: content-box;
      width: 100%;
      box-sizing: border-box;
      span.recommends {
        line-height: 22px;
      }
    }
    .dish {
      width: calc(100% - 40px);
      padding: 40px 16px;
      box-sizing: border-box;
      border: 1px solid black;
      margin: 16px;
      .dish-border {
        border: none;
      }
    }
    .counts-section {
      width: calc(100% - 40px);
      padding: 0px;
      margin-bottom: 16px;
    }
    .bookmark-thumbnail-review {
      @extend .action-grey;
      // padding: 0px;
      // border-bottom: 1px solid black;
      // height: 30px;
      // line-height: 30px;
      // font-size: 11px;
      // font-weight: 500;
      // letter-spacing: 0.6px;

      height: 42px;
      margin: 16px 0px;
      padding: 0px 20px;
      svg {
        font-size: 14px;
        margin-right: 12px;
      }
    }
    .review {
      // @extend .flex-by-column-left;
      width: calc(100% - 40px);
      box-sizing: border-box;
      margin: 0px 0px 16px 0px;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.6px;
      line-height: 1.8;
      .author {
        font-weight: 600;
        text-transform: capitalize;
        margin-right: 5px;
      }
    }
    div:last-child {
      margin-bottom: 0px;
    }
  }
  .bookmark-thumbnail:first-child {
    margin-top: 16px;
  }
}
@media only screen and (min-width: 500px) {
  .bookmark-list {
    width: 500px;
  }
}
