@import '../../../index.scss';

.profile-banner {
  @extend .flex-by-column-centered;
  width: 100%;
  // padding-top: 24px;
  box-sizing: border-box;
  background-color: rgba(246, 244, 240, 1);
  border-bottom: 1px solid rgb(217, 217, 217);
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  .profile-body {
    @extend .flex-by-row-left;
    position: relative;
    margin: 0px 80px;
    padding-left: 90px;
    height: 80px;
    box-sizing: border-box;
    width: calc(100% - 160px);
    .avatar {
      position: absolute;
      left: 10px;
      top: 20px;
      width: 60px;
      height: 60px;
      margin-right: 20px;
      div {
        line-height: 25px;
        height: 25px;
        font-size: 25px;
      }
    }
    .name {
      @extend .flex-by-row-centered;
      //   margin: 12px 0px;
      // margin-bottom: 15px;
      margin-top: 20px;
      font-weight: 600;
      font-size: 12px;
      letter-spacing: 1.6px;
      text-transform: uppercase;
    }
  }
  .counts {
    @extend .flex-by-row-centered;
    margin: 10px 0px 0px 0px;
    width: 100%;
    // height: 50px;
    .count {
      color: grey;
      margin-right: 36px;
      letter-spacing: 0.6px;
      border-bottom: 1px solid transparent;
      font-weight: 300;
      letter-spacing: 1.6px;
      font-size: 14px;
      line-height: var(--title-height);
      height: var(--title-height);
      padding: 0px;
      position: relative;
      box-sizing: content-box;
      font-family: 'Theano Didot', serif;
      margin-bottom: -1px;
    }
    .count:last-child {
      margin-right: 0px;
    }
    .count.selected {
      border-bottom: 1px solid black;
      color: black;
    }
  }
}

@media only screen and (min-width: 500px) {
  .profile-banner {
    padding-left: calc(50% - 250px);
    padding-right: calc(50% - 250px);
    box-sizing: border-box;
    .profile-body {
      padding: 24px 0px 0px 0px;
      justify-content: center;
      .avatar {
        position: relative;
        left: 0px;
        top: auto;
      }
      .name {
        margin: 0px;
      }
    }
    .counts {
      margin: 10px 0px 0px 0px;
      width: auto;
    }
  }
}
