@import "./index.scss";
.home-from-menu {
  position: fixed;
  z-index: 1;
  @extend .flex-by-row-centered;
  width: 35px;
  height: 35px;
  // border-radius: 50%;
  // @extend .card;
  background-color: white;
  left: 15px;
  top: 15px;
  font-size: 24px;
  // border: 1px solid var(--border-grey);
  // box-shadow: 0px 0px 8px 0px rgba(28, 29, 33, 0.3);
}
.nav-with-date {
  height: calc(var(--nav-height) + 30px);
  @extend .flex-by-column-centered;
  justify-content: flex-end;
  padding: 0px var(--nav-height) 10px var(--nav-height);
  box-shadow: none;
  border-bottom: 1px solid var(--border-grey);
  // position: relative;
  // .to-home {
  //   position: absolute;
  //   @extend .flex-by-row-centered;
  //   left: 0px;
  //   height: var(--nav-height);
  //   line-height: var(--nav-height);
  //   width: var(--nav-height);
  //   text-align: center;
  // }
  .reminder-container {
    position: fixed;
    right: 20px;
    top: 20px;
    // background-color: pink;
  }
  .user-menu {
    width: 100vw;
    left: 0px;
  }
  .restaurant-awards {
    @extend .flex-by-row-centered;
    // position: absolute;
    // top: 65px;
    width: 100%;
    background-color: pink;
    gap: 5px;
  }
  .delivery-distance {
    position: absolute;
    right: 0px;
    @extend .flex-by-column-centered;
    span {
      @extend .text-12-300;
    }
    width: var(--nav-height);
  }
  .delivery-window {
    @extend .flex-by-row-centered;
    // display: none;
    position: absolute;
    // right: 70px;
    // top: 0px;
    // height: var(--nav-height);
    bottom: 13px;
    height: 25px;
    line-height: 25px;
    background-color: var(--background-beige);
    padding: 0px 10px;
    border-radius: 5px;
    div {
      @extend .text-title;
      // @extend .text-11-300;
      // text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      // letter-spacing: 1px;
      color: rgba(0, 0, 0, 0.7);
      // font-size: 9px;
    }
    .order-by {
      margin-right: 5px;
    }
    .order-by::after {
      content: ",";
      // left: 4px;
      position: relative;
    }
  }
  button.user {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 60px;
    height: calc(var(--nav-height) - 1px);
    z-index: 1;
    background-color: white;
    box-shadow: none;
    svg {
      font-size: 22px;
    }
    .avatar {
      width: 27px;
      height: 27px;
    }
    svg:nth-child(2) {
      font-size: 12px;
      margin-left: 8px;
    }
  }
  .cart-thumbnail {
    position: absolute;
    right: 0px;
    top: 0px;
  }
  .day-menu-date {
    @extend .text-title;
    font-size: 16px;
    // margin: 8px 0px 4px 0px;
    // height: var(--subnav-height);
    // line-height: var(--subnav-height);
    // padding-left: 20px;
    // background-color: grey;
    position: fixed;
    @extend .overflow-ellipsis;
    // white-space: nowrap;
    width: calc(100vw - 2 * var(--nav-height));
    left: var(--nav-height);
    letter-spacing: 0.6px;
    text-align: center;
    top: 15px;
    svg {
      // position: absolute;
      --fa-primary-color: #f76b6b;
      --fa-secondary-color: black;
      --fa-secondary-opacity: 0.4;
      // color: #f76b6b;
      margin-right: 8px;
      // font-size: 16px;
      left: 0px;
    }
  }
  .view-meal {
    // border-bottom: 1px solid black;
    // background-color: black;
    // color: white;
    position: fixed;
    @extend .flex-by-column-centered;
    width: 100%;
    top: calc(var(--nav-height) - 35px);
    .meal-type {
      // padding: 0px 2px;
      text-align: center;
      @extend .text-title;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.8px;
      margin: 5px 0px 7px 0px;
      line-height: 12px !important;
      // text-transform: uppercase;
      // line-height: 25px !important;
      // position: relative;
      // width: calc(var(--nav-height) - 10px);
      // line-height: calc(var(--nav-height) - 10px);
      // position: fixed;
      // right: 5px;
      // top: 5px;
      // border: 1px solid black;
      // border-radius: 50%;
      // display: none;
      @extend .with-curls;
    }
    .meal-type::before {
      width: 15px;
      left: -21px;
      top: 6px;
    }
    .meal-type::after {
      width: 15px;
      top: 6px;
    }
    .delivery-window {
      @extend .text-11-300;
      font-size: 9px;
      letter-spacing: 1px;
    }
    // margin-bottom: 20px;
  }
}
.menu-two-dishes {
  .announcement {
    opacity: 0;
  }
  @extend .flex-by-column-top;
  width: 100%;
  min-height: calc(100vh - var(--nav-height));
  overflow-y: scroll;
  // overflow-x: hidden;
  overflow: visible;
  padding-right: -10px; /* Increase/decrease this value for cross-browser compatibility */
  padding-bottom: calc(var(--nav-height) + 40px);
  box-sizing: content-box;
  .restaurant-card {
    @extend .card;
    // width: calc(100% - 30px);
    @extend .flex-by-column-centered;
    padding: 20px;
    // background-color: red;

    .restaurant-content {
      border: 1px solid black;
      @extend .flex-by-row-centered;
      justify-content: stretch;
      flex-grow: 1;
      align-self: stretch;
      align-items: stretch;
      padding: 5px;
      text-align: center;
      position: relative;
      box-sizing: border-box;

      .restaurant-border {
        z-index: 1;
        // border: 1px solid black;
        padding: 50px 30px;
        @extend .flex-by-column-centered;
        flex-grow: 1;
        align-self: stretch;
        align-items: stretch;

        .show-min-order-value {
          @extend .text-13-400;
          // text-transform: uppercase;
          // margin: 0px 20px;
          // font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.6px;
          // width: 80%;
          letter-spacing: 0.8px;
          text-align: center;
          margin-top: 20px;
        }

        .hide-warning {
          display: none;
        }

        .restaurant-name {
          @extend .text-title;
          color: rgb(11, 11, 11);
          line-height: 30px;
          letter-spacing: 1.6px;
          position: relative;
          width: 100%;
          margin: 0px;
          // @extend .flex-by-row-between;

          .restaurant-address {
            margin-top: 20px;
            @extend .text-title-16;
            line-height: 24px;
          }
          .show-restaurant-details {
            position: absolute;
          }
        }

        .restaurant-details {
          padding: 30px 0px 0px 0px;
          width: 100%;
          div {
            margin-bottom: 20px;
          }
          div:last-child {
            margin-bottom: 0px;
          }
          .restaurant-address {
            @extend .text-title-16;
            line-height: 24px;
          }
          position: relative;
          .external-links {
            @extend .flex-by-row-even;
            width: 100%;
            a {
              @extend .action-underline;
              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }
  }
  .restaurant-card.mobile {
    width: 100%;
    padding: 0px;
    box-shadow: none;
    .menu-type {
      .menu-is-lunch {
        @extend .text-title;
      }
    }
    .restaurant-content {
      background-color: var(--background-beige);
      border: none;
      padding: 0px;
      border-top: 1px solid rgb(217, 217, 217);
      .meal-time {
        // background-color: red;
        padding: 15px 0px 15px 15px;
        border: 1px solid rgb(217, 217, 217);
        border-left: none;
        border-right: none;
        @extend .flex-by-column-centered;
        .meal-time-date {
          // @extend .card;
          // box-shadow: 0px 0px 4px 1px rgb(217, 217, 217);
          border: 1px solid rgb(217, 217, 217);
          border-radius: 10px;
          // margin-bottom: 5px;
          width: 80px;
          // height: 60px;
          padding-bottom: 8px;
          .week-day {
            @extend .text-13-400;
            text-transform: uppercase;
            background-color: var(--background-coral);
            color: white;
            font-weight: 500;
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            line-height: 25px;
          }
          .month-date {
            @extend .text-13-400;
            line-height: 34px;
            font-weight: 500;
            font-size: 16px;
          }
          .delivery-window {
            // background-color: red;
            @extend .flex-by-row-centered;
            @extend .text-10-300;
            svg {
              margin-right: 5px;
            }
            height: 12px;
          }
        }
      }
      .restaurant-border {
        padding: 15px 0px;
        border-right: none;
        border-left: 0px;
        @extend .flex-by-column-centered;
        width: 100%;
        button.share-menu.desktop {
          display: none;
        }
        .restaurant-name {
          padding: 0px 50px 0px 60px;
          // @extend .text-title;
          width: 100%;
          box-sizing: border-box;
          @extend .text-13-400;
          text-transform: uppercase;
          // margin: 0px 20px;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.6px;
          position: relative;
          @extend .flex-by-row-centered;
          // flex-grow: 1;
          align-self: stretch;
          // @extend .card;
          // height: 60px;
          // box-shadow: 0px 0px 4px 1px rgb(217, 217, 217);
          border-radius: 10px;
          .name {
          }
          .min {
            @extend .text-title;
            font-size: 12px;
            // letter-spacing: 1px;
            text-transform: none;
            margin-top: 3px;
            margin-bottom: 8px;
            font-weight: 500;
          }
          // margin-bottom: 5px;
          .hide-details {
            @extend .overflow-ellipsis;
            // line-height: 1;
            svg {
              margin-right: 15px;
            }
          }
          .show-details {
            svg {
              margin-right: 15px;
            }
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: normal;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          button.share-menu {
            position: absolute;
            @extend .flex-by-row-centered;
            // background-color: var(--border-grey);
            background-color: var(--background-yellow);
            width: 34px;
            height: 34px;
            border-radius: 50%;
            top: 0px;
            left: 16px;
          }
          button.show-restaurant-details {
            position: absolute;
            right: 16px;
            top: 5px;
            padding: 0px;
            svg {
              font-size: 18px;
            }
          }
        }
        .restaurant-name:last-child {
          margin-bottom: 0px;
        }
        .price-disclaimer {
          margin: 0px;
          font-size: 10px;
          // letter-spacing: 0.6px;
          // font-weight: 200;
        }
        .restaurant-details {
          padding: 15px 15px 5px 15px;
          box-shadow: none;
          .restaurant-address {
            @extend .text-12-300;
            letter-spacing: 0.6px;
            display: none;
          }
        }
      }
    }
  }
  .category-carousel {
    @extend .flex-by-row-centered;
    height: 60px;
    padding: 24px 0px 0px 16px;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    overflow-x: hidden;
    border-top: 1px solid rgb(217, 217, 217);
    // padding-bottom: 32px;
    .carousel-container {
      width: auto;
      @extend .flex-by-row-left;
      padding: 0px 0px;
      margin-bottom: 0px;
      overflow-x: scroll;
      position: relative;
      button {
        padding: 0px;
        margin-right: 20px;
        margin-bottom: 0px;
        box-sizing: border-box;
        height: 30px;
        span {
          display: inline-block;
          line-height: 26px;
          color: var(--text-grey);
          font-weight: 200;
          letter-spacing: 0.8px;
        }
        &.active {
          span {
            color: black;
          }
          border-bottom: 1px solid black;
        }
        @extend .text-title;
        font-size: 16px;
        padding: 0px;
        white-space: nowrap;
      }
      button:last-child {
        padding-right: 16px;
      }
    }
    width: 100%;
    z-index: 10;
    top: calc(var(--nav-height) + 0px); /* + var(--subnav-height));*/
    background-color: white;
    box-shadow: 0 8px 8px 0px rgba(28, 29, 33, 0.15);
    // background-color: var(--background-beige);
    overflow-x: scroll;
  }
  .category-carousel.mobile {
    // padding-top: 15px;
    // padding-bottom: 30px;
    // padding: 15px 0px;
    max-width: 100%;
    @extend .flex-by-row-left;
    align-items: flex-start;
    // padding-top: 0px;
    padding-top: 0px;
    height: 60px;
    // padding-bottom: 15px;
    .carousel-container {
      // top: 34px;
      // margin-top: -30px;
      // padding-bottom: 25px;
      // margin-bottom: -5px;
      // background-color: red;
      height: 65px;
      @extend .flex-by-row-left;
      position: relative;
      padding-top: 15px;
      align-items: flex-start;
      .go-to-category {
        height: 30px;
        line-height: 30px;
      }
    }
  }
  .category-carousel.desktop {
    display: none;
  }
  .dish-choice-list-loading {
    width: 100%;
    &.lunch {
      background: var(--lunch-color);
    }
    &.dinner {
      background: var(--dinner-color);
    }
  }
  .dish-choice-list-tbd {
    width: 100%;
    height: calc(100vh - var(--nav-height));

    &.lunch {
      background: var(--lunch-color);
    }
    &.dinner {
      background: var(--dinner-color);
    }
    @extend .flex-by-row-top;
    padding: 40px;
    span {
      @extend .text-title;
      font-size: 20px;
      text-align: center;
      margin-top: 50px;
      text-transform: none;
      line-height: 2;
      padding: 20px;
      display: inline-block;
    }
  }

  .dish-choice-list-mobile {
    @extend .flex-by-column-centered;
    width: 100%;
    justify-content: stretch;

    .price-disclaimer {
      width: 100%;
      text-align: center;
      margin: 0px;
      padding-bottom: 10px;
      background-color: white;
    }
    .nominate {
      width: 100vw;
      padding-top: 60px;
      background-color: var(--background-beige);

      .nominate-wrapper {
        width: 100%;

        .nominate-form {
          margin-top: 40px;
          width: calc(100vw - 30px);
          max-width: 400px;
        }
      }
    }

    .category-of-dishes {
      padding-top: 15px;
      width: 100%;
      @extend .flex-by-row-centered;
      flex-wrap: wrap;
      .category-name {
        width: 100%;
        @extend .flex-by-column-centered;
        padding: 15px 0px;
        span {
          text-align: center;
          @extend .text-title;
          text-transform: capitalize;
          line-height: 25px;
          font-size: 20px;
          font-weight: 500;
          letter-spacing: 1px;
        }
        span::before,
        span::after {
          background-color: black;
        }
      }

      .dish-choice-mobile {
        width: calc(100% - 30px);
        max-width: 600px;
        margin: 15px;
        padding: 0px;
        @extend .flex-by-column-centered;
        @extend .card;
        .dish-content {
          @extend .flex-by-row-right;
          width: 100%;
          position: relative;
          background-color: white;
          .dish-text {
            width: 60%;
            @extend .flex-by-column-centered;
            padding: 20px;

            .add-to-cart {
              position: relative;
              bottom: auto;
              left: auto;
            }
          }
        }
        .dish-comment {
          position: relative;
          background-color: white;
        }
      }
      .dish-choice-mobile:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .dish-choice-list-mobile.lunch {
    background: var(--lunch-color);
    .restaurant-card {
      background-color: white;
      .restaurant-content {
        .restaurant-border {
          .restaurant-name {
            color: var(--lunch-theme-font-color);
          }
        }
      }
    }
    .menu-type {
      height: 30px;
      margin-top: 20px;
      @extend .flex-by-row-centered;
      span {
        @extend .text-title;
        font-size: 18px;
        @extend .with-curls;
      }
      span:before,
      span:after {
        background-color: black;
      }
    }
  }
  .dish-choice-list-mobile.dinner {
    background: var(--dinner-color);
    .restaurant-card {
      .restaurant-content {
        .restaurant-border {
          .restaurant-name {
            color: var(--dinner-theme-font-color);
            button {
              color: var(--dinner-theme-font-color);
            }
          }
          .restaurant-details {
            color: var(--dinner-theme-font-color);
            .external-links {
              a {
                color: var(--dinner-theme-font-color);
                border-color: var(--dinner-theme-font-color);
              }
            }
          }
        }
      }
    }
    .price-disclaimer {
      color: var(--dinner-theme-font-color);
    }
  }
  .dish-choice-list-desktop {
    @extend .flex-by-row-stretch;
    justify-content: center;
    width: 100%;
    padding: calc(var(--subnav-height) + 0px) 0px 40px 0px;
    display: none;
    flex-wrap: wrap;
    position: relative;
    .menu-type {
      position: absolute;
      width: 100%;
      top: var(--subnav-height);
      left: 0px;
      height: 100px;
      @extend .flex-by-row-centered;
      display: none;
      span.menu-is-lunch {
        display: inline-block;
        text-align: center;
        @extend .text-title;
        font-size: 24px;
        font-weight: 400;
        letter-spacing: 1.2px;
      }
      .my-orders {
        top: 20px;
        right: 30px;
        @extend .action-grey;
        height: 49px;
        position: absolute;
        span {
          font-size: 13px;
          letter-spacing: 0.825px;
        }
      }
    }

    .dish-choice-desktop {
      width: 320px;
      margin: 20px;
      @extend .flex-by-column-stretch;
      @extend .card;
      position: relative;
      padding: 200px 20px 70px 20px;
      background-color: white;
      .add-to-cart {
        position: absolute;
        bottom: 30px;
        left: 0px;
      }
      button.add-to-cart {
        left: calc(50% - 75px);
        width: 150px;
      }
      .dish-image {
        position: absolute;
        top: 0px;
        left: 0px;
      }
      .dish-comment {
        position: absolute;
        bottom: 80px;
        left: 0px;
        background-color: white;
      }
    }
    .dish-choice-desktop.nominate {
      padding: 0px;
      align-self: stretch;
      flex-grow: 1;
      height: 546px;
      .nominate-form {
        border: none;
        box-shadow: none;
        background-color: transparent;
        height: 546px;
        justify-content: space-around;
        width: 100%;
        .nominate-field {
          width: 100%;
        }
      }
    }
    .dish-choice-desktop.nominate.lunch {
      background-color: var(--lunch-color);
    }
    .dish-choice-desktop.nominate.dinner {
      background-color: var(--dinner-color);
    }
    .dish-choice-desktop.restaurant-card {
      padding: 20px;
      // width: 400px;
      margin: 20px;
      @extend .card;
      .restaurant-content {
        .restaurant-border {
          min-height: 300px;
          @extend .flex-by-column-even;

          .restaurant-name {
            width: 100%;
            font-size: 26px;
            line-height: 50px;
            // margin-bottom: 20px;
            flex-grow: 0;
            padding: 0px;
          }
          .restaurant-details {
            width: 100%;
            box-shadow: none;
            border: none;
            .external-links {
              @extend .flex-by-row-even;
              width: 100%;
              margin-bottom: 0px;

              div {
                margin-right: 60px;
                margin-bottom: 40px;
              }
            }
          }
          .price-disclaimer {
            // font-size: 12px;
            display: none;
          }
        }
      }
      .restaurant-content::before {
        position: absolute;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        border: 1px solid;
        left: -2px;
        bottom: -2px;
        border-top-right-radius: 20px;
        background-color: white;
        border-left-color: white;
        border-bottom-color: white;
      }
      .restaurant-content::after {
        box-sizing: border-box;
        position: absolute;
        content: "";
        display: block;
        height: 20px;
        width: 20px;
        border: 1px solid;
        right: -2px;
        top: -1px;
        border-bottom-left-radius: 20px;
        background-color: white;
        border-right-color: white;
        border-top-color: white;
      }
    }
    .dish-choice-desktop.category-name-card {
      padding: 20px;
      @extend .card;
      @extend .flex-by-row-centered;
      span {
        width: 100%;
        @extend .text-title;
        font-size: 26px;
        line-height: 2;
        text-align: center;
      }
    }
    .dish-choice-desktop.category-name-card.lunch {
      background-color: var(--lunch-color);
    }
    .dish-choice-desktop.category-name-card.dinner {
      background-color: var(--dinner-color);
    }
  }
  .dish-choice-list-desktop.no-categories {
    padding-top: 20px;
  }
  .dish-choice-list-desktop.lunch {
    background: var(--lunch-color);
    .menu-type {
      span {
        color: var(--lunch-theme-font-color);
      }
    }
  }
  .dish-choice-list-desktop.dinner {
    background: var(--dinner-color);
    .menu-type {
      span {
        color: var(--dinner-theme-font-color);
      }
    }
  }
  .price-disclaimer {
    @extend .text-11-300;
    font-size: 9px;
    letter-spacing: 1px;
    margin-bottom: 3px;
  }
}

.menu-two-dishes::-webkit-scrollbar {
  display: none;
}

.menu-two-dishes.has-announcement,
.menu-two-dishes.has-gift-card {
  .announcement {
    opacity: 1;
  }
}
.menu-two-dishes.has-announcement.show-announcement-details {
  height: 0px;
  overflow-y: hidden;
}

.nominate-form {
  @extend .flex-by-column-centered;
  width: calc(100vw - 30px);
  max-width: 400px;
  padding: 40px 20px;
  box-sizing: border-box;
  margin-top: 40px;
  // @extend .card;
  button {
    margin-top: 20px;
    @extend .action-black;
  }
  .title {
    @extend .flex-by-column-centered;
    margin-bottom: 20px;

    span {
      text-align: center;
      line-height: 1.5;
      font-family: "Cardo", serif;
      font-weight: 400;
      text-transform: capitalize;
      font-size: 22px;
      letter-spacing: 0.6px;
    }
  }
  .nominate-field {
    @extend .flex-by-column-left;
    @extend .text-12-300;
    width: calc(100% - 60px);
    margin-bottom: 20px;
    label {
      font-family: "Cardo", serif;
      font-weight: 400;
      text-transform: capitalize;
      font-size: 18px;
      letter-spacing: 0.7px;
    }
    .geosuggest {
      border: 1px solid #dbd9d9;
      margin: 0px;
      .geosuggest__input-wrapper {
        input {
          @extend .text-12-300;
          padding: 10px 5px;
          font-size: 16px;
          color: black;
          text-transform: normal;
          display: inline-block;
          line-height: 1;
        }
        input::placeholder {
          opacity: 0.6;
        }
      }
    }
    .selected-address {
      margin-top: 10px;
      @extend .text-12-300;
      svg {
        margin-right: 5px;
      }
    }
    textarea {
      @extend .text-12-300;
      padding: 10px 5px;
      font-size: 16px;
      line-height: 1;
      width: 100%;
      box-sizing: border-box;
      border: 1px solid #dbd9d9;
      border-style: solid;
      outline: none;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none !important;
      -webkit-appearance: none;
    }
    textarea::placeholder {
      opacity: 0.6;
    }
  }
  button[type="submit"] {
    @extend .action-black;

    margin-top: 20px;
  }
}

.back-to-top-home {
  position: fixed;
  // bottom: 115px;
  left: 15px;
  bottom: 35px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.8);
  z-index: 102;
  padding: 0px;
  svg {
    font-size: 24px;
  }
  @media only screen and (min-width: 800px) {
    left: auto;
    right: calc(1vw * var(--desktop-left-padding) + 90px);
  }
}

// .dish-choice-list-desktop {
//   .back-to-top {
//     right: 20px;
//     left: auto;
//   }
// }

@media only screen and (min-width: 800px) {
  .nav-with-date {
    justify-content: center;
    padding-top: 15px;
    border-bottom: 1px solid var(--border-grey);
    .day-menu-date {
      // align-self: stretch;
      text-align: center;
      // width: auto;
      padding: 0px 40px;
      width: 100%;
      left: 0px;
    }
    .reminder-container {
      left: 100px;
      right: auto;
    }
    // .day-menu-date {
    //   font-size: 17px;
    // }
    // .view-meal {
    //   font-size: 10px;
    //   margin-top: 5px;
    //   .meal-type {
    //     margin: 0px;
    //   }
    // }
    .delivery-window {
      display: flex;
      margin-right: 20px;
      // flex-direction: column;
      .order-by,
      .receive {
        line-height: 1.5;
      }
    }
  }
  .price-disclaimer {
    border: none;
  }
  .menu-two-dishes {
    justify-content: centered;

    .restaurant-card.mobile {
      display: none;
    }
    .dish-choice-list-mobile {
      display: none;
    }
    .category-carousel.desktop {
      display: flex;
      // display: block;
      box-shadow: none;
      // overflow-y: hidden;
      height: var(--nav-height);
      background-color: var(--background-beige);
      position: fixed;
      top: var(--nav-height);
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid var(--border-grey);
      // border-top: 1px solid var(--border-grey);
      // background-color: white;
      box-shadow: 0 2px 2px 0px rgba(28, 29, 33, 0.15);
      padding: 0px 60px;
      overflow-y: hidden;
      overflow-x: scroll;
      width: 100vw;
      button.move-category {
        position: fixed;
        top: calc(var(--nav-height) + 15px);
        height: 30px;
        width: 30px;
        border-radius: 50%;
        // border: 1px solid rgb(117, 117, 117);
        box-shadow: 0 2px 8px 0px rgba(0, 0, 0, 0.2);
        padding: 0px;
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      svg {
        font-size: 14px;
        color: black;
      }
      button.move-category.next {
        right: 15px;
      }
      button.move-category.prev {
        left: 15px;
      }
      .carousel-container {
        flex-wrap: nowrap;
        // margin-left: 60px;
        // padding: 12px 0px;
        padding-top: 15px;
        overflow: hidden;
        height: var(--nav-height);
        padding-block-end: 0px;
        text-overflow: ellipsis;
        button {
          margin: 0px 20px;
          height: 24px;
        }
        button.active {
          border-bottom: 1px solid black;
        }
        // button:first-child {
        //   margin-left: 60px;
        // }
      }
    }
    .dish-choice-list-desktop {
      display: flex;
      position: relative;
      .restaurant-card {
        // width: 100%;
        // max-width: 100%;
        box-shadow: none;
        margin: 0px;
        .price-disclaimer.mobile {
          display: none;
        }
        .restaurant-content {
          .restaurant-border {
            border: 1px solid black;
            flex-direction: row;
            align-items: center;
            position: relative;
            padding: 20px 20px 60px 20px;
            justify-content: stretch;
            align-self: stretch;
            .share-menu {
              display: flex;
              position: absolute;
              background-color: var(--background-yellow);
              width: 34px;
              height: 34px;
              border-radius: 50%;
              align-items: center;
              justify-content: center;
              bottom: 20px;
              right: 20px;
            }
            .restaurant-name,
            .restaurant-details {
              display: flex;
              flex-direction: column;
              margin: 0px;
              align-items: space-evenly;
              justify-content: space-evenly;
              padding: 0px;
            }
            .show-min-order-value {
              font-size: 13px;
              font-weight: 400;
              line-height: 2;
              letter-spacing: 0.6px;
              font-family: "Montserrat", sans-serif;
              width: 80%;
              text-transform: none;
              padding-top: 1px;
              font-style: normal;
            }

            .restaurant-name {
              width: 60%;
              align-self: stretch;
              flex-grow: 1;
              padding-top: 10px;
              .share-menu {
                display: none;
              }
              .restaurant-address {
                margin: 0px;
                margin-top: 20px;
                font-size: 12px;
                display: none;
              }
              .price-disclaimer {
                margin: 0px;
                margin-top: 5px;
              }
            }
            .restaurant-details {
              width: 40%;
              margin-bottom: 0px;
              .external-links {
                margin: 0px;
                margin-bottom: 0px;
                a {
                  font-size: 20px;
                  font-family: "Theano Didot", serif;
                }
              }
            }
          }
        }
      }
    }
    .category-carousel.mobile {
      display: none;
    }
  }
  .menu-two-dishes.has-announcement {
    .ordering-with-code {
      top: var(--subnav-height);
      box-shadow: none;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .nav-with-date {
    .reminder-container {
      left: 300px;
      right: auto;
    }
  }
}

.countdown-container {
  @extend .flex-by-column-centered;
  // margin-top: 10px;
  // background-color: pink;
  position: relative;
  // gap: 5px;
}
