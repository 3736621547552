@import "./index.scss";
.current-summary {
  width: calc(100% - 40px);
  max-width: 1000px;
  margin-top: 30px;
  padding: 20px;
  @extend .text-14-300;
  @extend .flex-by-column-centered;
  @extend .card;

  .summary-title {
    @extend .text-16-400;
    text-transform: capitalize;
    margin-bottom: 30px;
  }
  .item {
    width: 100%;
    @extend .flex-by-row-between;
    margin-bottom: 10px;
    .item-name {
      text-transform: capitalize;
    }
    .item-pre-ordered-quantity {
      width: 40px;
      text-align: center;
      margin: 0 6px;
      padding: 2px 0;
    }
  }
  .item.total-items {
    border-top: 1px dashed black;
    padding-top: 10px;
    text-transform: capitalize;
    margin-bottom: 0px;
    .item-quantity.highlight {
      background-color: yellow;
      font-weight: 500;
    }
  }
  .sold-out {
    margin-top: 12px;
  }
}
.current {
  width: 100vw;
  max-width: 100vw;
  padding-top: calc(var(--subnav-height) + 20px);
  @extend .flex-by-column-centered;
  .select-restaurant {
    position: relative;
    .view-mode {
      @extend .action-underline;
      svg {
        margin-left: 20px;
      }
    }
    .view-restaurant-selections {
      @extend .card;
      position: absolute;
      top: 30px;
      padding: 10px;
      width: auto;
      li {
        // height: 60px;
        width: 100%;
        button {
          height: 60px;
          @extend .flex-by-row-between;
          span {
            @extend .text-12-300;
            text-align: left;
            line-height: 1.5;
          }
          width: 100%;
        }
      }
    }
  }
  .meal-select-wrapper {
    @extend .sub-nav-1;
  }
  .show-details-toggles {
    @extend .sub-nav-2;
  }

  .current-batch {
    @extend .flex-by-column-left;
    justify-content: flex-start;
    // margin-top: 30px;
    padding: 20px;
    padding-bottom: 120px;
    width: 100%;
    max-width: 1000px;
    margin: 0px;

    .order {
      @extend .text-14-300;
      width: 100%;
      margin-bottom: 30px;
      // padding: 0px 10px;
      .order-driver {
        text-transform: capitalize;
        color: white;
        span:first-child {
          margin-right: 10px;
        }
        span:last-child {
          font-weight: 600;
        }
      }
      .order-name {
        margin-bottom: 10px;
        width: 100%;
        background-color: yellow;
        @extend .flex-by-column-left;
        // @extend .flex-by-row-left;
        // flex-wrap: wrap;
        padding: 10px;

        .field {
          margin-bottom: 10px;
          span {
            margin: 10px;
          }
        }
        .field.name {
          text-transform: capitalize;
        }
        .field:last-child {
          margin-bottom: 0px;
        }
        .field.order-method,
        .field.contact-name {
          text-transform: capitalize;
        }
        .field.note {
          width: calc(100% - 60px);
          height: 120px;
          position: relative;
          bottom: 15px;
          @extend .flex-by-column-centered;
          margin: 24px 0px 0px 0px;
          label {
            @extend .text-12-300;
            text-transform: capitalize;
            margin-bottom: 5px;
          }
          textarea {
            width: 100%;
            height: 100px;
            display: block;
            background: var(--background-grey);
            border: none;
            box-sizing: border-box;
            padding: 10px;
            @extend .text-16-400;
            text-transform: lowercase;
          }
          textarea::placeholder {
            text-transform: none;
          }

          textarea:disabled:hover {
            cursor: not-allowed;
          }
        }
        .action-wrapper {
          width: 100%;
          margin: 10px 0px 20px 0px;
          @extend .flex-by-row-centered;
        }
        .count {
          margin-left: 20px;
        }
        .person-summary {
          width: 100%;
          @extend .flex-by-row-between;
          span {
            padding: 0px;
            margin: 0px;
          }
        }
        .mark-as-ordered {
          @extend .action-black;
          color: white;
        }
      }
      .order-name.restaurant {
        background-color: var(--background-beige);
        margin-bottom: 0px;
        &.edit {
          background-color: white;
        }
        .field {
          width: 100%;
          margin: 0;
          padding: 0;
          display: flex;
          align-items: baseline;
          > svg {
            width: 30px;
            margin-right: 5px;
          }
          input {
            flex-grow: 1;
          }
          span {
            margin: 0;
          }
        }
        .action {
          width: 100%;
          margin-top: 20px;
        }
      }
      .order-details {
        .item-wrapper {
          width: 100%;
          @extend .flex-by-column-left;
          padding: 10px 200px 10px 10px;
          position: relative;
          border: 1px solid black;
          margin-bottom: 20px;
          .item {
            width: 100%;
            @extend .flex-by-column-centered;
            margin-top: 10px;
            padding: 10px;
            .item-name-quantity {
              @extend .flex-by-row-between;
              align-items: flex-start;
              width: 100%;
              .item-quantity.multiple {
                font-weight: 500;
                color: red;
              }
            }
            .item-special-instructions {
              width: 100%;
              text-align: left;
              font-weight: 500;
              color: red;
            }
          }
          .item:first-child {
            margin-top: 0px;
          }
          .item.without-quantity {
            background-color: var(--background-grey);
            text-align: left;
            line-height: 2;
            @extend .flex-by-column-left;
            align-self: stretch;
            span {
              justify-content: space-between;
            }
            .item-header {
              width: 100%;
              @extend .flex-by-row-between;
            }
            .item-description {
              width: 100%;
              @extend .flex-by-row-between;
              align-items: flex-start;
              padding: 0px;
              margin-bottom: 10px;
              label {
                line-height: 1.4;
                margin: 0 10px 0 0;
                font-size: 10px;
                color: black;
              }
              span {
                line-height: 1.4;
                font-size: 10px;
                align-self: stretch;
                text-align: right;
              }
            }
            .item-name {
              text-transform: capitalize;
            }
            .item-selections {
              border-top: 1px dashed grey;
              font-size: 11px;
              width: 100%;
              padding-top: 5px;
              label {
                color: black;
              }
              .selection-item-wrapper {
                width: 100%;
                @extend .flex-by-row-between;
              }
            }
            label {
              color: red;
              font-weight: 500;
              text-transform: capitalize;
              margin-top: 5px;
            }
            .item-instructions {
              width: 100%;
              border: 1px solid red;
              font-size: 11px;
            }
          }
          .cancel-item {
            width: 150px;
            padding: 10px;
            // border: 1px solid black;
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }
      }
    }
  }
  .current-batch.show-summary {
    // border: 1px solid black;
    width: calc(100% - 40px);
    // margin: 20px 0px;
    @extend .card;
    .order:last-child {
      margin-bottom: 0px;
    }
    .order {
      .order-details {
        .item-wrapper {
          .cancel-item {
            display: none;
          }
        }
      }
    }
  }
  .current-batch.show-details {
    .order {
      @extend .card;
      padding: 20px;
    }
    margin: 0px;
    .send-email {
      @extend .action-black;
      padding: 30px;
      background-color: rgb(89, 255, 47);
      text-align: center;
      color: white;
      margin-bottom: 20px;
      width: 100%;
      margin: 10px 0px 20px 0px;
    }
  }

  .current-batch.drivers {
    width: 100%;
    @extend .flex-by-column-left;
    flex-wrap: wrap;
    padding: 0px 0px 200px 0px;
    align-items: flex-start;
    position: relative;
    .route {
      width: 100%;
      @extend .card;
      @extend .text-12-300;
      margin-top: 10px;
      padding: 5px 15px;
      .driver-name {
        font-weight: 500;
      }
      .customers {
        @extend .flex-by-row-left;
        flex-wrap: wrap;
        .customer {
          @extend .flex-by-row-left;
          margin-right: 8px;
          margin-bottom: 5px;
          // align-items: flex-start;
          .stop {
            width: 15px;
            height: 15px;
            line-height: 15px;
            display: inline-block;
            border: 1px solid black;
            border-radius: 50%;
            text-align: center;
            margin-right: 5px;
          }
          .name {
            line-height: 15px;
          }
        }
      }
    }
  }

  .cancel-item {
    width: 100%;
    padding: 10px 0px;

    .cancel-item-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }
  }
  .cancel-item-form {
    z-index: 100;
    padding: 20px;
    position: absolute;
    @extend .flex-by-column-centered;
    @extend .card;
    background-color: white;
    right: 10px;
    top: 60px;

    .cancel-item-quantity {
      justify-content: center;
      align-items: center;
      width: 100%;
      label {
        font-size: 13px;
        // padding: 3px 10px;
        text-transform: capitalize;
      }
      input {
        display: inline-block;
        @extend .text-12-300;
        border: 1px solid var(--background-grey);
        width: 100%;
        box-sizing: border-box;
        margin: 0px;
        text-align: center;
        padding: 3px 10px;
      }
    }
    button {
      @extend .action-black;
      margin-top: 10px;
      width: 100%;
    }
  }
}
@media only screen and (min-width: 500px) {
  .current {
    .current-summary {
      padding: 50px;
    }
    .current-batch {
      padding: 50px;
      padding-bottom: 120px;
      // margin-top: 30px;
    }
    .current-batch.show-summary {
      margin: 40px 0px;
    }
    .current-batch.drivers {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      .route {
        margin: 10px;
        max-width: 200px;
        align-self: stretch;
      }
    }
  }
}
