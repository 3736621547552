@import '../../index.scss';
.expand-toggle {
  @extend .flex-by-row-centered;
  width: auto;
  height: 24px;
  svg {
    font-size: 16px;
    margin-left: 8px;
  }
}
