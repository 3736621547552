@import './index.scss';
.restaurant-history {
  width: 100%;
  padding-top: 70px;
  @extend .flex-by-column-left;
  .restaurant-summary {
    @extend .flex-by-column-centered;
    padding: 5px 0px 10px 0px;
    width: 100%;
    max-width: 100%;
    position: fixed;
    top: 0px;
    z-index: 1;
    .close-history {
      position: fixed;
      top: 15px;
      right: 15px;
      svg {
        font-size: 20px;
      }
    }
    .restaurant-name {
      @extend .text-title;
      color: black;
    }
    .meal-count {
      width: calc(100% - 40px);
      label {
        font-weight: 500;
      }
      max-width: 460px;
    }
  }
  .restaurant-meal {
    position: relative;
    @extend .flex-by-row-left;
    align-items: flex-start;
    margin: 20px 0px;
    width: 100%;
    // max-width: 600px;
    padding: 20px;
    .restaurant-summary {
      position: relative;
      width: 50%;
      padding: 0px;
      .meal-summary {
        position: relative;
        @extend .card;
        margin: 0px;
        padding: 10px;
        @extend .flex-by-row-between;
        width: 100%;
        span {
          @extend .text-12-300;
          margin-right: 20px;
        }
        span.meal-date {
          position: relative;
        }
      }
      .toggle-map {
        @extend .action-black;
        width: 100px;
        // margin-bottom: 20px;
        position: relative;
        font-size: 12px;
        svg {
          position: absolute;
          right: 10px;
        }
      }
      .meal-stops {
        display: block;
        // width: calc(100vw - 20px);
        width: 100%;
        height: 300px;
        box-sizing: border-box;
        position: relative;
        border-top: 1px solid #ccc;
        padding: 10px 0px;
        .stops-map {
          // width: calc(100vw - 20px);
          width: 100%;
          height: 280px;
          position: absolute;
          top: 10px;
          left: 0px;
        }
      }
    }
    .block-summary {
      @extend .card;
      @extend .flex-by-column-left;
      margin-left: 20px;
      padding: 10px;
      width: 50%;
      @extend .text-12-300;
      .block-total {
        @extend .flex-by-row-between;
        width: 100%;
      }
      .meal-time {
        @extend .flex-by-row-centered;
        width: 100%;
        margin-bottom: 10px;
        @extend .text-title;
      }

      .participant-meal {
        width: 100%;
        margin-bottom: 10px;
        @extend .flex-by-row-between;
        padding: 0px 10px;
        .restaurant {
          width: 40%;
          text-transform: capitalize;
          font-weight: 500;
          @extend .flex-by-row-left;
          // span {
          //   display: inline-block;
          //   @extend .overflow-ellipsis;
          //   max-width: 80%;
          // }
          a {
            // background-color: var(--background-grey);
            // margin-left: 5px;
            text-align: center;
            // padding: 5px;
            // width: 60px;
            height: 20px;
            line-height: 20px;
            display: block;
            @extend .overflow-ellipsis;
            border-bottom: 1px solid black;
          }
        }

        .revenue,
        .profit,
        .orders {
          width: 20%;
          text-align: right;
          padding: 0px 10px;
        }
        .revenue.good {
          background-color: green;
          color: white;
          font-weight: 500;
        }
        .revenue.bad {
          background-color: red;
          color: white;
          font-weight: 500;
        }

        .profit.positive {
          background-color: green;
          color: white;
          font-weight: 500;
        }
        .profit.negative {
          background-color: red;
          color: white;
          font-weight: 500;
        }
      }
      .participant-meal.self {
        background-color: yellow;
      }
      .participant-meal:last-of-type {
        margin-bottom: 0px;
      }
      .days-since-last {
        position: absolute;
        bottom: -20px;
      }
    }
    .block-summary.lunch {
      background-color: var(--lunch-color);
    }
    .block-summary.dinner {
      background-color: var(--dinner-color);
    }
  }
}
@media only screen and (min-width: 1100px) {
}
