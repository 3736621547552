@import "../tasting_menu/components/index.scss";
.select-wrapper {
  @extend .flex-by-column-centered;
  // border-radius: 4px;
  position: relative;
  .expand-toggle {
    @extend .text-14-cap;
    text-align: left;
    margin: 0px;
    padding: 10px;
  }
  button.expand-toggle {
    margin: 0px;
  }
  .select-option-list {
    @extend .card;
    @extend .flex-by-column-left;
    position: absolute;
    top: 52px;
    right: 0px;
    padding: 10px;
    z-index: 2;
    button {
      margin: 0px;
    }
    .select-option {
      @extend .flex-by-row-left;
      // @extend .text-14-cap;

      color: var(--text-grey);
      // height: 42px;
      width: 100%;
      padding-right: 40px;
      margin-bottom: 20px;
      position: relative;
      text-align: left;
      align-items: flex-start;

      svg {
        // display: none;
        margin-top: 2px;
        margin-right: 10px;
      }
    }
    .select-option.selected-option {
      color: black;
      font-weight: 500;
      svg.fa-check {
        display: inline-block;
        position: absolute;
        right: 0px;
        font-size: 18px;
        color: var(--main-purple);
      }
    }
    .select-option:last-child {
      margin-bottom: 0px;
    }
  }
  .select-option-list::after {
    background-color: white;
    border: none;
  }
}
.select-chip {
  @extend .flex-by-row-between;
  @extend .text-14-cap;
  width: auto;
  height: 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  padding-left: 12px;
  color: var(--main-purple);
  border: 1px solid var(--main-purple);
  border-radius: 120px;
  .select-chip-name {
    @extend .flex-by-row-centered;
  }
  .select-chip-status {
    @extend .flex-by-row-centered;
    margin-left: 4px;
    width: 20px;
    svg {
      font-size: 16px;
    }
  }
}
.select-chip.selected-chip {
  background-color: var(--main-purple);
  color: white;
  opacity: 1;
}
.must-select {
  // @extend .flex-by-row-left;
  display: flex;
  flex-wrap: wrap;
  z-index: 100;
  background-color: white;
  width: 280px;
  // position: fixed;
  padding: 90px 10px 10px 10px;
  // margin-top: calc(var(--nav-height) / 2);
  box-sizing: border-box;
  left: max(40px, 50vw - 250px);
  top: max(40px, 50vh - 400px);
  width: calc(100vw - 80px);
  max-width: 500px;
  height: calc(100vh - 80px);
  max-height: 800px;
  align-items: flex-start;
  justify-content: flex-start;
  position: fixed;
  align-content: flex-start;
  // height: 100vh;
  button {
    // @extend .flex-by-row-centered;
    font-family: "Montserrat", sans-serif;
    text-transform: capitalize;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding: 0 10px;
    // min-width: 170px;
    height: 30px;
    line-height: 30px;
    border: 1px solid black;
    box-shadow: none;
    box-sizing: border-box;
    margin: 5px;
    flex-shrink: 1;
  }
  .header {
    position: fixed;
    @extend .action-black;
    border: 1px solid black;
    box-sizing: border-box;
    height: 80px;
    text-align: center;
    // position: fixed;
    opacity: 1;
    margin: 0px;
    left: max(40px, 50vw - 250px);
    top: max(40px, 50vh - 400px);
    width: calc(100vw - 80px);
    max-width: 500px;
    @extend .flex-by-column-centered;
    .title {
      // @extend .text-title-16;
      letter-spacing: 1px;
    }
    .sub-title {
      @extend .text-12-300;
      margin-top: 10px;
      line-height: 1.2;
      font-size: 10px;
      text-transform: none;
    }
  }
}
@media only screen and (min-width: 360px) {
  .must-select {
    top: calc(var(--nav-height) / 2);
    padding-top: calc(var(--nav-height) + 20px);
    .header {
      top: calc(var(--nav-height) / 2);
    }
  }
}
