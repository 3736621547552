@import '../../../index.scss';

.restaurant-banner {
  @extend .flex-by-column-centered;
  width: 100%;
  padding: 28px 0px;
  box-sizing: border-box;
  background-color: rgba(246, 244, 240, 0.8);
  border-bottom: 1px solid rgb(217, 217, 217);
  .restaurant-body {
    @extend .flex-by-column-centered;
    width: 100%;
    text-align: center;
    .name {
      @extend .flex-by-row-centered;
      font-weight: 500;
      font-size: 18px;
      line-height: 1.6;
      letter-spacing: 2.6px;
      text-transform: uppercase;
      margin-bottom: 22px;
      width: 100%;
      box-sizing: border-box;
      padding: 0px 80px;
    }
    .address,
    .city,
    .phone {
      @extend .flex-by-row-centered;
      width: 100%;
      box-sizing: border-box;
      padding: 0px 60px;
      font-weight: 300;
      font-size: 12px;
      line-height: 20px;
      letter-spacing: 0.6px;
      margin-bottom: 2px;
    }
    .address {
      margin-bottom: 20px;
    }
    .phone {
      font-weight: 500;
      font-size: 13px;
    }
  }
}

@media only screen and (min-width: 500px) {
  .restaurant-banner {
    padding-left: calc(50% - 250px);
    padding-right: calc(50% - 250px);
    box-sizing: border-box;
  }
}
