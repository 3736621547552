@import './index.scss';
.list.day-details {
  max-width: 100vw;
  .meal-wrapper {
    @extend .flex-by-column-top-left;
    .meal-details {
      padding: 20px;
      @extend .flex-by-row-centered;
      align-items: flex-start;
      flex-wrap: wrap;
      margin-bottom: 30px;
      width: 100%;
      .meal-header {
        width: 100%;
        @extend .flex-by-column-centered;
        .meal-restaurant,
        .meal-type,
        .meal-due {
          text-align: center;
          @extend .text-14-300;
          text-transform: uppercase;
          letter-spacing: 2px;
          font-weight: 500;
          margin-bottom: 20px;
        }
        .meal-restaurant {
          span {
            background-color: yellow;
          }
        }
      }
      .order-details {
        @extend .card;
        max-width: 500px;
        margin: 10px;
        padding: 10px 30px;
        width: 100%;
        div {
          width: 100%;
        }
        div {
          .field {
            width: 100%;
            margin-bottom: 10px;
            @extend .flex-by-row-between;
            align-items: flex-start;
            @extend .text-12-300;
            label {
              text-transform: capitalize;
            }
            span {
              text-align: right;
              text-transform: none;
            }
          }
          .field:last-child {
            margin-bottom: 0px;
          }
        }

        .customer {
          padding: 20px 0px;
          border-bottom: 1px dashed black;
        }
        .dishes {
          .dish-details {
            border-bottom: 1px dashed black;
            padding: 20px 0px;
            .cancel-quantity {
              color: red;
              font-weight: 500;
            }
          }
        }
        .order-summary {
          padding: 20px 0px;
          border-bottom: 1px dashed black;
          width: 100%;
          .mismatch {
            color: red;
            font-weight: 500;
            text-transform: capitalize;
          }
          .in-store-total {
            background-color: yellow;
            font-weight: 500;
          }
        }
        .refunds,
        .cancel-item {
          padding: 20px 0px;
          position: relative;
          // border-bottom: 1px dashed black;
          .refund-toggle,
          .cancel-item-toggle {
            svg: {
              position: relative;
              top: 5px;
            }
          }
          .refund-form,
          .cancel-item-form {
            padding: 30px;
            @extend .flex-by-column-centered;
            .refund-amount,
            .cancel-item-quantity {
              align-items: center;
              label {
                margin-left: calc(50% - 50px);
                margin-right: 10px;
                font-size: 13px;
                padding: 3px 0px;
              }
              input {
                display: inline-block;
                @extend .text-12-300;
                border: 1px solid var(--background-grey);
                width: 50px;
                margin: 0px;
                text-align: center;
                margin-right: calc(50% - 10px);
                padding: 3px 10px;
              }
            }
            button {
              @extend .action-black;
              margin-top: 30px;
            }
          }
        }
        .cancel-item {
          padding: 0;
        }
      }
    }
  }
}
