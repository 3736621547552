@import "./index.scss";
.weekly-menu {
  width: 100%;
  @extend .flex-by-column-centered;
  position: relative;
  // margin-top: 80px;
  & > .announcement {
    top: var(--nav-height);
    border-top: 1px solid var(--background-grey);
    box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
  }

  .loading {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    z-index: 100;
  }
  padding-top: var(--announcement-height-short);
  .weekly-header {
    width: 100%;
    text-align: center;
    position: sticky;
    top: var(--nav-height);
    background: #fff;
    z-index: 4;
    // border-bottom: 1px solid var(--border-grey);
    border-top: 1px solid var(--border-grey);
  }
  .weekly-header-placeholder {
    width: 100%;
    height: calc(var(--subnav-height) + var(--subnav-height-short));
  }
  .weekly-menu-month {
    // font-size: 16px;
    // letter-spacing: 1.8px;
    // font-weight: 500;
    // text-transform: uppercase;
    height: var(--subnav-height);
    line-height: var(--subnav-height);
    @extend .text-title;
    font-size: 28px;
  }
  .weekly-menu-days {
    width: 100%;
    padding: 0px;
    box-shadow: 0px 2px 4px 0px rgba(28, 29, 33, 0.2);
    background-color: var(--background-beige);
    .weekly-menu-day {
      color: black;
      width: 100%;
      height: var(--subnav-height-short);
      text-align: center;
      // padding: 12px;
      font-weight: 500;
      font-size: 13px;
      text-transform: uppercase;
      letter-spacing: 1.2px;
      line-height: var(--subnav-height-short);
      &.active {
        background: rgba(0, 128, 0, 0.8);
      }
    }
  }

  // .day-menu-date.desktop {
  //   display: none;
  //   @extend .text-title;
  // }
  // .day-menu-date.closed-date {
  //   opacity: 0.3;
  // }
  .view-meal {
    // border-bottom: 1px solid black;
    // background-color: black;
    // color: white;
    position: relative;
    @extend .flex-by-column-top;
    width: 100%;
    align-items: flex-start;
    .meal-type {
      padding: 0px 2px;
      text-align: center;
      // @extend .text-title;
      @extend .flex-by-row-centered;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 15px;
      font-weight: 500;
      letter-spacing: 0.8px;
      // text-transform: uppercase;
      line-height: 25px !important;
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      // justify-content: space-between;
      // padding-bottom: 5px;
      // background-color: yellow;
      // @extend .with-curls;
      svg {
        margin-right: 5px;
        font-size: 13px;
        margin-bottom: 2px;
      }
      .meal-type-container {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        // background-color: var(--background-beige);
        width: calc(100% - 40px);
        flex-wrap: wrap;
        span {
          @extend .text-12-300;
          text-transform: capitalize;
          margin-right: 5px;
          font-size: 13px;
          font-weight: 500;
          // display: inline-block;
          b {
            font-weight: 600;
            font-size: 13.5px;
            line-height: 21px;
          }
          text-align: left;
          line-height: 21px;
          white-space: nowrap;
          &::after {
            // using dot to separate city and number of restaurants
            content: "•";
            margin-left: 5px;
          }
          &:last-child {
            &::after {
              display: none;
            }
          }
        }
      }

      // span.meal-type-text {
      //   margin-right: 10px;
      //   text-transform: capitalize;
      // }
      // span.city {
      //   display: inline-block;
      //   // width: 30px;
      //   height: 30px;
      //   padding: 0px 15px;
      //   // border: 1px solid var(--border-grey);
      //   border-radius: 15px;
      //   line-height: 30px;
      //   margin-right: 10px;
      //   font-weight: 400;
      //   background-color: var(--background-beige);
      //   b {
      //     font-weight: 500;
      //     text-transform: uppercase;
      //   }
      // }
      button {
        background-color: transparent;
        border: none;
        padding: 0px;
        margin: 0px;
        height: 20px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: 0px;
        svg {
          font-size: 20px;
          margin: 0px;
        }
      }
    }
    .meal-type::before,
    .meal-type::after {
      top: 12px;
    }
    .delivery-window {
      @extend .text-11-300;
      // font-size: 9px;
      letter-spacing: 1px;
      b {
        font-weight: 500;
      }
      margin-bottom: 10px;
    }
    .delivery-window.desktop {
      display: none;
    }
    // margin-bottom: 20px;
  }
  .view-meal.empty {
    height: 50px;
    background-color: var(--background-grey);
    margin-top: 20px;
    width: 100%;
  }
  .view-meal.closed {
    border-bottom: 1px solid transparent;
  }
  .meal-list {
    // background-color: red;
    width: 100%;
    @extend .flex-by-column-even;
    .meal-menu-container {
      // border: 1px solid var(--border-grey);
      background-color: var(--background-beige);
      // align-self: stretch;
      // flex-grow: 1;
      // flex-shrink: 1;
      display: block;
      // @extend .card;
      // width: 40%;
      width: 100%;
      // height: 120px;
      // @extend .flex-by-column-top;
      position: relative;
      padding: 0px;
      // overflow: hidden;
      // margin: 0px;
      // height: 150px;
      // @extend .card;
      margin-bottom: 10px; //var(--meal-padding);
      .meal-menu {
        // width: 100%;
        // height: 100%;
        // align-self: stretch;
        // flex-grow: 1;
        @extend .flex-by-row-left;
        // background-color: white;
        position: relative;
        box-sizing: border-box;
        height: auto;
        padding-left: 40%;

        .capacity-left {
          @extend .flex-by-row-centered;
          box-sizing: border-box;
          background-color: rgba(211, 65, 65, 0.9);
          color: white;
          @extend .text-12-300;
          text-align: center;
          font-weight: 500;
          position: absolute;
          top: 8px;
          z-index: 2;
          border-radius: 10px;
          padding: 6px 0px;
          width: 130px;
          left: calc(20% - 65px);
          text-transform: capitalize;
          flex-wrap: wrap;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.6px;
          line-height: 1.5;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          white-space: normal;
          word-wrap: break-word;
        }
        .late-cutoff {
          // @extend .flex-by-row-centered;
          // justify-content: flex-start;
          box-sizing: border-box;
          display: inline-block;
          padding: 3px 6px;
          background-color: rgb(255, 227, 211, 0.9);
          color: black;
          @extend .text-12-300;
          text-align: left;
          font-weight: 500;
          // margin-bottom: 5px;
          // position: absolute;
          // top: 8px;
          z-index: 2;
          border-radius: 5px;
          padding: 3px 8px;
          // width: 130px;
          width: calc(100% - 5px);
          // left: calc(20% - 80px);
          left: 5px;
          text-transform: capitalize;
          // flex-wrap: wrap;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 0.6px;
          line-height: 1.5;
          height: auto;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          white-space: normal;
          word-wrap: break-word;
          svg {
            margin-right: 3px;
            color: black;
          }
        }
        .meal-menu-order {
          position: absolute;
          top: 50px; //calc((var(--meal-height) - var(--badge-height)) / 2);
          // right: -30px;
          // right: calc(50% - 35px);
          // left: calc(var(--meal-height) * var(--meal-width-height-ratio) - 35px);
          left: calc(40% - var(--badge-height) / 2);
          // right: -30px;

          // background-color: rgba(0, 128, 0, 0.8);
          color: white;
          width: var(--badge-height);
          height: var(--badge-height);
          border-radius: 50%;
          @extend .text-12-300;
          @extend .flex-by-column-centered;
          @extend .text-title;
          padding: 10px 0px 13px 0px;
          justify-content: space-between;
          label {
            width: 80%;
            font-size: 10px;
            text-align: center;
            line-height: 1.15;
          }
          span {
            font-size: 16px;
            // text-decoration: underline;
            // line-height: 1.1;
            // padding-bottom: 1px;
            line-height: 1.1;
            letter-spacing: 0.5px;
            border-bottom: 1px solid black;
          }
          z-index: 2;
          // box-shadow: 0px 0px 4px 2px rgba(28, 29, 33, 0.4);
          svg {
            font-size: 16px;
          }
          span.ordered-label {
            font-size: 10px;
          }
        }

        .meal-menu-order.order-history {
          background-color: rgb(157, 220, 255, 0.8);
          color: black;
        }
        .meal-menu-order.ordered {
          // background-color: var(--background-darkgreen);
          // background-color: rgba(48, 150, 122, 0.8);
          background-color: var(--background-vivid-blue);
          box-sizing: border-box;
          padding: 12px 0px 20px 0px;
          left: calc(40% - var(--badge-height) / 2);
          // calc(
          //   var(--meal-height) * var(--meal-width-height-ratio) -
          //     var(--badge-height) / 2
          // );
          right: auto;
          span {
            font-size: 12px;
            border-color: white;
          }
        }
        .meal-menu-order.ordered.cart {
          background-color: rgba(0, 128, 0, 1);
          padding-top: 18px;
          // color: black;
          span {
            margin-top: 6px;
            // border-color: black;
          }
        }
        .meal-note {
          @extend .meal-menu-order;
          position: absolute;
          // left: calc(
          //   var(--meal-height) * var(--meal-width-height-ratio) -
          //     var(--badge-height) / 2
          // );
          left: calc(0px - var(--badge-height) / 2);
          right: auto;
          // left: calc(
          //   var(--meal-height) * var(--meal-width-height-ratio) -
          //     var(--badge-height) / 2
          // );
          top: calc((100% - var(--badge-height)) / 2);
          color: black;
          span {
            border: none;
          }
          // @extend .text-12-300;
          // font-size: 10px;
          // line-height: 1.5;
          // font-weight: 500;
          // padding: 5px 5px;
          // text-align: center;
          // text-transform: capitalize;
          // // width: calc(var(--meal-height) * var(--meal-width-height-ratio));
          // height: var(--badge-height);
          // width: var(--badge-height);
          // border-radius: 50%;
          // z-index: 1;
          // @extend .flex-by-column-centered;
          // .header {
          //   position: absolute;
          //   @extend .text-title;
          //   font-size: 10px;
          //   line-height: 1.1;
          //   top: 12px;
          //   svg {
          //     margin-right: 2px;
          //   }
          // }
          // .markup-footer {
          //   position: absolute;
          //   // @extend .text-12-300;
          //   font-size: 5px;
          //   line-height: 1.4;
          //   bottom: 7px;
          // }
        }
        .meal-note.mark-up {
          // background-color: yellow;
          background-color: var(--background-gold); //var(--hero-color);
          background-color: rgb(255, 191, 0, 0.8);
          padding: 11px 0px 14px 0px;
          label {
            line-height: 1.1;
          }
          // @extend .text-title;
          // .header {
          //   position: absolute;
          //   // @extend .text-12-300;
          //   font-size: 10px;
          //   // line-height: 1.2;
          //   // font-size: 10px;
          //   top: 10px;
          //   font-weight: 300;
          //   svg {
          //     font-size: 10px;
          //     position: absolute;
          //     bottom: 5px;
          //   }
          // }
          // .highlight {
          //   // @extend .text-12-300;
          //   line-height: 1;
          //   margin: 27px 0px 0px 0px;
          //   // font-size: 13px;
          //   font-weight: 500;
          //   font-size: 16px;
          // }
          // .svg {
          //   font-size: 10px;
          //   position: absolute;
          //   bottom: 0px;
          // }
        }
        .meal-note.mark-up.small {
          span {
            font-size: 14px;
          }
        }
        .meal-note.exclusive {
          // background-color: black;
          // color: gold;
          // background-color: var(--background-brown);
          background-color: rgb(191, 155, 48, 0.8);
          // color: white;
          // font-weight: 600;
          // letter-spacing: 0.8px;
          svg {
            font-size: 14px;
          }
          span {
            @extend .text-title;
            font-size: 10px;
            // margin-top: 5px;
            font-weight: 300;
            width: 50px;
            line-height: 1.2;
            text-align: center;
            // position: absolute;
            // bottom: 5px;
            // top: 5px;
          }
        }
        .meal-note.universal {
          background-color: #a0e7e5;
          display: none;
          @extend .text-12-300;
          line-height: 1.6;
          font-size: 8px;
        }
        .alert-container {
          position: absolute;
          bottom: 10px;
          left: 0px;
          z-index: 2;
          @extend .flex-by-row-centered;
          justify-content: space-evenly;
          // background-color: pink;
          width: 40%;
          // padding-left: 15px;
          // padding-right: 100px;
          // padding-top: 8px;
        }
        .overlay {
          position: absolute;
          top: 0px;
          left: 0px;
          // width: calc(var(--meal-height) * var(--meal-width-height-ratio));
          width: 40%;
          height: 100%;
          // display: none;
          background-color: rgba(0, 0, 0, 0.25);
          z-index: 1;
          padding: 7px;
          @extend .flex-by-column-centered;
          align-items: flex-start;
          justify-content: flex-start;
          .which-meal {
            @extend .text-12-300;
            svg {
              margin-right: 4px;
              font-size: 10px;
              margin-bottom: 1px;
            }
            font-weight: 500;
            font-size: 11px;
            text-align: center;
            line-height: 24px;
            height: 26px;
            padding-bottom: 2px;
            // color: white;
            color: black;
            background-color: var(--background-pink-peach);
            text-transform: capitalize;
            width: auto;
            flex-grow: 0;
            padding: 0px 8px;
            border-radius: 12px;
            // margin-top: 10px;
            // position: absolute;
            // left: 10px;
            // top: 10px;
            // width: auto;
          }
          .restaurant-labels {
            width: 100%;
            position: absolute;
            bottom: 7px;
            left: 7px;
            @extend .flex-by-row-left;
            flex-wrap: wrap;
            padding: 0px 7px 0px 0px;
            button: {
              background-color: white;
            }
            // button:first-child {
            //     margin-right: 0px!important;
            //     margin-bottom: 5px!important;
            // }
            .restaurant-bookmark {
              @extend .flex-by-row-centered;
              background-color: white;
              .bookmark-note {
                @extend .text-12-300;
              }
            }
          }
          .which-meal.early {
            font-size: 11px;
          }
          .which-meal.cuisine-tag {
            background-color: var(--background-gold);
            margin-top: 5px;
            svg {
              font-size: 12px;
              margin: 0px;
              margin-right: 5px;
            }
          }
        }

        .which-meal.new-restaurant-tag {
          background-color: var(--background-lightgreen);
          svg {
            font-size: 12px;
            margin: 0px;
            margin-right: 5px;
          }
        }

        img.hero-dish-image {
          // min-width: calc(var(--meal-height) * var(--meal-width-height-ratio));
          // max-width: calc(var(--meal-height) * var(--meal-width-height-ratio));
          // border-radius: 50%;
          // height: var(--meal-height);
          align-self: stretch;
          object-fit: cover;
          min-width: 40%;
          max-width: 40%;
          height: 100%;
          position: absolute;
          left: 0px;
          top: 0px;
          // display: none;
        }
        div.hero-dish-image {
          // width: calc(var(--meal-height) * var(--meal-width-height-ratio));
          // height: var(--meal-height);
          width: 40%;
          background-color: var(--background-grey);
        }
        .meal-text {
          padding: 30px 10px 10px 10px;
          // height: var(--meal-height);
          position: relative;
          @extend .flex-by-column-centered;
          // align-self: stretch;
          // flex-grow: 1;
          // justify-content: center;∂
          justify-content: space-between;
          width: 100%;
          .new-restaurant {
            @extend .flex-by-row-centered;
            position: absolute;
            top: 8px;
            width: 100%;
            gap: 8px;
            // border: 1px solid pink;
          }
          .new-restaurant.multiple-logos {
            @extend .flex-by-row-right;
            padding-right: 80px;
          }
          .restaurant-name {
            @extend .text-13-500;
            font-size: 14px;
            text-align: center;
            text-transform: uppercase;
            // margin: 10px 0px;
            width: calc(100% - 20px);
            line-height: 1.3;
            color: black;
            letter-spacing: 1.5px;
            // position: absolute;
            // left: 20px;
            // top: 15px;
            display: inline-block;
            margin-bottom: 10px;
            -webkit-line-clamp: 2;
          }
          .last-call {
            @extend .text-13-500;
            letter-spacing: 1.2px;
            line-height: 1;
            text-transform: uppercase;
            // border-bottom: 1px solid rgba(0, 0, 0, 0.6);
            // border: 1px solid rgba(0, 0, 0, 0.6);
            padding: 0px 25px;
            height: 45px;
            line-height: 45px;
            background-color: black;
            color: white;
            margin-top: 10px;
            border-radius: 10px;
            svg {
              // margin-left: 5px;
              // font-size: 11px;
              display: none;
            }
          }
          .last-call.closed {
            border: none;
            background-color: var(--text-grey);
            svg {
              display: inline-block;
              margin-left: 5px;
              font-size: 12px;
            }
            // color: black;
          }
          .last-call.desktop {
            display: none;
          }
        }
        .meal-text.has-logo {
          padding-top: 65px;
        }
        .meal-text.empty {
          width: 60%;
          height: 150px;
          padding: 20px;
          justify-content: flex-start;
        }
        .miles {
          // margin-top: 12px;
          span {
            // @extend .text-12-300;
            // font-weight: 500;
            text-transform: capitalize;
          }
        }
        .restaurant-info {
          @extend .text-12-300;
          // @extend .text-title;
          @extend .flex-by-row-centered;
          // justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          // color: var(--text-grey);
          // color: #555;
          font-weight: 400;
          font-size: 13px;
          // font-weight: 300;
          .info-field {
            // margin-right: 0px;
            position: relative;
            @extend .flex-by-row-left;
            flex-wrap: nowrap;
            line-height: 1.6;
            svg {
              color: #333;
            }
          }
          .info-field.honor {
            svg {
              margin-right: 2px;
              font-size: 12px;
            }
          }
          .info-field.member {
            margin: 0 0 0 0;
            // padding-right: 15px;
            border-radius: 5px;
            // width: 170px;
            text-align: center;
            @extend .flex-by-row-centered;
            // align-items: flex-start;
            // height: 40px;
            // @extend .text-title;
            // font-size: 12px;
            .icon-container {
              @extend .flex-by-row-centered;
              // width: 23px;
              // height: 23px;
              // border-radius: 50%;
              // background-color: #bf9b30;
              margin-right: 4px;
              flex-shrink: 0;
              margin-bottom: 2px;
              svg {
                color: #bf9b30;
                font-size: 12px;
              }
            }
          }
          .info-field.saving {
            // background-color: rgba(255, 215, 0, 0.8)
          }
          .info-field.only {
            text-transform: capitalize;
            // background-color: #bf9b30;
          }
          .info-field.yelp {
            // border: 2px solid #ff1a1a;
            label {
              // background-color: #ff1a1a;
              // background-color: white;
              padding: 0px 4px;
              // margin-right: 5px;
              // font-weight: 500;
              // border-radius: 4px;
              svg {
                // margin: 0px 2px 2px 2px;
                // font-size: 9px;
                color: #ff1a1a;
                margin-right: 2px;
                // font-size: 12px;
              }
            }
            span {
              // background-color: white;
              // padding-left: 4px;
            }
          }
          // .info-field::after {
          //   content: "•";
          //   position: absolute;
          //   font-size: 26px;
          //   top: -12px;
          //   right: -12px;
          // }
          .info-field:last-child {
            margin-bottom: 0px;
            margin-right: 0px;
          }
          .info-field:last-child::after {
            display: none;
          }
        }
        .restaurant-info.line-1 {
          margin-bottom: 0px;
          @extend .flex-by-column-centered;
        }
        .yelp-info {
          // background-color: rgb(255, 26, 26, 0.75);
          // right: auto;
          // left: -30px;
          // display: none;
          @extend .flex-by-column-centered;
          // justify-content: space-between;
          margin: 12px 0px;
          align-items: flex-start;
          // padding: 6px 0 5px 0;
          // color: black;
          position: relative;
          // padding-bottom: 18px;
          align-self: stretch;
          .yelp-reviews {
            @extend .flex-by-row-left;
            svg {
              font-size: 10px;
            }
            span {
              @extend .text-12-300;
              font-size: 12px;
              font-weight: 500;
              line-height: 1;
            }
            .yelp-title {
              // @extend .text-title;
              // font-size: 12px;
              margin-right: 3px;
            }
            .number-reviews {
              margin-left: 3px;
            }
          }
          .order-history-info {
            @extend .text-12-300;
            line-height: 1;
            margin-top: 8px;
            // position: absolute;
            // bottom: 0px;
            // left: 0px;
          }
          // svg {
          //   font-size: 9px;
          // }

          // span.yelp-title {
          //   @extend .text-title;
          //   font-size: 12px;
          //   font-weight: 400;
          //   margin-top: 1px;
          //   margin-bottom: 5px;
          // }
          // span.number-reviews {
          //   font-size: 12px;
          //   font-weight: 500;
          //   margin-top: 3px;
          // }
          // span.review-footer {
          //   font-size: 9px;
          // }
        }
      }
      .meal-menu.empty {
        background: white;
        border: 1px solid var(--border-grey);
        justify-content: flex-end;
        height: 150px;
      }
    }

    .meal-menu-container:last-child {
      margin: 0px;
    }
    .meal-menu-container[disabled] {
      color: black;
      .meal-menu {
        .meal-text {
          .view-meal {
            border: none;
          }
        }
      }
    }
    .meal-menu-container[disabled]:hover {
      cursor: not-allowed;
    }
    .meal-menu-container.closed-container {
      // .meal-type {
      //   opacity: 0.4;
      // }
      // .hero-dish-image {
      //   opacity: 0.4;
      // }
      // .meal-text {
      //   opacity: 0.4;
      // }
      // .container {
      //   opacity: 0.4;
      // }
    }
  }
  .weekly-menu-content {
    width: 100%;
    @extend .flex-by-column-centered;
    // background-color: white;
    background-color: transparent;
    flex-wrap: nowrap;
    .warning-message {
      @extend .flex-by-column-centered;
      // @extend .card;
      height: 100px;
      margin: 20px;
      padding: 30px;
      @extend .text-12-300;
      text-align: center;
      label {
        font-size: 14px;
        font-weight: 400;
      }
      .auth-link {
        font-weight: 500;
        border-bottom: 1px solid black;
      }
    }

    .loading {
      left: 0px;
      top: 0px;
      z-index: 0;
    }
    .announcement {
      display: none;
      @extend .flex-by-column-centered;
    }
    .day-menu {
      position: relative;
      // width: 100%;
      margin-bottom: 20px;
      // max-width: calc(100% - 40px);
      width: 100%;
      max-width: 500px;
      // margin: 0px 20px 20px 20px;
      // background-color: var(--background-beige);
      // @extend .flex-by-row-top;
      // margin: 5px;
      // border-top: 1px solid var(--border-grey);
      // border-bottom: 1px solid var(--border-grey);
      @extend .card;

      .day-menu-date {
        @extend .flex-by-row-centered;
        padding: 30px 0px 20px 0px;
        align-items: flex-start !important;
        span {
          text-align: center;
          // font-size: 16px;
          // font-weight: 500;
          // border: solid 2px #000;
          // padding: 6px 0px;
          // margin-bottom: 6px;
          @extend .text-title;
          // font-size: 20px;
          // line-height: 26px;
          // display: inline-block;

          // height: var(--subnav-height);
          // line-height: var(--subnav-height);
        }
        span:first-child {
          margin-right: 6px;
          position: relative;
        }
        svg {
          font-size: 24px;
          margin-right: 20px;
        }
      }

      // .day-menu-date.high-light {
      //   span:first-child {
      //     font-size: 30px;
      //     margin-bottom: 10px;
      //   }

      // }
      .date-wrapper {
        @extend .flex-by-column-centered;
        margin: 0px 5px;
        .meal-date {
          // margin: 0px 8px;
          top: 0px;
          left: 0px;
          position: relative;
          // position: relative;
          // width: 100px;
        }
        .date-key {
          // position: absolute;
          display: none;
          height: 50px;
          width: 50px;
          line-height: 40px;
          @extend .text-title-16;
          font-size: 12px;
          text-transform: capitalize;
          letter-spacing: 1.2px;
        }
      }

      .menu-content {
        width: 100%;
        @extend .flex-by-column-even;
        margin-right: 10px;
        align-items: stretch;
        position: relative;
        .menu-meals {
          width: 100%;
          // width: 30%;
          position: relative;
          margin-bottom: 25px;
          // flex-grow: 1;
          // align-self: stretch;
          // padding-top: 30px;
          @extend .flex-by-column-centered;
          align-items: flex-start;
          // .meal-type {
          //   @extend .text-12-400;
          //   letter-spacing: 1.5px;
          //   color: white;
          //   background-color: rgba(0, 0, 0, 0.4);
          //   text-transform: uppercase;
          //   text-align: center;
          //   height: 30px;
          //   line-height: 30px;
          //   width: 100%;
          //   position: absolute;
          //   top: 0px;
          //   left: 0px;
          // }
        }
        .menu-meals.dinner {
          margin: 0px;
          // padding-bottom: 20px;
          // border-bottom: 1px solid var(--border-grey);
        }
      }
      // box-shadow: none;
    }
    .day-menu-date-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        font-size: 20px;
      }
      span:first-child {
        // margin: 0px 0px 10px 0px;
        font-size: 30px;
      }
    }
    .current-day {
      position: relative;
      // margin-top: 20px;
      width: 100%;
      @extend .flex-by-row-centered;
      // margin-bottom: 10px;
      > svg {
        position: absolute;
        top: 15px;
        right: 35px;
        font-size: 30px;
        z-index: 1;
        transform: rotate(35deg);
      }
      .day-menu {
        max-width: 500px;
        // width: 100%;
        // box-shadow: 0 4px 32px 2px rgba(28, 29, 33, 0.5);
        // margin: 0px;
        // border: none;
        // border-top: none;

        .day-menu-date {
          // flex-direction: column;
          // @extend .flex-by-row-centered;
          // flex-direction: row;
          // background-color: red;
          justify-content: space-between;
          // align-items: flex-start;
          // position: relative;

          // span {
          //   font-size: 30px;
          // }

          span:first-child::after {
            display: none;
          }
          button {
            background-color: transparent;
            border: none;
            padding: 0px;
            margin: 0px;
            height: 40px;
            width: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            // position: absolute;
            // top: 30px;
            // right: 0px;
            svg {
              font-size: 20px;
              margin: 0px;
            }
          }

          @media (min-width: 768px) {
            flex-direction: row;
            align-items: center;
            gap: 10px;

            span:first-child {
              margin: 0;
            }

            button {
              position: static;
              margin-left: 10px;
            }
          }
        }
      }
    }
    .current-day.empty {
      width: 100%;
      margin-top: 20px;
      .day-menu {
        border: 1px solid var(--border-grey);
      }
    }
    .scheduled-meals {
      width: 400px;
      max-width: calc(100vw - 40px);
    }
    .scheduled-meals.upvote,
    .scheduled-meals.nominate {
      span.nominate-title {
        display: none;
        @extend .text-title;
        font-size: 32px;
        font-weight: 400;
        line-height: 41.6px;
        letter-spacing: 0.6px;
        margin-bottom: 20px;
      }
      svg {
        font-size: 38px;
      }
    }

    .upcoming-days {
      width: 100%;
      .day-menu:first-child {
        border-top: none;
      }
      .day-menu:first-child::before {
        padding-left: 10px;
        text-align: left;
        width: auto;
        margin-left: 10px;
        padding-left: 0px;
        border-bottom: 1px solid black;
      }

      .day-menu-date {
        // @extend .flex-by-row-centered;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
        button {
          background-color: transparent;
          border: none;
          padding: 0px;
          margin: 0px;
          height: 40px;
          width: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
          // position: absolute;
          // top: 30px;
          // right: 0px;
          svg {
            font-size: 20px;
            margin: 0px;
          }
        }
        // button {
        //   background-color: transparent;
        //   border: none;
        //   padding: 0px;
        //   margin: 0px;
        //   height: 20px;
        //   width: 40px;
        //   display: flex;
        //   align-items: center;
        //   justify-content: center;
        //   // position: absolute;
        //   // right: 0px;
        //   svg {
        //     font-size: 20px;
        //     margin: 0px;
        //   }
        // }

        @media (min-width: 768px) {
          flex-direction: row;
          align-items: center;
          gap: 10px;

          span:first-child {
            margin: 0;
          }

          button {
            position: static;
            margin-left: 10px;
          }
        }
      }
    }
    // .current-day {
    //   .meal-list {
    //     .meal-menu-container {
    //       .meal-menu {
    //         .meal-menu-order {
    //           left: -10px;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  @media only screen and (max-width: 1200px) {
    .upcoming-days {
      display: flex;
      align-items: center;
      flex-direction: column;
    }

    .scheduled-meals {
      text-align: center;
    }
  }

  .weekly-menu-content.desktop {
    padding: 0px;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100vw;
    position: relative;
    flex-wrap: wrap;
    border-left: 1px solid black;
    .date-row {
      width: 100%;
      height: 60px;
      display: flex;
      align-items: flex-start;
      position: sticky;
      top: 187px;
      background-color: white;
      z-index: 2;
      border-bottom: 1px solid black;
      box-sizing: border-box;
      flex-wrap: nowrap;
      .day-menu-date {
        @extend .text-title;
        border-right: 1px solid black;
        width: calc(100% / 7);
        box-sizing: border-box;
        flex-shrink: 0;
        line-height: 60px;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .meal-time-row {
      width: 100%;
      @extend .flex-by-row-centered;
      align-items: flex-start;
      justify-content: stretch;
      align-content: stretch;
      flex-wrap: nowrap;
      .day-meal-time-menu {
        // width: calc((100% - 20px * 2 - 10px * 6) / 7);
        // min-width: calc(14% - 10px);
        width: calc(100% / 7);
        @extend .flex-by-column-top;
        // position: relative;
        // width: 100%;
        // width: 400px;
        // max-width: 100%;
        // margin-bottom: 20px;
        // background-color: var(--background-beige);
        // @extend .flex-by-row-top;
        // margin: 5px;
        border-right: 1px solid black;
        // @extend .card;
        align-self: stretch;
        // flex-grow: 1;
        // width: 100px;
        flex-shrink: 0;
        box-sizing: border-box;
        .menu-meals {
          margin-bottom: 0px;
          // box-shadow: 0px 0px 4px 2px rgba(28, 29, 33, 0.3);
          // padding: 0px 5px;
          width: 100%;
          padding: 5px 20px 25px 20px;
          .view-meal {
            // display: none;
            margin-bottom: 10px;
            height: 80px;
            @extend .flex-by-column-centered;
            color: white;
            align-items: flex-start;
            .meal-type {
              // font-size: 24px;
              display: inline-block;
              width: 100%;
              margin-bottom: 5px;
              @extend .text-11-300;
              text-transform: uppercase;
              font-size: 22px;
              letter-spacing: 1.4px;
              font-weight: 400;
              line-height: 24px;
              // margin-bottom: 5px;
            }
            .delivery-window.desktop {
              display: block;
              text-transform: capitalize;
              @extend .text-title;
              font-size: 10px;
              line-height: 12px;
              font-weight: 300;
            }
            .delivery-window.mobile {
              display: none;
            }
            .meal-type::before,
            .meal-type::after {
              display: none;
            }
          }
          .meal-list {
            width: 100%;
            // height: 320px;
            height: auto;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            // justify-content: stretch;
            // align-items: stretch;
            // overflow: hidden;
            .meal-menu-container.tbd {
              display: none;
              // height: 140px;
              height: auto;
              text-align: center;
              padding: 0px;
              line-height: var(--meal-height);
              // flex-shrink: 1;
              flex: 1;
            }
            .meal-menu-container {
              height: var(--meal-height);
              // height: auto;
              margin-bottom: 20px;
              // margin-bottom: 0px;
              box-sizing: border-box;
              position: relative;
              overflow: visible;
              // flex-shrink: 1;
              // flex-grow: 1;
              // align-self: stretch;
              // flex: 1;
              // max-height: 140px;

              background-color: white;
              box-shadow: 0 0px 4px 4px rgba(28, 29, 33, 0.4);
              // padding: 10px 10px 0px 10px;
              @extend .card;
              .meal-menu {
                z-index: 1;
                flex-direction: column;
                width: 100%;
                position: relative;
                padding-left: 0px;
                // height: 140px;

                .overlay {
                  // top: 40px;
                  width: 50%;
                  max-width: 50%;
                  min-width: 50%;
                  height: var(--meal-height);
                  background-color: rgba(0, 0, 0, 0.1);
                  // display: none;
                }
                img.hero-dish-image {
                  width: 50%;
                  max-width: 50%;
                  min-width: 50%;
                  height: var(--meal-height);
                  object-fit: cover;
                  // height: 100%;
                  position: absolute;
                  left: 0px;
                }
                div.hero-dish-image {
                  width: 100%;
                  // height: 80px;
                  background-color: var(--background-grey);
                }
                .meal-text {
                  // border: 1px solid var(--border-grey);
                  height: var(--meal-height);
                  position: absolute;
                  right: 0px;
                  top: 0px;
                  width: 50%;
                  // z-index: 1;
                  padding: 20px;
                  // justify-content: space-between;
                  overflow: hidden;
                  @extend .flex-by-column-centered;
                  // justify-content: space-between;
                  background-color: white;
                  padding-top: 49px;
                  .capacity-left {
                    top: 10px;
                    left: 10px;
                    height: 25px;
                    width: 100px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    span {
                      margin: 0px 3px;
                      font-size: 11px;
                    }
                  }
                  .late-cutoff {
                    // top: 10px;
                    // left: 10px;
                    height: 25px;
                    // width: 150px;
                    // display: flex;
                    // justify-content: center;
                    // align-items: center;
                    padding: 3px 6px;
                    flex-wrap: wrap;
                    span {
                      margin: 0px 3px;
                      font-size: 11px;
                      svg {
                        margin-right: 5px;
                      }
                    }
                  }
                  .last-call.desktop {
                    display: block;
                    border: none;
                    position: absolute;
                    bottom: 10px;
                    left: 0px;
                    width: 100%;
                    text-align: center;
                    span {
                      display: inline-block;
                      @extend .text-12-300;
                      text-transform: capitalize;
                      font-size: 8px;
                      line-height: 15px;
                      border-bottom: 1px solid #666;
                    }
                  }

                  .last-call.mobile {
                    display: none;
                  }
                }
                .meal-menu-order {
                  left: calc(50% - 25px);
                  // left: auto;
                  // right: -20px;
                  top: -12px;
                  z-index: 1;
                  width: 50px;
                  height: 50px;
                  font-size: 8px;
                  line-height: 1;
                  padding-bottom: 8px;
                  box-sizing: border-box;
                  justify-content: flex-start;
                  svg {
                    font-size: 18px;
                    margin-bottom: 4px;
                  }
                }
              }
            }

            // .meal-menu-container:last-child {
            //   margin-bottom: 10px;
            // }
            .meal-menu-container.tbd {
              height: 260px;
            }
            // .meal-menu-container.lunch {
            //   margin-bottom: 10px;
            // }
            // .meal-menu-container.lunch::after {
            //   content: '';
            //   position: absolute;
            //   display: block;
            //   width: 100%;
            //   height: 100%;
            //   right: -10px;
            //   bottom: -10px;
            //   background-color: var(--lunch-color);
            //   z-index: 0;
            // }
          }
        }

        .menu-meals.closed {
          opacity: 0.5;
          box-shadow: none;
          // filter: saturate(0);
        }
      }
      .day-meal-time-menu.lunch {
        background-color: var(--lunch-color);
        .menu-meals {
          .view-meal {
            color: black;
          }
        }
      }
      .day-meal-time-menu.dinner {
        background-color: var(--dinner-color);
      }
      .day-meal-time-menu.closed {
        filter: saturate(0);
      }
      .day-meal-time-menu.lunch.closed {
        background-color: var(--lunch-closed-color);
      }
      .day-meal-time-menu.dinner.closed {
        background-color: var(--dinner-closed-color);
      }

      .day-menu:nth-child(7n) {
        margin-right: 0px;
      }
    }
    .meal-time-row {
      border-bottom: 1px solid black;
    }
  }
}
.weekly-menu.anonymous {
  margin-top: 0px;
}
.weekly-menu.freeze {
  overflow: hidden;
}
.date-navbar {
  width: 100%;
  @extend .flex-by-row-space-around;
  position: sticky;
  // background-color: white;
  background-color: var(--background-beige);
  // @extend .card;
  // box-shadow: 0 4px 16px 0px rgba(28, 29, 33, 0.5);
  // padding: 15px 20px;
  z-index: 102;
  // max-width: 500px;
  // margin-top: 20px;
  // border-top: 1px solid black;
  // border: 1px solid var(--border-grey);

  border-bottom: 1px solid var(--border-grey);
  height: calc(var(--nav-height) + 1px);
  position: sticky;
  top: 0px;
  margin-top: -1px;
  .date-button {
    @extend .flex-by-column-centered;
    @extend .text-12-300;
    padding: 0px;
    margin-right: 10px;
    .day-of-week {
      font-size: 11px;
      // color: var(--text-grey);
      line-height: 1.5;
      font-weight: 500;
      margin-bottom: 0px;
    }
    .day-of-month {
      @extend .flex-by-row-left;
      position: relative;
      .date {
        font-size: 13px;
        font-weight: 500;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        display: inline-block;
        border-radius: 50%;
      }
      .orders-of-day {
        height: 35px;
        width: 5px;
        position: absolute;
        right: -8px;
        @extend .flex-by-column-top;
        padding: 3px 0px;
        .one-order {
          width: 4px;
          height: 4px;
          margin: 2px 0px;
          border-radius: 50%;
          background-color: green;

          svg {
            // font-size: 8px;
            // color: green;
            // height: 8px;
            display: none;
          }
        }
      }
    }
  }
  .date-button.selected {
    .day-of-month {
      .date {
        background-color: var(--highlight-blue);
        color: white;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }
  .date-button:last-child {
    margin-right: 0px;
  }
  @media only screen and (min-width: 800px) {
    padding: 0px calc(1vw * var(--desktop-left-padding) + 90px);
  }
}
.date-navbar.show-user-menu {
  z-index: 99;
}
.date-navbar.guest-nav {
  top: var(--nav-height);
}
// @media only screen and (min-width: 800px) {
.weekly-menu {
  padding-top: 0px;
  & > .announcement {
    left: auto;
    right: auto;
    // display: none;
  }
  .weekly-menu-content {
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: flex-start;
    // padding: 20px;
    // padding: 20px 0 0 0;
    // padding-top: calc(var(--announcement-height-short) + 20px);
    // width: auto;
    // box-sizing: border-box;
    max-width: 100%;
    .announcement.double-line,
    .day-menu {
      &.hidden-for-mobile {
        display: none;
      }
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      // border: 1px solid var(--border-grey);
      // box-shadow: 0 4px 16px 4px rgba(28, 29, 33, 0.2);
      // margin: 10px;
      // box-sizing: border-box;
      // width: 350px;
      padding: var(--meal-padding);
      padding-top: 0px;
      // margin: 0px;
      .date-wrapper {
        flex-direction: row;
        .meal-date {
          position: relative;
          left: 0px;
          top: 0px;
        }
        margin-bottom: 10px;
      }
      .menu-content {
        // width: auto;
        // flex-direction: column;
        justify-content: center;
        margin: 0px;
        align-self: flex-start;
        position: relative;
        .menu-meals {
          .meal-menu-container {
            // width: 100%;
            // box-shadow: 0 4px 16px 4px rgba(28, 29, 33, 0.2);
            .meal-type {
              left: 0px;
              top: 0px;
              width: 100%;
            }
            .meal-menu {
              // align-items: center;
              // height: 140px;
              position: relative;
              img.hero-dish-image {
                width: 100%;
                // height: 120px;
                object-fit: cover;
              }

              div.hero-dish-image {
                width: 100%;
                // height: 120px;
                background-color: var(--background-grey);
              }
              .meal-text {
                // width: 50%;
                // position: absolute;
                // right: 0px;
                // top: 0px;
                box-sizing: border-box;
                // align-self: center;
                // height: 70px;
                // padding-bottom: 45px;
                justify-content: flex-start;
                .restaurant-name {
                  // margin-top: 18px;
                  // margin-bottom: 0px;
                  // height: 40px;
                  // line-height: 20px;
                  display: flex;
                  // flex-grow: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: normal;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 2;
                  // line-height: 18px;
                  // height: 36px;
                  // overflow: hidden;
                  // white-space: nowrap;
                  // text-overflow: ellipsis;
                }
                .view-meal {
                  margin-bottom: 20px;
                }
              }
            }
          }
          .meal-menu-container.tbd {
            height: var(--meal-height);
            @extend .flex-by-row-centered;
            background-color: #dfdbe5;
            // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%239C92AC' fill-opacity='0.4'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
            // padding: 20px 40px;
            span {
              display: inline-block;
              width: 100%;
              line-height: var(--meal-height);
              // @extend .text-title;
              // font-size: 20px;

              // text-transform: capitalize;
              // width: 100%;
              text-align: center;
              // font-size: 18px;
              // line-height: 1.6;
              @extend .text-12-300;
              font-weight: 500;
              // letter-spacing: 1px;
              // font-size: 11px;
              letter-spacing: 2.4px;
              text-transform: uppercase;
            }
          }
          .meal-menu-container.tbd.lunch {
            background-color: var(--lunch-color);
          }
          .meal-menu-container.tbd.dinner {
            background-color: var(--dinner-color);
          }
          // .meal-menu-container.lunch {
          //   margin-bottom: 10px;
          // }
          .meal-menu-container.closed-container {
            // .meal-type {
            //   opacity: 0.4;
            // }
            // .hero-dish-image {
            //   opacity: 0.4;
            // }
            // .meal-text {
            //   opacity: 0.4;
            // }
            // .container {
            //   opacity: 0.4;
            // }
          }
        }
        .menu-meals.empty {
          margin-bottom: 0px;
        }
      }
    }
    // .day-menu:first-child {
    //   margin-top: 10px;
    //   .date-wrapper {
    //     .date-key {
    //       display: inline-block;
    //       text-align: center;
    //       width: auto;
    //       position: absolute;
    //       top: 5px;
    //       left: 60px;
    //       padding: 0px 20px;
    //       height: 40px;
    //       line-height: 40px;
    //       transform: none;
    //       background-color: transparent;
    //       box-shadow: none;
    //       font-size: 15px;
    //       border: none;
    //     }
    //   }
    // }
    .announcement.double-line {
      display: flex;
      min-height: 280px;
      align-self: stretch;
    }
  }
  .weekly-menu-content.desktop {
    display: none;
  }
}
// }
// @media only screen and (min-width: 800px) {
//   .weekly-menu {
//     & > .announcement {
//       left: auto;
//       right: 15px;
//       top: 71px;
//       box-shadow: none;
//     }
//   }
// }
@media only screen and (min-width: 500px) {
  // .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-note {
  //   // width: 100%;
  //   left: calc(var(--meal-height) * var(--meal-width-height-ratio) - 25px);
  //   // left: 10px;
  //   // top: 15px;
  //   // bottom: auto;
  //   // bottom: 85px;
  //   // height: 30px;
  //   padding: 0px;
  // }
  .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-text {
    // padding-right: 40px;
  }
}
@media only screen and (min-width: 1200px) {
  .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-menu-order {
    padding: 10px 0px 10px 0px;
    label {
      line-height: 1.1;
    }
  }
  .weekly-menu .meal-list .meal-menu-container .meal-menu {
    padding-left: 0px;
    padding-top: var(--meal-height);
    .overlay {
      // flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 8px 5px 0px 5px;

      .restaurant-labels {
        // justify-content: flex-start;
        padding: 0px 0px 5px 0px;
        // justify-content: center;
      }
      .which-meal {
        margin-top: 0px;
        margin-right: 5px;
        font-size: 11px;
        line-height: 24px;
        height: 26px;
        flex-shrink: 0;
        padding: 0px 7px;
      }
      .which-meal.cuisine-tag {
        // font-size: 10px;;
        margin-top: 5px;
        svg {
          font-size: 9px;
          margin-right: 2px;
        }
      }
    }
    .alert-container {
      @extend .flex-by-row-centered;
      gap: 5px;
      right: 5px;
      left: auto;
      top: 5px;
      // top: calc(var(--meal-height) - 50px);
      bottom: auto;
    }
    .meal-text {
      .restaurant-name {
        width: 100%;
        -webkit-line-clamp: 1;
      }
      .new-restaurant {
        top: 5px;
        // padding-left: 7px;
        // background-color: pink;
        // justify-content: flex-start;
      }
      .new-restaurant.multiple-logos {
        top: 5px;
      }
    }
    .meal-note {
      // right: 10px;
      top: calc(150px - var(--badge-height) / 2);
      left: -10px;
      bottom: 82px;
      // height: 30px;
      right: -10px;
      padding: 10px 0px;
      height: 65px;
      width: 65px;
    }
    // position: relative;
    // .header {
    //   top: 10px;
    //   font-size: 7px;
    // }
    // .markup-footer {
    //   bottom: 8px;
    //   font-size: 6px;
    // }
    // .highlight {
    //   font-size: 16px;
    // }
    // svg {
    //   position: absolute;
    // }
  }
  .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-note.mark-up {
    padding: 11px 0px 12px 0px;
  }
  .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-note.exclusive {
    // position: relative;
    // span {
    //   font-size: 10px;
    //   position: absolute;
    //   bottom: 6px;
    // }
    // svg {
    //   font-size: 20px;
    //   margin-bottom: 24px;
    // }
  }
  .weekly-menu.has-gift-card {
    padding: 45px 0px 0px 0px;
    position: relative;
  }
}
@media only screen and (min-width: 800px) {
  .weekly-menu {
    .weekly-menu-content {
      .current-day {
        margin-top: 20px;
      }
      .current-day,
      .upcoming-days {
        .day-menu {
          border: 1px solid var(--border-grey);
        }
      }
    }
  }
}
@media only screen and (min-width: 1200px) {
  .weekly-menu {
    align-items: flex-start;
    .weekly-menu-content {
      width: 100%;
      padding-top: 0px;
      overflow-x: visible;

      .current-day,
      .upcoming-days {
        .day-menu {
          border: none;
          .day-menu-date {
            justify-content: flex-start;
            padding: 0px 0px 0px 10px;
            .day-menu-date-wrapper {
              flex-direction: row;
              align-items: flex-start;
            }
            span {
              margin: 0px;
              position: relative;
              width: auto;
            }
            button {
              margin-left: 40px;
            }
          }
        }
      }

      .day-menu {
        width: 100%;
        box-shadow: none;
        background-color: transparent;
        border: none;
        margin: 0px;
        padding: 0px;
        border: 1px solid var(--border-grey);

        // curls {
        //   position: relative;
        //   line-height: 30px !important;
        // }

        .menu-content {
          // flex-direction: row;
          // justify-content: center;
          // align-self: stretch;
          // align-content: stretch;

          .menu-meals {
            // flex-grow: 1;
            // align-self: stretch;
            // align-content: stretch;
            // min-height: 100%;
            // flex-direction: row;

            justify-content: flex-start;
            width: auto;
            align-items: flex-start;
            // padding: 20px;
            // background-color: white;
            // border: 1px solid var(--border-grey);
            // box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
            margin: 20px 0px 0px 0px;

            .view-meal {
              // width: 300px;
              padding-left: 10px;
              .meal-type {
                width: 400px;
                // flex-grow: 0;
                padding-right: 10px;
                // margin-right: 10px;
                font-size: 22px;
                line-height: 30px;
                button {
                  position: relative;
                }
                // border-right: 2px solid black;
              }
              .meal-type::before,
              .meal-type::after {
                display: none;
              }
              flex-direction: row;
              justify-content: flex-start;
              margin: 0px;
              .delivery-window {
                font-size: 14px;
                // font-family: 'Theano Didot', serif;
                // opacity: 0.4;
                // opacity: 0.5;
                line-height: 30px;
                margin-bottom: 3px;
              }
            }
            .meal-list {
              flex-direction: row;
              justify-content: flex-start;
              margin-bottom: 20px;
              flex-wrap: wrap;
              .meal-menu-container {
                width: 230px;
                // height: 370px;

                padding: 0px;
                background-color: white;
                border: 1px solid var(--border-grey);
                box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
                margin: 10px 12px;
                // flex-grow: 1;
                align-self: stretch;
                // height: 100%;
                .meal-menu {
                  .meal-text {
                    padding: 30px 15px 80px 15px;
                    // height: calc(100% - var(--meal-height));
                    width: 100%;
                    height: 100%;
                    justify-content: flex-end;
                    .last-call {
                      position: absolute;
                      bottom: 20px;
                    }
                    align-self: stretch;
                    // justify-content: flex-start;
                  }
                  .meal-text.has-logo {
                    padding-top: 70px;
                  }
                  flex-direction: column;
                  height: 100%;
                  justify-content: flex-end;
                  align-self: stretch;
                  .overlay,
                  .hero-dish-image {
                    width: 100%;
                    min-width: 100%;
                    max-width: 100%;
                    // height: 100%;
                    height: var(--meal-height);
                  }
                  .capacity-left {
                    top: 8px;
                    left: 8px;
                    width: 100px;
                    padding: 3px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    span {
                      margin: 0px 3px;
                      font-size: 11px;
                    }
                  }
                  .late-cutoff {
                    top: 8px;
                    left: 8px;
                    width: 125px;
                    padding: 3px 6px;
                  }
                  .meal-menu-order {
                    top: calc(var(--meal-height) - var(--badge-height) - 5px);
                    // left: calc(50% - 35px);
                    // left: auto;
                    // right: 2px;
                    height: 65px;
                    width: 65px;
                    // left: 10px;
                    // bottom: 82px;
                    z-index: 1;
                  }
                  .meal-menu-order.ordered {
                    // bottom: 5px;
                    // right: calc(50% - var(--badge-height) / 2);
                    // right: -10px;
                    z-index: 2;
                    left: auto;
                    right: auto;
                  }
                  .meal-menu-order.order-history {
                    // bottom: 5px;
                    // right: calc(50% - var(--badge-height) / 2);
                    // background-color: red;
                    right: -10px;
                    // background-color: blue;
                  }
                  .restaurant-info {
                    margin-bottom: 0px;
                    .info-field {
                      span {
                        line-height: 1.5;
                      }
                    }
                    .info-field::after {
                      top: -13px;
                    }
                  }
                  .restaurant-info.line-2 {
                    // min-height: 19.5px;
                    // margin-bottom: 5px;
                  }
                  .yelp-info {
                    // bottom: 5px;
                    // left: -10px;
                    display: flex;
                    height: 32px;
                    justify-content: flex-start;
                    // margin: 5px 0px;
                  }
                  // .meal-text {
                  //   padding: 30px;
                  //   hei
                  // }
                }
              }
              .meal-menu-container.empty {
                box-shadow: none;
                margin-left: 0px;
              }
              // .meal-menu-container:first-child {
              //   margin-left: 0px;
              // }
              // .meal-menu-container:last-child {
              //   margin-right: 0px;
              // }
            }
          }
          .menu-meals:last-child {
            margin-top: 20px;
          }
        }
      }
      // .day-menu:first-child {
      //   margin-top: 20px;
      // }
      .current-day {
        margin-top: 0px;
        // width: 100vw;
        .day-menu {
          max-width: 100%;
          width: 100%;
          box-shadow: none;
          // background-color: var(--background-beige);
          // padding: 40px calc(10vw + 90px) 0px calc(10vw + 90px);
          padding: 40px 40px 40px calc(1vw * var(--desktop-left-padding) + 90px);
        }
      }
      .scheduled-meals {
        display: none;
      }
      .scheduled-meals.upvote,
      .scheduled-meals.nominate {
        display: flex;
        // flex-direction: row;
        // width: 430px;
        // max-width: 430px;
        width: auto;
        padding-left: 0px;
        align-items: flex-start;
        margin-bottom: 15px;
        svg {
          margin: 0 15px 0 0;
          font-size: 50px;
          display: none;
        }
        span.nominate-title {
          display: inline-block;
        }
        span.nominate-tagline {
          // font-family: "Theano Didot", serif;
          text-transform: none;
          font-size: 14px;
          font-weight: 300;
          line-height: 22px;
          letter-spacing: 1px;
          opacity: 0.5;
          width: auto;
        }
      }
      .nominate-container {
        padding-left: calc(1vw * var(--desktop-left-padding) + 90px + 15px);
        align-items: flex-start;
        margin-bottom: 30px;
        background-color: white;
        .nominate-form.in-flow {
          width: 430px;
          max-width: 430px;
          // button {
          //   width: 100px;
          // }
        }
      }
      .upcoming-days {
        width: 100vw;
        // background-color: var(--background-beige);
        .day-menu {
          align-self: stretch;
          max-width: 100%;
          padding: 40px calc(1vw * var(--desktop-left-padding) + 90px);
          padding-bottom: 20px;
        }
        .day-menu:first-child::before {
          padding-left: 10px;
          text-align: left;
          width: auto;
          margin-left: 10px;
          padding-left: 0px;
          border-bottom: 1px solid black;
        }

        .day-menu:nth-child(odd) {
          background-color: var(--background-beige);
        }
      }
      .upcoming-days.authenticated {
        background-color: white;
      }
    }
  }
  // .weekly-menu .meal-list .meal-menu-container .meal-menu .meal-text {
  //   padding: 20px;
  // }
}
@media only screen and (min-width: 5200px) {
  .weekly-menu {
    // padding-top: calc(var(--subnav-height) + var(--subnav-height-short));
    height: auto;
    & > .announcement {
      left: auto;
      right: 15px;
      top: 71px;
      box-shadow: none;
      width: auto;
    }
    .weekly-header {
      .weekly-menu-days {
        box-shadow: none;
        border-bottom: 1px solid black;
      }
    }

    .weekly-menu-content.mobile {
      display: none;
    }
    .weekly-menu-content.desktop {
      display: flex;
    }
  }
  .weekly-menu.has-gift-card {
    padding: 127px 0px 0px 0px;
  }
}

@media only screen and (min-width: 1200px) {
  .date-navbar {
    // top: 0px;
    // z-index: 100;
    box-shadow: none;

    // border: none;
    // margin-top: 20px;
    // max-width: 800px;
    // background-color: white;
    // position: sticky;
    // right: 10px;
    // margin-top: 20px;
    // top: calc(var(--nav-height) + 10px);
    // // flex-direction: column;
    // width: 400px;
    // border: 1px solid var(--border-grey);
  }
  .weekly-menu {
    // flex-direction: row-reverse;
    // justify-content: flex-start;
    // align-items: flex-start;
    .weekly-menu-content {
      .day-menu {
        .day-menu-date {
          flex-direction: row;
          span {
            font-size: 32px;
          }
          span:first-child {
            margin-right: 12px;
            margin-bottom: 0px;
          }
        }
        .day-menu-date.high-light {
          span:first-child {
            margin-bottom: 0px;
            margin-right: 16px;
            font-size: 32px;
            // margin-bottom: 0px;
          }
          span:first-child::after {
            content: ",";
            margin-right: 14px;
            position: absolute;
            // margin-bottom: 0px;
          }
        }
      }
      .day-menu:first-child {
        .day-menu-date {
          flex-direction: row;
          span:first-child {
            font-size: 34px;
            margin: 0px 16px 0px 0px;
            // text-transform: uppercase;
          }
          span:first-child::after {
            display: inline-block;
          }
        }
      }
    }
  }
}
// @media only screen and (min-width: 1500px) {
//   .weekly-menu {
//     .weekly-menu-content {
//       .day-menu {
//         margin: 0px;

//         .menu-content {
//           .meal-menu-container {
//             .meal-type {
//               width: 40%;
//               z-index: 1;
//             }
//             .meal-menu {
//               flex-direction: row;
//               width: 100%;
//               // align-self: stretch;
//               // flex-grow: 1;
//               .meal-text {
//                 width: 60%;
//                 padding: 5px;
//                 .restaurant-name {
//                   font-size: 12px;
//                   word-break: normal;
//                 }
//               }
//               img.hero-dish-image {
//                 width: 40%;
//                 min-width: 40%;

//                 height: 130px;
//                 object-fit: cover;
//               }
//               div.hero-dish-image {
//                 width: 40%;
//                 height: 130px;
//                 background-color: var(--background-grey);
//               }
//             }
//           }
//           .meal-menu-container.tbd {
//             height: 130px;
//           }
//         }
//       }
//     }
//   }
// }

.nominate-container {
  width: 100vw;
  @extend .flex-by-column-centered;
  // padding-top: calc(2 * var(--nav-height));
  .nominate-form.in-flow {
    @extend .card;
    width: 400px;
    max-width: calc(100vw - 40px);
    margin: 0px;
    border: 1px solid var(--border-grey);
    padding: 40px 20px;
    button {
      width: 100%;
      height: 45px;
      // margin-top: 15px;
    }
    .nominate-field {
      width: 100%;
      margin: 0px;
      input {
        background-color: var(--background-beige);
        padding-left: 10px;
      }
      input::placeholder {
        @extend .text-12-300;
        font-size: 13px;
        font-weight: 400;
      }
    }
  }
}

@keyframes shimmer {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f5f5f5 0%, #e0e0e0 50%, #f5f5f5 100%);
  background-size: 200% 100%;
  animation: shimmer 2s infinite;
}

@media only screen and (max-width: 350px) {
  // make which-meal smaller
  .which-meal {
    font-size: 10px !important;
    line-height: 20px !important;
    height: 20px !important;
    margin-top: 5px !important;
  }
}

@media screen and (max-height: 700px) {
  .date-navbar.guest-nav {
    top: calc(var(--nav-height-short) - 2px);
    height: var(--nav-height-short);
    .date-button {
      .day-of-month {
        .date {
          font-size: 10px;
          width: 25px;
          height: 25px;
          line-height: 25px;
        }
      }
      .day-of-week {
        font-size: 10px;
        line-height: 2;
      }
    }
  }
}

@media screen and (min-width: 800px) {
}
