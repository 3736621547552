@import './index.scss';
.menu-categories {
  width: 100vw;
  @extend .flex-by-column-centered;
  padding: 0px 20px 100px 20px;
  position: relative;
  .menu-categories-title {
    @extend .flex-by-column-centered;
    height: 50px;
    width: 100vw;
    margin-bottom: 20px;
    background-color: var(--background-beige);
    span {
      @extend .with-curls;
      @extend .text-12-300;
      font-weight: 500;
      text-transform: capitalize;
    }
  }
  .menu-category {
    width: 100%;
    @extend .flex-by-column-left;
    margin-bottom: 10px;
    @extend .card;
    .category-name {
      @extend .text-12-300;
      text-transform: uppercase;
      width: 100%;
      padding: 10px 20px;
      @extend .flex-by-row-between;
    }
    .category-dishes {
      padding: 10px 25px;
      @extend .flex-by-column-left;
      width: 100%;
      border-top: 1px solid rgb(217, 217, 217);
      .dish-name {
        @extend .text-12-300;
        text-transform: capitalize;
        width: 100%;
        @extend .flex-by-row-left;
        padding: 0px;
        height: 40px;
      }
      .dish-name.selected {
        font-weight: 500;
        svg {
          margin-left: 10px;
        }
      }
    }
  }
}
