@import './index.scss';
.restaurant-orders {
  width: 100vw;
  max-width: 100vw;
  padding-top: calc(2 * var(--announcement-height-short));
  @extend .flex-by-column-centered;
  .download-summary {
    @extend .action-black;
    margin: 20px 0px 0px 0px;
  }
  .meal-info {
    @extend .sub-nav-2;
    top: var(--nav-height);
    height: var(--announcement-height-short);
    @extend .text-12-400;
    background-color: var(--background-beige);
    b {
      margin-right: 10px;
    }
  }
  button.print-order {
    @extend .action-black;
    margin-top: 20px;
    width: calc(100% - 80px);
    svg {
      margin-right: 10px;
    }
    max-width: 400px;
  }
  .show-details-toggles {
    @extend .sub-nav-2;
    top: calc(var(--nav-height) + var(--announcement-height-short));
    height: var(--announcement-height-short);
    @extend .flex-by-row-even;
    padding: 0px 20px;
    box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
    button {
      width: auto;
      margin: 0px;
      color: var(--text-grey);
    }
    button.selected {
      font-weight: 700;
      color: black;
    }
  }

  .current-batch {
    @extend .flex-by-column-left;
    justify-content: flex-start;
    padding: 20px 20px 120px 20px;
    width: 100%;
    max-width: 1000px;
  }
  .current-batch.show-summary {
    // border: 1px solid black;
    width: calc(100% - 40px);
    margin: 20px 0px;
    @extend .card;
    .order:last-child {
      margin-bottom: 0px;
    }
    .order {
      .order-items {
        .item-wrapper {
          .cancel-item {
            display: none;
          }
        }
      }
    }
  }
  .current-batch.show-new,
  .current-batch.show-confirmed {
    .order-wrapper {
      position: relative;
      width: 100%;
      padding: 0px;
      .order {
        @extend .card;
        padding: 10px;
        @extend .flex-by-column-centered;
        .toggle-items {
          @extend .action-underline;
          border: none;
          width: auto;
          @extend .flex-by-row-between;
          margin-bottom: 20px;
          svg {
            margin-left: 20px;
          }
        }
      }
    }
  }

  .cancel-item {
    width: 100%;
    padding: 10px 0px;

    .cancel-item-toggle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }
  }
}
@media only screen and (min-width: 500px) {
  .restaurant-orders {
    .current-summary {
      padding: 50px;
    }
    .current-batch {
      padding: 50px;
      padding-bottom: 120px;
    }
    .current-batch.show-summary {
      margin: 40px 0px;
    }
  }
}
@media print {
  .order-wrapper {
    page-break-inside: avoid;
  }
}

.cancel-item-form {
  z-index: 100;
  padding: 20px;
  position: absolute;
  @extend .flex-by-column-centered;
  @extend .card;
  background-color: white;
  right: 10px;
  top: 30px;

  .cancel-item-quantity {
    justify-content: center;
    align-items: center;
    width: 100%;
    label {
      font-size: 13px;
      // padding: 3px 10px;
      text-transform: capitalize;
    }
    input {
      display: inline-block;
      @extend .text-12-300;
      border: 1px solid var(--background-grey);
      width: 100%;
      box-sizing: border-box;
      margin: 0px;
      text-align: center;
      padding: 3px 10px;
    }
  }
  button {
    @extend .action-black;
    margin-top: 10px;
    width: 100%;
  }
}
