@import './index.scss';

.hide-current-restaurants {
  @extend .flex-by-column-centered;
  flex-wrap: wrap;
  max-width: 100vw;
  padding-top: 30px;

  .current-restaurants {
    @extend .action-transparent;
    font-size: 14px;
    height: 40px;
    width: 400px;
    border-color: black;
    border-style: solid;
    border-width: 1px;
    color: black;

    .hide {
      display: none;
    }
  }

  .current-restaurants.filtered {
    @extend .action-black;
    background-color: black;
    color: white;
  }
}

.list.nomination-list {
  @extend .flex-by-row-left;
  flex-wrap: wrap;
  max-width: 100vw;

  .nomination {
    @extend .card;
    position: relative;
    margin: 10px 20px;
    padding: 20px;
    @extend .text-12-300;
    @extend .flex-by-column-top-left;
    align-self: stretch;
    width: 400px;
    padding-bottom: 80px;
    .delete-nomination {
      position: absolute;
      top: 20px;
      right: 20px;
      svg {
        font-size: 20px;
      }
    }
    .user {
      @extend .action-transparent;
      svg {
        margin-right: 10px;
        font-size: 15px;
      }
    }
    .user.filtered {
      @extend .action-black;
    }
    .restaurant {
      @extend .flex-by-column-left;
      margin-top: 20px;
      .restaurant-name {
        @extend .action-transparent;
        svg {
          font-size: 15px;
          margin-right: 10px;
        }
        margin-bottom: 20px;
      }
      .restaurant-name.filtered {
        @extend .action-black;
      }
    }
    .dishes {
      margin: 20px 0px;
      @extend .flex-by-column-left;
      padding: 0px;
      padding-left: 20px;
      .dish-name {
        @extend .text-12-300;

        list-style: disc;
      }
    }
    .time {
      position: absolute;
      bottom: 20px;
    }
  }
}
