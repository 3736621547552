@import "./index.scss";

.referral-code-entry {
  z-index: 3;
  // top: calc(var(--nav-height) + var(--subnav-height));
  border: none;
  //   max-width: 450px;
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
  @extend .flex-by-row-centered;
  left: 0px;
  // @extend .card;
  // box-shadow: 0px 4px 12px 2px rgba(28, 29, 33, 0.12);
  height: var(--subnav-height);
  position: fixed;
  background-color: #e8e02b;
  //   background-color: #ebebeb;
  margin: 0px;
  top: var(--nav-height);
  //   box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
  padding: 0px;
  span {
    @extend .text-title;
    font-size: 16px;
    // font-weight: 600;
    // font-family: 'Rock Salt';
    // letter-spacing: 2.5px;
    // padding-left: 10px;
    // font-weight: 400;
  }
  svg {
    margin-right: 12px;
    font-size: 27px;
    margin-bottom: 5px;
  }
}
.weekly-menu {
  .referral-code-entry {
    top: var(--nav-height);
  }
}
.referral-code-entry.has-error {
  box-shadow: none;
}
.list.lunch-cart,
.list.dinner-cart {
  .referral-code-entry {
    left: auto;
    right: auto;
  }
}

// @media only screen and (min-width: 500px) {
//   .list {
//     .referral-code-entry {
//       max-width: 500px;
//     }
//   }
// }
.referral-code-entry.ordering-with-code {
  svg {
    --fa-secondary-color: #f76b6b;
    --fa-primary-color: #aaa;
    --fa-secondary-opacity: 1;
  }
  span {
    // font-size: 7px;
    b {
      @extend .text-title;
      font-weight: 500;
      font-size: 14px;
    }
  }
}
.referral-code-entry.create-referral-code {
  .icon-wrapper {
    position: relative;
    svg.fa-heart-circle {
      --fa-secondary-opacity: 1;
      // --fa-primary-color: var(--background-coral);
      // --fa-secondary-color: var(--background-purple);
      --fa-primary-color: #f76b6b;
      // --fa-primary-color: #e8e02b;
      --fa-secondary-color: white;
      position: absolute;
      left: 7px;
      top: 4px;
      font-size: 20px;
    }
    svg.fa-envelope-open-text {
      // color: var(--background-coral);
      --fa-secondary-opacity: 1;
      // --fa-primary-color: #e5d6d3;
      --fa-primary-color: #ccc;
      --fa-secondary-color: white;
      font-size: 34px;
      // margin-bottom: 20px;
    }
  }
}
@media only screen and (min-width: 800px) {
  // .referral-code-entry {
  //   left: auto;

  //   right: 12px;
  //   // right: auto;
  //   width: auto;
  //   // width: auto;
  //   padding: 0px 10px;
  // }

  .referral-code-entry.create-referral-code,
  .referral-code-entry.ordering-with-code {
    z-index: 9;
    width: 450px;
    padding: 0px 20px;
    right: 12px;
    left: auto;
    width: 450px;
    top: 10px;
    position: absolute;
  }
  .weekly-menu {
    position: relative;
    .referral-code-entry {
      width: 100%;
      top: var(--nav-height);
      left: 0px;
    }
  }
}
@media only screen and (min-width: 1200px) {
  .weekly-menu {
    .referral-code-entry.ordering-with-code,
    .referral-code-entry.create-referral-code {
      position: fixed;
      top: calc(var(--nav-height) + 12px);
      z-index: 9;
      right: 12px;
      left: auto;
      width: 400px;
    }
  }
}
