@import './index.scss';
// .checkout-form {
//   width: 100%;
//   // padding: 20px 0px;
//   @extend .flex-by-column-centered;
//   .receipt {
//     padding: 0px;
//     box-shadow: none;
//     margin: 10px 0px 10px 0px;
//     max-width: 500px;
//   }
.form-section.budget {
  .form-row {
    margin-bottom: 20px;
  }
  .form-field-wrapper {
    padding: 10px 0;
  }
  margin-bottom: 30px;
}
.list {
  .checkout {
    @extend .flex-by-column-top;
    width: 100%;
    // min-height: calc(100vh - var(--nav-height));
    position: relative;
    z-index: 0;
    // @extend .card;
    // .checkout-total {
    //   margin-bottom: 20px;
    // }
    .form-section {
      width: calc(100% - 40px);
      input[name='phone'] {
        letter-spacing: 1.5px;
      }
      margin-bottom: 30px;
      .form-row {
        position: relative;
        .card-icon {
          position: absolute;
          right: 6px;
          top: 6px;
          font-size: 24px;
        }
        button.save-card {
          padding: 0px;
          @extend .flex-by-row-left;
          // span {
          //   font-weight: 500;
          // }
          svg {
            border: 1px solid black;
            margin: 0px 10px 0px 0px;
            border-radius: 3px;
            padding: 2px;
            width: 17px;
            height: 17px;
            box-sizing: border-box;
          }
        }
        button.save-card.unchecked {
          svg {
            color: transparent;
          }
        }
      }
      .form-row:last-child {
        margin-bottom: 0px;
      }
    }
    // .form-section:first-child {
    //   margin-top: 20px;
    // }
  }
}
// }

.checkout-auth {
  .checkout-auth-body {
    @extend .flex-by-column-centered;
    min-height: calc(100vh - var(--nav-height));
    width: 100%;
  }
}
.no-card {
  @extend .text-12-300;
  font-size: 16px;
  letter-spacing: 0.74px;
  font-stretch: 100%;
  font-weight: 400;
  color: rgb(117, 117, 117);
}
.setting-action {
  @extend .action-underline;
  width: fit-content;
  padding: 0px;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 29px;
  font-weight: 400;
  letter-spacing: 0.74px;
  svg {
    margin-right: 10px;
  }
}
.setting-action.cancel-new-card {
  margin-top: 30px;
}
.newsletter {
  label {
    @extend .text-12-300;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    height: 24px;
    letter-spacing: 0.74px;
    text-transform: capitalize;
    // @extend .flex-by-row-left;
    input[type='checkbox'] {
      margin: 0px 18px 0px 0px;
      height: 20px;
      position: relative;
    }
    input[type='checkbox']:before {
      position: absolute;
      top: 3px;
      width: 21px;
      height: 21px;
      display: block;
      border: 1px solid black;
      border-radius: 2px;
      box-sizing: border-box;
      content: '';
      background: #fff;
      margin: 0px;
    }
    input[type='checkbox']:checked:before {
      position: absolute;
      display: block;
      width: 21px;
      height: 21px;
      padding-left: 3px;
      padding-bottom: 3px;
      box-sizing: border-box;
      content: '\2714';
      font-size: 15px;
      background: white;
    }
  }
}
@media only screen and (min-width: 500px) {
  .no-card {
    font-size: 12px;
  }
  .setting-action {
    font-size: 12px;
  }
  .newsletter {
    label {
      font-size: 12px;
    }
  }
}

.user {
  @extend .card;
  @extend .flex-by-column-left;
  justify-content: flex-start;
  align-self: stretch;
  margin: 10px 0px;
  // width: calc(100% - 40px);
  padding: 20px;
  border-radius: 10px;
  .user-field {
    @extend .text-13-400;
    padding-left: 26px;
    position: relative;
    margin-bottom: 10px;
    svg {
      left: 0px;
      top: 0px;
      height: 26px;
      position: absolute;
    }
  }
  .user-field:last-child {
    margin-bottom: 0px;
  }

  textarea {
    background: #f5f5f5;
    border: none;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    @extend .text-12-400;
    text-transform: none;
    letter-spacing: 0.74px;
  }
  textarea::placeholder {
    text-transform: none;
  }
  .error-message {
    margin: 10px 0px;
  }
  .error-message.no-error {
    opacity: 0;
  }
  button,
  a {
    text-transform: capitalize;
    border: 1px solid black;
    box-sizing: border-box;
    @extend .action-black;
    width: 100%;
    margin: auto;
    font-size: 12px;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
.user:first-child {
  margin-top: 0px;
}
.user.pending {
  .name {
    color: var(--background-coral);
  }
}
@media only screen and (min-width: 800px) {
  .page {
    .list {
      max-width: 100%;
      // padding: 0px;
      .list-body.authorized-users {
        .form-section {
          max-width: 100%;
          width: 100%;
          margin: 0px;
          padding: 10px;
          .setting-section {
            width: 100%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .user {
              width: 250px;
              margin: 10px;
            }
          }
          .user.invite {
            align-self: stretch;
            flex-grow: 1;
            margin: 10px;
          }
        }
      }
    }
  }
}
