@import "./index.scss";
.user-list {
  @extend .flex-by-column-centered;
  padding: 20px;
  margin-bottom: 100px;
  .user-count {
    @extend .text-14-300;
    @extend .flex-by-row-centered;
    padding: 20px 0px;
  }
  .create-group {
    @extend .card;
    @extend .flex-by-column-centered;
    align-self: stretch;
    padding: 20px;
    input {
      align-self: stretch;
      margin: 20px 0px;
    }
    button {
      @extend .action-black;
    }
  }
  .list-title {
    @extend .card;
  }
  .users {
    width: 100%;
    @extend .flex-by-column-centered;
    .user {
      @extend .card;
      @extend .flex-by-column-left;
      justify-content: flex-start;
      @extend .text-12-300;
      font-size: 15px;
      // align-self: stretch;
      margin: 10px 0px;
      // width: calc(100% - 40px);
      width: 100%;
      // padding: 20px;
      border-radius: 10px;
      position: relative;
      padding-bottom: 60px;
      span {
        // background-color: red;
      }
      .user-section {
        @extend .flex-by-column-left;
        border-bottom: 1px dashed var(--text-grey);
        padding: 10px;
        width: 100%;
        &:last-of-type {
          border-bottom: none;
          margin-bottom: 15px;
        }
        &:nth-child(odd) {
          background-color: var(--background-beige);
        }
        &:nth-child(even) {
          background-color: white;
        }
      }
      .user-field {
        // @extend .text-16-400;
        margin-bottom: 0px;
        position: relative;
        padding: 0px;
        width: 100%;
        @extend .flex-by-row-left;
        justify-content: space-between;
        .user-subfield {
          text-transform: capitalize;
          .off {
            font-weight: 500;
            background-color: red !important;
            color: white;
            padding: 0px 3px;
          }
          .on {
            font-weight: 500;
            background-color: green !important;
            color: white;
            padding: 0px 3px;
          }
        }
        &.credit {
          input {
            width: 60px;
            padding: 4px;
            margin: 0 6px 0 0;
          }
          button {
            height: 30px;
            // max-height: 40px;
          }
        }
        label {
          text-transform: capitalize;
        }
      }
      .user-field.message {
        @extend .flex-by-column-left;
        width: 100%;
        margin-top: 10px;
        label {
          font-weight: 500;
        }
        textarea {
          margin-bottom: 10px;
          background: var(--background-beige);
          padding: 2px 5px;
          text-transform: capitalize;
        }
        button {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
      .user-field.membership {
        @extend .flex-by-column-left;
        label {
          margin-bottom: 5px;
          background: var(--border-grey);
          padding: 2px 5px;
          border-radius: 5px;
          text-transform: capitalize;
        }
        span {
          text-transform: capitalize;
          font-weight: 500;
        }
      }
      button.delegate-user,
      a.view-stats {
        height: 40px;
        max-height: 40px;
        position: absolute;
        bottom: 20px;
        width: 100px;
        background-color: transparent;
        color: black;
        svg {
          margin-right: 10px;
        }
      }
      button.delegate-user {
        left: 20px;
      }
      a.view-stats {
        right: 20px;
      }
      .user-field:last-child {
        margin-bottom: 0px;
      }
    }
    // .user.apt {
    //   background-color: pink;
    // }
    .user.active {
      background-color: gold;
    }
    .user.trialing {
      background-color: var(--background-coral);
    }
    .user.canceled {
      background-color: var(--background-brown);
    }
  }
}
.user-list-by-gmv {
  @extend .flex-by-column-left;
  padding: 20px;
  width: 100%;
  .user {
    max-width: 400px;
    padding: 10px 20px;
    .name,
    .average,
    .orders,
    .joined,
    .address,
    .spend {
      width: 100%;
      @extend .flex-by-row-between;
      @extend .text-12-300;
      align-items: flex-start;
      margin-bottom: 5px;
      text-transform: capitalize;
      label {
        display: inline-block;
        line-height: 1.5;
      }
      span {
        font-weight: 500;
        text-align: right;
        line-height: 1.5;
        display: inline-block;
        svg {
          margin-left: 5px;
          color: goldenrod;
        }
      }
    }
    .ranked {
      span {
        color: red;
      }
    }
  }
}

.leader-board-console {
  position: fixed;
  height: var(--nav-height);
  width: 100%;
  top: 0px;
  left: 0px;
  padding: 0px 40px 0px 60px;
  @extend .flex-by-column-centered;
  // justify-content: space-around;
  z-index: 1;
  @extend .card;
  .period-console {
    margin-bottom: 10px;
  }
  .period-console,
  .rank-console {
    width: 100%;
    @extend .flex-by-row-between;
    button {
      padding: 0px;
      opacity: 0.3;
    }
    button.selected {
      opacity: 1;
      border-bottom: 1px solid black;
    }
  }
}

@media only screen and (min-width: 500px) {
  .user-list {
    max-width: 100vw;
    width: 100vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-content: stretch;
    .users {
      width: 100vw;
      flex-direction: row;
      justify-content: left;
      align-items: center;
      flex-wrap: wrap;
      .user {
        width: 300px;
        margin: 10px;
        // height: 250px;
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .user-list-by-gmv {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    .user {
      margin: 10px;
      width: 250px;
    }
  }
  .leader-board-console {
    padding: 0px calc(10% + 90px);
    .rank-console,
    .period-console {
      justify-content: center;
      button {
        margin-right: 20px;
      }
    }
  }
}
