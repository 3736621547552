@import '../../../index.scss';
.tabs {
  @extend .flex-by-row-centered;
  @extend .page-title;
  .tab {
    @extend .flex-by-row-centered;
    width: 100px;
    margin: 0px 10px;
    span {
      color: grey;
      font-weight: 300;
      font-family: 'Theano Didot', serif;
      line-height: 1.4;
      letter-spacing: 1.6px;
      font-size: 14px;
      line-height: var(--title-height);
      height: var(--title-height);
      padding: 0px;
      position: relative;
      box-sizing: content-box;
      font-family: 'Theano Didot', serif;
    }
  }
  .tab.selected {
    span {
      color: black;
      border-bottom: 1px solid black;
    }
  }
  .tab:first-child {
    @extend .flex-by-row-right;
    span {
      text-align: right;
    }
  }
  .tab:last-child {
    @extend .flex-by-row-left;
    span {
      text-align: left;
    }
  }
}
.tabs.catelog {
  // @extend .flex-by-row-left;
  display: block;
  width: 100vw;
  overflow-x: scroll;
  white-space: nowrap;
  padding: 0px 50px;
  box-sizing: border-box;
  z-index: 2;
  .tab {
    display: inline-block;
    width: auto;
    height: var(--title-height);
    position: relative;
    margin: 0px 20px;
    span {
      display: inline-block;
      font-family: 'Montserrat';
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.8px;
      line-height: calc(var(--title-height) - 5px);
      height: calc(var(--title-height) - 5px);
    }
  }

  .tab:before {
    content: '';
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: black;
    opacity: 0.25;
    transform: rotate(45deg);
    position: absolute;
    left: -20px;
    top: calc(var(--title-height) / 2 - 1px);
  }
  .tab:first-child:before {
    display: none;
  }
}
@media only screen and (min-width: 500px) {
  .tabs {
    .tab {
      margin: 0px 30px;
    }
  }
}
