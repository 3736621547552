@import "./index.scss";
.receipt.goods {
  padding: 20px;
  width: 100%;
  margin-top: 0px !important;
  .receipt-by-user {
    width: 100%;
    margin-bottom: 25px;
    background-color: var(--background-beige);
    padding: 40px 15px 15px 15px;
    position: relative;
    .receipt-user-header {
      width: 100%;
      position: absolute;
      left: 0px;
      top: -10px;
      @extend .flex-by-row-between;
      height: 40px;
      background-color: white;
      .receipt-user-name {
        text-transform: uppercase;
        // margin-bottom: 10px;
        font-size: 13px;
        font-weight: 500;
        // line-height: 40px;
        // background-color: red;
        @extend .overflow-ellipsis;
        svg {
          margin-right: 5px;
          font-size: 12px;
        }
      }
      .user-role {
        @extend .flex-by-row-right;
        span {
          @extend .text-12-300;
          text-transform: capitalize;
          font-weight: 400;
          background-color: black;
          color: white;
          line-height: 25px;
          padding: 0px 10px;
          border-radius: 3px;
          margin-left: 10px;
        }
        .organizer {
          background-color: var(--background-peach);
          color: black;
        }
      }
    }

    .receipt-section {
      margin-bottom: 0px;
      padding: 20px 0px;
      border-bottom: 1px solid var(--border-grey);
      .receipt-row {
        padding-left: 0px;
      }
      &:nth-child(2) {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
      }
    }
  }
  .receipt-section {
    @extend .flex-by-column;
    // align-items: flex-end;
    .receipt-row.cancelled-quantity {
      color: red;
      font-weight: 500;
    }
    .receipt-row.selected {
      font-weight: 500;
    }
    .receipt-row.restaurant-name {
      // @extend .flex-by-column-centered;
      span {
        // font-family: "Theano Didot", serif;
        text-transform: uppercase;
        // font-weight: 300;
        // font-size: 13px;
        // letter-spacing: 1px;
      }
      margin-bottom: 10px;
    }
    .receipt-row.edit-dish {
      margin-top: 20px;
      @extend .flex-by-row-space-around;
      button {
        padding: 0px;
        @extend .action-underline;
        margin-right: 20px;
      }
      button:last-child {
        margin-right: 0px;
      }
    }
    .receipt-row.comment {
      @extend .flex-by-column-left;
      line-height: 20px;
      margin-top: 3px;
      span {
        text-align: left;
        text-transform: none;
      }
    }
    .receipt-row.comment.editting {
      label {
        margin-bottom: 8px;
      }
      textarea {
        padding: 5px 10px;
        width: 100%;
        @extend .text-12-300;
        box-sizing: border-box;
        font-size: 16px;
      }
      textarea::placeholder {
        @extend .text-12-300;
        font-size: 16px;
        text-transform: lowercase;
      }
    }
    .receipt-row.prices {
      width: 100%;
      @extend .flex-by-row;
      justify-content: space-between;
      align-items: flex-start;
      margin: 5px 0px;
      label {
        line-height: 1;
      }
    }
    .receipt-row.dish-name {
      // padding-right: 60px;
      position: relative;
      margin-bottom: 10px;
      min-height: 50px;
      @extend .flex-by-column;
      .dish-image {
        // width: 100%;
        // height: 150px;
        height: 50px;
        width: 50px;
        object-fit: cover;
        position: absolute;
        left: auto;
        right: 0px;
        top: 0px;
        margin-bottom: 10px;
      }
      .dish-image.hidden {
        display: none;
      }
      > span {
        width: 100%;
        text-align: left;
        @extend .text-12-300;
        font-weight: 500;
        line-height: 1.5;
      }
    }
    .receipt-row {
      .dish-quantity {
        // width: 50px;
        @extend .flex-by-row-centered;
        button {
          padding: 0px;
          width: 25px;
          height: 20px;
          svg {
            margin: 0px;
          }
        }
        button[disabled] {
          color: var(--border-grey);
        }
        button:first-child {
          margin-right: 0px;
          @extend .flex-by-row-left;
        }
        button:last-child {
          margin-left: 0px;
          @extend .flex-by-row-right;
        }
      }
      .dish-quantity.ops-view-highlight {
        font-weight: 600;
        font-size: 18px;
        color: red;
      }
    }
    .selection-items {
      margin: 10px 0px;
      width: 100%;
      font-size: 11px;
      border-top: 1px dashed grey;
      border-bottom: 1px dashed grey;
      padding: 20px 0px 10px 0px;
      label {
        line-height: 1;
        height: 11px;
      }
      .item-details {
        @extend .flex-by-row-between;
        align-items: flex-start;
        margin-bottom: 15px;
      }
      .item-details:last-child {
        margin-bottom: 0px;
      }
      .comment {
        text-transform: initial;
      }
    }
    .selection-edit {
      margin-top: 12px;
      margin-left: 0;
      padding-left: 0;
      text-decoration: underline;
    }
  }
  .receipt-section.goods {
    padding: 0px;
    background-color: white;
  }
}
