@import "../../index.scss";
.loading {
  @extend .flex-by-row-centered;
  width: 100%;
  height: calc(100vh - var(--nav-height));
  margin: 32px 0px;
}
.loading-auth {
  @extend .flex-by-row-centered;
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  color: white;
  z-index: 3;
  margin-top: 60px;
  height: 140px;
  @media only screen and (min-width: 800px) {
    flex-direction: row;
    margin-bottom: 120px;
    margin-top: 30px;
  }
}
.loading-top {
  @extend .flex-by-row-centered;
  width: 100%;
  height: 200px;
  margin-top: var(--nav-height);
}
.loading-top.transparent {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.loading-full {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1;
  font-size: 20px;
  width: 100vw;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.2);
  @extend .flex-by-row-centered;
  span {
    @extend .text-12;
    color: black;
    font-size: 20px;
    font-weight: 400;
    line-height: 100vh;
    text-align: center;
  }
}
.loading.transparent {
  position: fixed;
  top: var(--nav-height);
  z-index: 1;
  font-size: 20px;
  height: 200px;
  span {
    @extend .text-12;
  }
}

.page.feed,
.page.recommendations {
  .loading {
    height: auto;
  }
}
