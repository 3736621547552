@import "./index.scss";
.order-thumbnail {
  @extend .flex-by-column-top;
  @extend .card;
  margin: 0px 20px 20px 20px;
  padding: 20px;
  align-self: stretch;

  .meal-type {
    width: 100%;
    height: var(--nav-height);
    @extend .flex-by-row-centered;
    position: relative;
    margin: 0px;
    .meal-date {
      top: 10px;
      left: 10px;
      position: absolute;
    }
    .order-type {
      @extend .text-title-16;
      // font-size: 10px;
      // width: 100px;
      text-align: right;
      position: absolute;
      right: 10px;
    }
  }
  .receipt {
    box-shadow: none;
    width: 100%;
    align-self: stretch;
    flex-grow: 1;
    padding: 0px 0px 60px 0px;
    margin: 0px;
    @extend .flex-by-column-top;
    .receipt-body {
      width: 100%;
      .receipt-section {
        padding: 10px 20px;
        .receipt-row {
          width: 100%;
        }
        .receipt-row.saving {
          .highlight.saving {
            background-color: var(--background-gold);
            padding: 0px 10px;
            border-radius: 5px;
          }

          .highlight.member-only {
            background-color: var(--background-brown);
            padding: 0px 10px;
            border-radius: 5px;
          }
        }

        .receipt-row.restaurant {
          span {
            @extend .overflow-ellipsis;
            margin-left: 10px;
            text-align: right;
          }
        }
      }
    }
  }
  .order-action {
    @extend .flex-by-row-centered;
    justify-content: space-evenly;
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px;
    button,
    a {
      @extend .action-underline;
      padding: 0px;
      margin: 10px 0px 20px 0px;
      svg {
        margin-right: 5px;
      }
    }
  }
}

.order-thumbnail.lunch {
  .receipt {
    border: 2px solid var(--lunch-color);
  }
}
.order-thumbnail.dinner {
  .receipt {
    border: 2px solid var(--dinner-color);
  }
}
.order-thumbnail.express-dinner {
  .receipt {
    border: 2px solid var(--early-dinner-color);
  }
}
.order-thumbnail.highlight {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.8);
  // transition: box-shadow 1s;
}
.order-thumbnail.plain {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  transition: box-shadow 1s;
}
.receipt-body {
  .receipt-section.order-highlight {
    background-color: transparent;
    margin-bottom: 0px;
    .receipt-row.window {
      justify-content: flex-end;
    }
  }
}
@media only screen and (min-width: 800px) {
  .order-thumbnail {
    position: relative;
    margin-right: 0px;
    margin-left: 0px;
    max-width: 400px;
    width: 100%;
    align-self: auto;
    .receipt {
      margin: 0px;
    }
  }
}
