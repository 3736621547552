@import '../../index.scss';
.close-button {
  @extend .flex-by-row-centered;
  width: var(--nav-height);
  height: var(--nav-height);
  position: fixed;
  right: 0px;
  top: 0px;
  svg {
    font-size: 18px;
  }
}
