@import "./index.scss";
div.add-to-cart {
  @extend .flex-by-row-centered;
  width: 100%;
  .quantity-control {
    @extend .flex-by-row-centered;
    .quantity {
      @extend .flex-by-row-centered;
      @extend .text-15-400;
      margin: 0px 15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--background-grey);
    }
    .quantity.non-empty {
      background-color: green;
      color: white;
    }
  }
}
button.add-to-cart {
  @extend .action-black;
  bottom: 30px;
  font-weight: 500;
}
button.add-to-cart:disabled {
  opacity: 0.3;
}
@media only screen and (min-width: 500px) {
  div.add-to-cart {
    margin-top: 20px;
  }
}
