@import './index.scss';
.list.faq {
  padding: 20px 30px;
  @extend .flex-by-column-left;
  @extend .text-12-400;
  position: relative;
  width: 100%;
  max-width: 100vw;
  h2 {
    width: 100%;
    text-align: center;
    font-family: 'Theano Didot', serif;
    font-size: 24px;
    font-weight: 400;
    letter-spacing: 1px;
    text-transform: capitalize;
  }
  .faq-section {
    margin: 50px 0px 15px 0px;
    // @extend .card;
    // padding: 20px;
    width: 100%;
    position: relative;
    @extend .flex-by-column-left;

    .question {
      font-weight: 500;
      margin-bottom: 10px;
      font-size: 18px;
    }
    .answer {
      text-transform: none;
      font-weight: 300;
      letter-spacing: 0.8px;
      font-size: 16px;
      span {
        display: inline-block;
        margin-bottom: 10px;
        svg {
          font-size: 10px;
          position: relative;
          margin: 0px 5px;
        }
      }
      ul {
        li {
          font-weight: 500;
          margin-top: 8px;
          svg {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
.list.faq.about {
  @extend .flex-by-column-centered;
  .faq-section {
    @extend .flex-by-column-centered;
    max-width: 500px;

    .answer {
      padding: 30px 20px;
      text-align: center;
      margin-top: 70px;
      p {
        z-index: 1;
        margin-bottom: 30px;
        position: relative;
        text-align: left;
      }
      p:first-child:before {
        content: 'T';
        font-family: 'Theano Didot', serif;
        color: #f5f5f5;
        font-size: 160px;
        z-index: -1;
        position: absolute;
        top: -160px;
      }
      .signature {
        margin-top: 50px;
        @extend .flex-by-column-left;
        span:first-child {
          margin: 0px;
        }
        span:last-child {
          font-family: 'Satisfy';
          font-size: 30px;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) {
  .list.faq {
    padding: 30px calc(10% + 50px);
    .faq-section {
      display: flex;
      justify-content: center;
      .answer.about {
        padding-top: 50px;
        width: 500px;
        font-size: 14px;
        letter-spacing: 1px;
        text-align: left;
        p {
          margin-bottom: 50px;
        }
      }
    }
  }
}
.fancy-header {
  margin: auto;
}
