@import "./index.scss";
.how-it-works {
  @extend .flex-by-column-centered;
  // margin: 30px 0px;
  width: calc(100vw - 40px);
  max-width: 400px;
  padding: 20px 0px 40px 0px;
  margin-top: 40px;

  // border-bottom: 1px solid black;
  .title {
    // @extend .text-title;
    @extend .flex-by-row-centered;
    font-size: 20px;
    line-height: 20px;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    // width: 100%;
    text-align: center;
    // text-align: left;
    font-weight: 400;
    margin-bottom: 30px;
    padding: 0px;
    svg {
      font-size: 12px;
      margin-left: 5px;
    }
    // border-bottom: 1px solid black;
  }
  align-content: stretch;
  .steps {
    // width: 100%;
    flex-grow: 1;
    align-self: stretch;
    @extend .card;
    @extend .flex-by-column-centered;
    border: 1px solid rgba(217, 217, 217, 1);
    padding: 10px 10px 30px 10px;
    .step-body {
      @extend .flex-by-column-centered;
      flex-grow: 1;
      // width: 100%;
      align-self: stretch;
    }
    .step {
      background-color: #f8f7f5;
      // width: 100%;
      margin: 0 0px 10px 0px;
      // padding-left: 50px;
      @extend .flex-by-row-left;
      // @extend .card;
      padding: 10px 15px;
      align-self: stretch;
      // align-items: flex-start;
      flex-grow: 1;
      .step-count {
        // @extend .text-title;
        // display: inline;
        // margin-right: 10px;
        flex-shrink: 0;
        // width: 50px;
        height: 40px;
        // background-color: var(--background-grey);
        @extend .flex-by-row-centered;
        margin-right: 15px;
        // border-radius: 50%;
        // position: absolute;
        // left: 20px;
        // border: 1px solid var(--border-grey);
      }

      .step-content {
        @extend .text-13-400;
        font-size: 11px;
        line-height: 1.8;
        // @extend .text-title;
        // font-size: 20px;
        // font-weight: 400;
        letter-spacing: 0.6px;
      }
    }
    // .step:last-child {
    //   margin-bottom: 0px;
    // }
  }
  .more-details {
    @extend .action-underline;
    width: auto;
    margin-top: 20px;
    padding: 0px;
    font-size: 10px;
    font-weight: 400;
    text-transform: uppercase;
    border-bottom: 1px solid rgba(0, 0, 0, 0.6);
    svg {
      margin-left: 5px;
    }
  }
}

.scheduled-meals {
  font-size: 10px;
  line-height: 30px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  // text-align: left;
  font-weight: 400;
  margin: 50px 0px 20px 0px;
  @extend .flex-by-column-centered;
  svg {
    font-size: 30px;
    margin-bottom: 20px;
  }
  span {
    display: inline-block;
    line-height: 20px;
    // border-bottom: 1px solid black;
  }
}
.scheduled-meals.nominate {
  svg {
    color: var(--background-pink);
  }
}
.scheduled-meals.upvote {
  svg {
    color: black;
  }
}
@media only screen and (min-width: 1200px) {
  .scheduled-meals {
    padding-left: 10px;
    text-align: left;
    margin: 40px 0px;
    padding-left: 10px;
    width: 100%;
  }
  .how-it-works {
    width: 100%;
    max-width: 100%;
    padding: 30px calc(10vw + 90px);
    // justify-content: flex-start;
    // border-bottom: 1px solid black;
    // margin-bottom: 40px;
    .title {
      margin-bottom: 40px;
      width: 100%;
      justify-content: flex-start;
      padding-left: 10px;
    }
    .steps {
      // margin: 0px calc(10vw - 90px);
      // justify-content: space-between;
      // align-items: flex-start;

      // margin: 0px 20px;
      padding-bottom: 20px;
      box-shadow: none;
      border: none;
      align-items: flex-start;
      .step-body {
        flex-direction: row;
        padding: 0px;
        justify-content: flex-start;
      }
      .step {
        flex-direction: column;
        width: 25%;
        max-width: 202px;
        margin: 0px 10px;
        padding: 20px;
        // box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
        background-color: white;
        border: 1px solid var(--border-grey);
        .step-count {
          margin: 0px 0px 10px 0px;

          svg {
            font-size: 22px;
          }
        }
        .step-content {
          text-align: center;
          font-size: 13px;
          letter-spacing: 1px;
        }
      }
      .step:first-child {
        margin-left: 0px;
      }
      .step:last-child {
        margin-right: 0px;
      }
    }
    .more-details {
      margin-top: 50px;
    }
  }
}
// @media only screen and (min-width: 1000px) {
//   .how-it-works {
//   }
// }
// @media only screen and (min-width: 1200px) {
//   .hero2 {
//     background-position: 0px -300px;
//   }
// }
// @media only screen and (min-width: 1500px) {
//   .hero2 {
//     background-position: 0px -400px;
//   }
// }
