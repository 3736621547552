@import "./index.scss";
.cart-list {
  @extend .flex-by-column-centered;
  padding: 20px;
  margin-bottom: 100px;
  .create-group {
    @extend .card;
    @extend .flex-by-column-centered;
    align-self: stretch;
    padding: 20px;
    input {
      align-self: stretch;
      margin: 20px 0px;
    }
    button {
      @extend .action-black;
    }
  }
  .list-title {
    @extend .card;
  }
  .carts {
    width: 100%;
    @extend .flex-by-row-left;
    align-items: flex-start;
    flex-wrap: wrap;
    align-content: stretch;
    .cart {
      @extend .card;
      @extend .flex-by-column-left;
      justify-content: flex-start;
      @extend .text-12-300;
      font-size: 15px;
      margin: 10px;
      align-self: stretch;
      width: 300px;
      // flex-grow: 1;
      // width: calc(100% - 40px);
      // padding: 20px;
      border-radius: 10px;
      position: relative;
      padding: 20px;
      // flex-grow: 0;

      .cart-field {
        // @extend .text-16-400;
        margin-bottom: 20px;
        position: relative;
        padding: 0px;
      }
      > button {
        @extend .action-black;
      }
      .cart-field:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
