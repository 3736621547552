@import "./index.scss";
.list {
  padding-top: 0px;
  padding-bottom: 0px;
  .meal-type {
    position: relative;
    @extend .flex-by-column-centered;
    height: 30px;
    margin: 20px 0px;
    span.order-type {
      font-family: "Theano Didot", serif;
      font-size: 22px;
      text-transform: capitalize;
      letter-spacing: 1px;
      font-weight: 400;
    }
    svg {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  .meal-type.has-coupon,
  .meal-type.has-error {
    margin-top: var(--announcement-height-short);
  }

  .empty-cart {
    // width: 90%;
    @extend .flex-by-row-centered;
    // background-color: pink;
    position: fixed;
    top: calc(var(--nav-height) / 4);
    right: calc(var(--nav-height) / 4);
    z-index: 301;
    width: calc(var(--nav-height) / 2);
    height: calc(var(--nav-height) / 2);
    // margin-top: var(--announcement-height-short);
    button {
      // @extend .action-underline;
      // margin-top: 20px;
      padding: 0px;
      svg {
        // margin-right: 10px;
        font-size: 18px;
      }
    }
  }

  .cart-is-empty {
    @extend .flex-by-row-centered;
  }
  .receipt {
    position: relative;
    box-shadow: none;
    border: 1px solid var(--border-grey);
    .receipt-section.cart-summary {
      background-color: transparent;
      margin-bottom: 0px;

      // padding: 20px;
    }
  }
  .receipt.group {
    border: none;
    background-color: transparent;
    padding: 0px;
    width: calc(100% - 40px);
    .receipt-by-user {
      background-color: white;
      border: 1px solid var(--border-grey);
      padding-top: 60px;
      margin-bottom: 30px;
      .receipt-user-header {
        width: calc(100% - 30px);
        top: 10px;
        left: 15px;
      }
      .receipt-section {
        padding: 10px;
      }
    }
  }
  .cart {
    position: relative;
    // margin-top: 20px;

    .receipt {
      margin-top: 20px;
      .list-actions {
        padding: 20px;
        .checkout-disabled {
          opacity: 0.3;
          pointer-events: none;
        }
        .highlight {
          // border: 4px solid yellow;
          color: white;
          background-color: black;
        }
      }
    }
  }
  .cart.has-coupon,
  .cart.has-error {
    margin-top: var(--announcement-height-short);
  }
  .cart.above-min {
    margin-top: 75px;
  }
  .cart.initializing {
    margin-top: 0px;
  }
  .cart.below-min {
    margin-top: 0px;
  }
  .cart.list-body {
    padding-bottom: 100px;
  }
  .cart.list-body.group {
    margin-top: 95px;
  }
}
.list.lunch-cart,
.list.dinner-cart {
  min-height: calc(100vh - var(--nav-height));
  justify-content: flex-start;
  margin-bottom: 100px;
  .list-title {
    padding: 0px 75px;
    text-align: center;
    line-height: 1.2;
  }
  .list-title.cart-title {
    // position: relative;
    > span {
      position: absolute;
      top: 10px;
      font-size: 15px;
    }
  }
  .display-warning {
    @extend .card;
    @extend .text-12-300;
    font-size: 13px;
    width: 100%;
    background-color: red;
    color: white;
    font-size: 12.5px;
    text-align: left;
    // font-weight: bolder;
    text-transform: none;
    padding: 0px 30px;
    // margin-bottom: 15px;
    line-height: 2;
    position: sticky;
    top: 75px;
    z-index: 202;
    height: 75px;
    margin-top: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    line-height: 2;
    max-width: 450px;
  }
  .hide {
    height: 0px;
    opacity: 0;
  }
}
.list.lunch-cart {
  background-color: var(--lunch-color);
  padding-top: 0px;
  padding-bottom: 0px;
}
.list.dinner-cart {
  background-color: var(--dinner-color);
  padding-top: 0px;
  padding-bottom: 0px;
}
@media only screen and (min-width: 500px) {
  .list {
    // .meal-type {
    //   margin-top: 40px;
    // }
    // .empty-cart {
    //   margin-top: 10px;
    // }
  }
  .list {
    .cart {
      .receipt {
        .display-warning {
          padding: 8px 20px;
          font-size: 13px;
        }
      }
    }
  }
}
