@import "../index.scss";

.restaurant-details {
  .close-button {
    top: calc(var(--nav-height) + 20px);
    z-index: 2;
  }

  @extend .card;
  border-top: 1px solid rgb(217, 217, 217);
  @extend .flex-by-column-centered;
  .early-delivery {
    @extend .action-transparent;
    display: none;
  }
  .restaurant-actions {
    width: 100%;
    background-color: var(--background-beige);
    padding: 30px;
    border: 0px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    @extend .flex-by-row-centered;
    @extend .card;
    border-bottom: 1px solid var(--border-grey);
    button {
      @extend .action-black;
      background-color: white;
      color: black;
      border: 1px solid black;
      margin-right: 20px;
      margin-bottom: 20px;
    }
    button.close-button {
      border: none;
      position: absolute;
      right: 20px;
      top: 30px;
      background-color: transparent;
    }
  }
  .restaurant-categories {
    .dish-category {
      @extend .flex-by-column-centered;
      @extend .card;
      padding: 40px;
      margin: 80px 0px;
      background-color: var(--background-beige);
      border-top: 1px solid var(--border-grey);
      border-bottom: 1px solid var(--border-grey);
      .category-name {
        span {
          @extend .text-title;
        }
        margin-bottom: 20px;
      }
      .category-actions {
        @extend .flex-by-row-centered;
        button {
          @extend .action-transparent;
          margin: 10px;
          text-transform: uppercase;
        }
      }
      .dish-list {
        @extend .flex-by-row-left;
        align-items: flex-start;
        flex-wrap: wrap;
        flex-grow: 1;
        align-self: stretch;
        padding: 0px;
        .dish-item {
          @extend .flex-by-column-top;
          //   display: block;
          padding: 30px 10px 180px 10px;
          // @extend .card;
          background-color: white;
          margin: 40px 40px 0px 0px;
          width: 200px;
          align-self: stretch;
          position: relative;

          .hero-image {
            position: absolute;
            color: green;
            font-size: 14px;
            font-weight: 500;
            bottom: 90px;
          }
          .set-hero-image {
            position: absolute;
            bottom: 85px;
          }
          .dish-item-image {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
          .dish-name {
            text-align: center;
            margin-top: 10px;
            @extend .text-12-400;
            text-transform: capitalize;
          }
          .dish-price {
            @extend .text-13-500;
          }
          button.select-dish {
            // @extend .action-transparent;
            display: block;
            margin-top: 15px;
            width: 140px;
            padding: 0px 30px;
            border: 1px solid black;
            height: 40px;
            @extend .flex-by-row-centered;
            position: absolute;
            bottom: 125px;
            svg {
              display: inline-block;
              position: absolute;
              right: 6px;
              top: 12px;
              color: transparent;
              font-size: 15px;
            }
            span {
              @extend .text-12-300;
              font-weight: 500;
              letter-spacing: 1px;
              text-transform: uppercase;
            }
          }
        }
        .dish-item.selected {
          .preorder-quantity {
            position: absolute;
            bottom: 20px;
            width: 100%;
            @extend .flex-by-column-centered;
            @extend .text-12-300;
            label {
              text-transform: capitalize;
            }
            input {
              margin: 0px;
              text-align: center;
            }
          }
          button.select-dish {
            background-color: black;
            color: white;
            svg {
              color: white;
            }
          }
        }
      }
    }
  }
}
