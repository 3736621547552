html,
body {
  // overflow-x: hidden !important;
}
@font-face {
  font-family: "Theano Didot";
  src: url("fonts/TheanoDidot-Regular.ttf") format("truetype");
}
body {
  font-family: Montserrat;
  --ghost-white: #f7f9f9;
  --banner-white: #dcdcdc;
  --yelp-red: #ff1a1a;
  --warning-red: rgba(223, 44, 20);
  --background-beige: #f6f4f0;
  --background-grey: rgb(241, 241, 241);
  --background-peach: rgb(244, 189, 156);
  --background-pink-peach: #ffe3d3;
  --background-pink: #f9ece5;
  --border-grey: rgb(217, 217, 217);
  --border-gold: #867874;
  --text-grey: #757575;
  --text-black: #000000;
  --background-orange: #f87211;
  --background-bronze: #cd7f32;
  --background-vivid-blue: #296d9d;
  --background-gold: rgb(255, 191, 0);
  --background-purple: #5f5cff;
  --highlight-blue: #218aff;
  // --dinner-color: cadetblue;
  // --dinner-color: #5a2e80;
  // --dinner-color: #0396a5;
  // --dinner-color: #386495;
  // --dinner-color: #5a5492;
  --dinner-calendar-color: #ca6158;
  // --dinner-color: #efc1ab;
  --dinner-color: var(--background-beige);
  --early-dinner-color: var(--background-beige);

  --early-dinner-calendar-color: #f87211;

  // --lunch-color: #e8e02b;
  // --lunch-color: #fbaa3d;
  // --lunch-color: #ca6158;
  // --lunch-color: #fbaa3d;
  --lunch-calendar-color: #efc1ab;
  --lunch-color: var(--background-beige);
  --lunch-closed-color: rgba(239, 193, 171, 0.5);
  --dinner-closed-color: rgba(202, 97, 88, 0.5);

  --lunch-theme-font-color: black;
  --dinner-theme-font-color: black;
  --background-lightgreen: #a0e8af;
  --background-darkgreen: #30967a;
  --background-brightgreen: #86e890;
  --background-blue: #9ddcff;
  --background-pink: #ff9ad7;
  --background-yellow: #ffe352;
  --background-coral: #fe4945;
  --background-red: #f60400;
  --yelp-red: #ff1a1a;
  --background-gold: #ffbf00; //rgb(255, 191, 0);
  --background-brown: #bf9b30; //#a67c00;
  --hero-color: #ffe352;
  --beige-b: rgb(248, 247, 245);
  --mint: #e5e9d7;
  --lotus: #ead4ce;
  --nav-height: 75px;
  --nav-height-short: 55px;
  --desktop-left-padding: 3;
  --badge-height: 70px;
  --subnav-height: 70px;
  --subnav-height-short: 55px;
  --announcement-height: 480px;
  --announcement-height-short: 45px;
  --meal-height: 145px;
  --meal-padding: 10px;
  --meal-width-height-ratio: 0.8;
  // above for meal toggle
  // --nav-height: 110px;
  // --subnav-height: 50px;
  --title-height: 48px;
  --footer-height: 560px;
  --mobile-width: 400px;
  --quick-post-height: 80px;
  margin: 0px;
  padding: 0px; // 0px 100px 0px;
  max-height: 100%;
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}
// common
textarea:focus,
input:focus {
  outline: none;
}
textarea {
  resize: none;
  border-radius: 0px;
}
input {
  border-radius: 0px;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  font-size: 16px;
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
ul,
li {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
a {
  text-decoration: none;
  color: black;
}
button {
  border: none;
  background-color: transparent;
  outline: none;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  color: black;
}
a:hover,
button:hover {
  cursor: pointer;
}
button[type="submit"] {
  @extend .action-transparent;
}

input {
  flex-grow: 1;
  border: none;
  border-bottom: 1px solid rgb(219, 217, 217);
  padding: 10px 0px;
  font-family: "Montserrat", sans-serif;
  // text-transform: capitalize;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.74px;
  margin-bottom: 10px;
  margin-right: 15px;
}
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: #757575;
  text-transform: capitalize;
}
input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
// classes
.page.with-footer {
  min-height: calc(100vh + var(--footer-height));
  padding-bottom: var(--footer-height);
}
.page.with-tall-nav {
  padding-top: calc(var(--nav-height) + 20px);
}

.page.pop-up {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  height: 100vh;
  @extend .flex-by-column-centered;
  padding: 100px 0px;
}
.page {
  @extend .flex-by-column-top;
  align-items: center;
  width: 100%;
  max-width: 100vw;
  min-height: 100vh;
  // overflow-y: hidden;
  margin: 0px;
  background-color: white;
  position: relative;
  box-sizing: border-box;
  padding: var(--nav-height) 0px 0px 0px;
  // overflow-y: auto;
  // overflow-x: hidden;
  // max-height: 100vh;
  .list-wrapper {
    @extend .flex-by-column-top;
  }
  .empty-list {
    @extend .flex-by-column-top;
    width: 100%;
    // height: calc(100vh - var(--nav-height) - var(--title-height));
    padding-top: 200px;
    font-size: 13px;
    letter-spacing: 1px;
  }
}
.page::-webkit-scrollbar {
  display: none;
}
.page.no-nav {
  padding-top: 0px;
  min-height: 100vh;
}

/* Hide scrollbar for IE, Edge and Firefox */
.page {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.page.profile {
  padding-top: 150px;
}
.page.with-title {
  padding-top: calc(var(--nav-height) + var(--title-height));
}
.page.popup {
  padding: 0px 0px 100px 0px;
}
.page.single-meal {
  padding-top: calc(var(--nav-height) + 30px);
}
.page.calendar {
  padding-top: 50px;
}

.page-title {
  @extend .flex-by-row-centered;
  background-color: #f6f4f0;
  width: 100vw;
  height: var(--title-height);
  border-bottom: 1px solid rgb(217, 217, 217);
  text-transform: capitalize;
  font-weight: 300;
  font-family: "Theano Didot", serif;
  line-height: 1.4;
  letter-spacing: 1.6px;
  font-size: 14px;
  position: fixed;
  top: var(--nav-height);
  z-index: 1;
}
.card {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  background-color: #ffffff;
}

.card-deep {
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.7);
  background-color: #ffffff;
}
.form-container {
  @extend .flex-by-column-centered;
  width: calc(100vw - 80px);
  position: relative;
  margin-top: 40px;
  background-color: white;
  padding: 16px;
  .form-error {
    color: red;
  }
  .form-body {
    @extend .flex-by-column-centered;
    margin-bottom: 80px;
    width: 100%;
    .form-title {
      @extend .flex-by-row-centered;
      font-family: "Montserrat", sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.74px;
      margin-bottom: 32px;
    }
    label {
      width: 100%;
      text-align: left;
      margin: 25px 0px 10px 0px;
      letter-spacing: 1px;
    }
    .form-header {
      @extend .flex-by-column-centered;
      width: 100%;

      label {
        font-weight: 500;
        margin-bottom: 20px;
      }
      .form-field {
        text-align: left;
        width: 100%;
        padding: 10px 0px;
        margin: 0px 0px 20px 0px;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        letter-spacing: 0.74px;
        height: 44px;
        line-height: 44px;
        box-sizing: border-box;
        border: none;
        border-bottom: 1px solid black;
        border-radius: 0px;
        box-shadow: none;
      }
    }
    .form-content {
      width: 100%;
      box-sizing: border-box;
      border: 1px solid black;
      font-family: "Montserrat", sans-serif;
      font-size: 16px;
      letter-spacing: 0.74px;
      line-height: 45px;
      min-height: 200px;
      padding: 0px 25px;
      margin: 0px 0px;
      box-shadow: none;
    }
    .change-password {
      margin-top: 16px;
      font-size: 11px;
      width: 200px;
      height: 42px;
    }
  }
  button[type="submit"] {
    margin-top: 16px;
    width: 200px;
    @extend .action-black;
  }
}

.action-black {
  @extend .flex-by-row-centered;
  background: black;
  opacity: 0.8;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 0px 30px;
  height: 40px;
  box-shadow: none;
  box-sizing: border-box;
  color: white;
}
.action-grey {
  @extend .flex-by-row-centered;
  background: rgba(228, 228, 228, 0.5);
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.8px;
  padding: 10px 15px;
  box-shadow: none;
  box-sizing: border-box;
}
.action-underline {
  @extend .flex-by-row-centered;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  line-height: 2;
  border-bottom: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
}
.action-transparent {
  @extend .flex-by-row-centered;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
  padding: 12px 25px;
  min-width: 170px;
  height: 40px;
  border: 1px solid black;
  box-shadow: none;
  box-sizing: border-box;
}
.form-section {
  @extend .flex-by-column-stretch;
  max-width: 500px;
  .form-section-title {
    @extend .flex-by-row-left;
    font-family: "Jacques Francois", serif;
    font-size: 22px;
    font-weight: 400;
    letter-spacing: 0.6px;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  .form-row {
    @extend .flex-by-row-stretch;
    width: 100%;
    margin-bottom: 20px;

    input:last-child {
      margin-right: 0px;
    }
  }
}
// styles
.flex-by-row {
  display: flex;
  flex-direction: row;
}
.flex-by-column {
  display: flex;
  flex-direction: column;
}

.flex-by-row-centered {
  @extend .flex-by-row;
  justify-content: center;
  align-items: center;
}
.flex-by-row-top {
  @extend .flex-by-row;
  justify-content: center;
  align-items: flex-start;
}
.flex-by-row-bottom {
  @extend .flex-by-row;
  justify-content: center;
  align-items: flex-end;
}

.flex-by-row-stretch {
  @extend .flex-by-row;
  justify-content: center;
  align-items: stretch;
}
.flex-by-row-top-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-by-row-even {
  @extend .flex-by-row;
  justify-content: space-evenly;
  align-items: center;
}

.flex-by-column-centered {
  @extend .flex-by-column;
  justify-content: center;
  align-items: center;
}
.flex-by-column-stretch {
  @extend .flex-by-column;
  justify-content: center;
  align-items: stretch;
}
.flex-by-column-left {
  @extend .flex-by-column;
  justify-content: center;
  align-items: flex-start;
}
.flex-by-column-right {
  @extend .flex-by-column;
  justify-content: center;
  align-items: flex-end;
}

.flex-by-column-between {
  @extend .flex-by-column;
  justify-content: space-between;
  align-items: center;
}
.flex-by-column-even {
  @extend .flex-by-column;
  justify-content: space-evenly;
  align-items: center;
}
.flex-by-column-around {
  @extend .flex-by-column;
  justify-content: space-around;
  align-items: center;
}
.flex-by-column-top-left {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-column-left-between {
  @extend .flex-by-column;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-by-column-top {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: center;
}
.flex-by-column-bottom {
  @extend .flex-by-column;
  justify-content: flex-end;
  align-items: center;
}
.flex-by-column-bottom-right {
  @extend .flex-by-column;
  justify-content: flex-end;
  align-items: flex-end;
}
.flex-by-column-upper-left {
  @extend .flex-by-column;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-row-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: center;
}
.flex-by-row-left-bottom {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-by-row-right {
  @extend .flex-by-row;
  justify-content: flex-end;
  align-items: center;
}

.flex-by-row-upper-left {
  @extend .flex-by-row;
  justify-content: flex-start;
  align-items: flex-start;
}

.flex-by-row-upper-right {
  @extend .flex-by-row;
  justify-content: flex-end;
  align-items: flex-start;
}

.flex-by-row-between {
  @extend .flex-by-row;
  justify-content: space-between;
  align-items: center;
}
.flex-by-row-between-top {
  @extend .flex-by-row;
  justify-content: space-between;
  align-items: flex-start;
}
.flex-by-row-space-around {
  @extend .flex-by-row;
  justify-content: space-around;
  align-items: center;
}

.flex-by-column-align-left {
  @extend .flex-by-column;
  justify-content: flex-start;
}
.overflow-ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.text-20 {
  line-height: 27px;
  font-size: 20px;
}
.text-34 {
  height: 46px;
  font-size: 34px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
  color: var(--eerie_black);
}
.title-34 {
  @extend .text-34;
  font-weight: bold;
  text-transform: capitalize;
}
.title-32 {
  font-size: 32px;
  line-height: 32px;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.title-26 {
  font-size: 26px;
  line-height: 26px;
  font-weight: 700;
  letter-spacing: 1.5px;
}
.title-22 {
  font-size: 22px;
  line-height: 22px;
  font-weight: 700;
  letter-spacing: 0.5px;
}
.title-20 {
  line-height: 27px;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  text-transform: capitalize;
  color: #000000;
}

.title-16 {
  line-height: 24px;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.15px;
  color: var(--eerie_black);
  text-transform: capitalize;
}

.title-14 {
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.25px;
  color: var(--eerie_black);
  text-transform: capitalize;
}
.text-16 {
  line-height: 28px;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
}
.text-16-cap {
  @extend .text-16;
  text-transform: capitalize;
}
.text-14-button {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: 0.1px;
  text-transform: capitalize;
  color: var(--ghost_white);
}
.text-14 {
  line-height: 24px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--eerie_black);
  text-transform: none;
}
.text-14-cap {
  @extend .text-14;
  text-transform: capitalize;
}

.text-14-upper {
  @extend .text-14;
  text-transform: uppercase;
}
.text-12 {
  line-height: 16px;
  font-size: 12px;
  letter-spacing: 0.4px;
  color: var(--eerie_black);
  text-transform: normal;
}

.text-12-cap {
  @extend .text-12;
  text-transform: capitalize;
}

.text-12-upper {
  @extend .text-12;
  text-transform: uppercase;
}

.title-12 {
  @extend .text-12-cap;
  font-weight: bold;
}
.text-10 {
  line-height: 16px;
  font-size: 10px;
  letter-spacing: 1.5px;
}
.text-10-upper {
  @extend .text-10;
  text-transform: uppercase;
}
.title-10 {
  @extend .text-10-upper;
  font-weight: bold;
}
.text-9 {
  line-height: 14px;
  font-size: 9px;
  letter-spacing: 1.2px;
}
.text-9-upper {
  @extend .text-9;
  text-transform: uppercase;
}
.with-curls {
  position: relative;
  line-height: 30px !important;
}
.with-curls::before,
.with-curls::after {
  content: "";
  position: absolute;
  top: 14px;
  display: block;
  width: 25px;
  height: 1px;
  background-color: rgb(219, 217, 217);
}
.with-curls::before {
  left: calc(-25px - 5px);
}
.with-curls::after {
  left: calc(100% + 5px);
}
.no-transform {
  text-transform: none;
}
.page.new-user,
.page.new-entity {
  @extend .flex-by-column-top;
  // border: 1px solid black;
  min-height: 100vh;
  width: 100vw;
  height: auto;
  background-color: #f8f7f5; //var(--background-beige);
  // top: 0px;
  // left: 0px;
  // position: fixed;
  justify-content: flex-start;
  padding: 15px 0px 10px 0px;
  .anonymous-container {
    // margin-top: 150px;
    @extend .flex-by-column-centered;
    align-self: stretch;
    margin-bottom: 100px;
    width: 100vw;
    // min-height: 100vh;
    // height: auto;
    overflow: hidden;
    justify-content: flex-start;
    .anonymous-form {
      padding: 0px;
      @extend .flex-by-column-centered;
      position: absolute;
      top: 140px;
      width: calc(100vw - 40px);
      max-width: 450px;
      .anonymous-header {
        // @extend .card;
        @extend .flex-by-column-centered;
      }
    }
  }
}
.custom-continue-with-google-button-wrapper {
  // background-color: white!important;
}
.custom-google-button {
}
.custom-facebook-button-wrapper {
  background-color: #3c5898;
  border: none;
}
.social-icon-wrapper {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  overflow: hidden;
  svg {
    margin: 0 auto;
    width: auto;
    height: auto;
    position: relative;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    font-size: 30px;
  }
  img {
    margin: 0px !important;
    width: 45px !important;
    height: 45px !important;
  }
}
.custom-facebook-button {
  padding: 0px !important;
  span {
    font-weight: 600;
    color: white;
    font-size: 18px;
    letter-spacing: 0.7px;
  }
}
@media only screen and (min-width: 800px) and (min-height: 700px) {
  .page.new-user,
  .page.new-entity {
    justify-content: center;
    // background-color: white;
    .anonymous-container {
      .anonymous-form {
        padding-top: 40px;
        // background-color: #f8f7f5;
        // border: 1px solid #ccc;
      }
    }
  }
}

@media only screen and (max-height: 700px) and (max-width: 400px) {
  .page.new-user,
  .page.new-entity {
    justify-content: center;
    .anonymous-container {
      .anonymous-form {
        .anonymous-header {
          margin-bottom: 0px;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) {
  .form-container {
    width: 470px;
  }
}
@media only screen and (max-height: 600px) and (max-width: 400px) {
  .page.new-user,
  .page.new-entity {
    justify-content: center;
    .anonymous-container {
      .anonymous-form {
        .anonymous-header {
          flex-direction: row;
          margin-bottom: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 350px) {
  .action-black {
    padding: 0px 20px;
  }
}
