@import './index.scss';
.list.driver-tasks {
  .transient-message {
    position: fixed;
    top: 0px;
    @extend .text-12-300;
    height: var(--nav-height);
    @extend .flex-by-row-centered;
    z-index: 4;
    div {
      background-color: var(--background-grey);
      border-radius: 10px;
      height: 30px;
      padding: 0px 15px;
      box-sizing: border-box;
      @extend .flex-by-row-centered;
      svg {
        margin-right: 5px;
      }
      .copied-content {
        margin-right: 5px;
        text-transform: capitalize;
      }
    }
  }
  .driver-action-container {
    width: 100%;
  }
  .driver-action {
    @extend .flex-by-row-between;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 10px;
    padding: 10px 15px 10px 50px;
    background-color: var(--background-grey);
    position: relative;
    .driver-label {
      position: absolute;
      left: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      box-sizing: border-box;
      @extend .flex-by-row-centered;

      svg {
        font-size: 20px;
        color: white;
        // padding: 5px;
      }
    }
    .show-orders {
      position: absolute;
      left: 50px;
      top: 10px;
      width: 40px;
      height: 40px;
      color: var(--background-orange);
    }
    .order-status {
      @extend .flex-by-row-right;
      @extend .text-16-400;
      padding-right: 0px;
      font-weight: 500;
      letter-spacing: 1px;
      min-height: 40px;
      height: auto;
      width: 100%;
      box-sizing: border-box;
      text-align: right;
      padding-left: 10px;
      span {
        text-align: right !important;
      }
      input[type='checkbox'] {
        display: inline-block;
        // width: 100px;
        flex-grow: 0;
        margin: 0px 0px 0px 10px;
        border: 3px solid black;
      }
    }
    .order-status.item-details {
      @extend .flex-by-column-right;
      .item-total {
        display: inline-block;
        line-height: 40px;
        margin-bottom: 10px;
        color: var(--background-red);
        font-weight: 500;
        text-transform: uppercase;
      }
      ul.item-list {
        li.item {
          .item-count {
            display: inline-block;
            color: var(--background-red);
            margin-left: 10px;
            font-size: 16px;
            width: 24px;
            line-height: 24px;
            text-align: center !important;
            border: 2px solid var(--background-red);
            border-radius: 50%;
          }
          margin-bottom: 20px;
        }
      }
    }
    .order-status.restaurant-address {
      text-transform: capitalize;
    }
  }
  .order-number-notice {
    @extend .text-13-400;
    // padding: 0px 5px;
    height: 50px;
    line-height: 50px;
    font-size: 15px;
    width: 100%;
    background-color: var(--background-yellow);
    margin-bottom: 15px;
    text-align: center;
    b {
      font-size: 20px;
    }
  }
  .driver-action.order-address,
  .driver-action.restaurant-address {
    .driver-label {
      background-color: var(--background-darkgreen);
    }
    // color: white;
    // font-weight: 500;
  }
  .driver-action.restaurant-name {
    .order-status {
      text-transform: capitalize;
    }
  }
  .driver-action.order-delivery-status {
    .driver-label {
      background-color: var(--background-lightgreen);
    }
  }
  .driver-action.order-phone {
    .driver-label {
      background-color: var(--background-purple);
    }
  }
  .driver-action.order-phone.divided {
    margin-bottom: 40px;
  }
  .driver-action.order-order {
    .driver-label {
      background-color: var(--background-red);
    }
  }
  .driver-action.order-restaurant {
    .driver-label {
      background-color: var(--background-lightgreen);
    }
  }
  .driver-action.meal {
    .driver-label {
      background-color: var(--background-orange);
    }
  }
  .driver-action.target-time {
    .driver-label {
      background-color: var(--background-coral);
    }
  }
  .driver-action.doorbell-instructions {
    .driver-label {
      background-color: var(--background-blue);
      svg {
        font-size: 22px;
      }
    }
  }
  .driver-action.order-note {
    .driver-label {
      background-color: var(--background-yellow);
      svg {
        font-size: 22px;
      }
    }
  }
  .driver-action.order-name {
    .driver-label {
      background-color: var(--background-orange);
    }
    span {
      text-align: center;
    }
  }
  .driver-action.restaurant-name {
    .driver-label {
      background-color: var(--background-coral);
    }
  }
  .driver-action.order-address-map {
    background-color: transparent;
    padding: 0px;
    border-radius: 0px;
    .map-container {
      background-position: center;
      background-size: cover;
      width: 100%;
      @extend .flex-by-column-centered;
      box-sizing: content-box;
      img {
        object-fit: cover;
        width: 100%;
        height: var(--image-width);
        box-sizing: border-box;
        border: none;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }
  .driver-action.place-order {
    margin-bottom: 20px;
  }
  .deliver-action-wrapper {
    @extend .flex-by-column-centered;
    background-color: var(--background-grey);
    border-radius: 10px;
    width: 100%;
    .fileUploader {
      // background-color: var(--background-grey);
      .fileContainer {
        box-shadow: none;
        background-color: var(--background-grey);
        padding-bottom: 10px;
        button.chooseFileButton {
          border-radius: 0px !important;
          @extend .action-black;
          color: black;
          border: 1px solid black;
          background-color: transparent;
        }
      }
    }
    .deliver-button {
      margin-top: 0px;
      margin-bottom: 20px;
    }
  }

  .dispatch-button {
    @extend .action-black;
    background-color: transparent;
    color: black;
    border: 2px solid black;
    font-weight: 600;
  }
  .dispatch-button,
  .deliver-button {
    // background-color: transparent;
    margin: 20px 0px;
    width: 300px;
    // border: 2px solid black;
    // color: black;
    // font-weight: 600;
    padding: 10px;
    letter-spacing: 1px;
    // width: 160px;
    height: 60px;
    span {
      line-height: 1.6;
    }
  }
  .deliver-button:disabled {
    opacity: 0.5;
  }
  .action-wrapper {
    @extend .flex-by-row-centered;
    margin: 30px 0px;
    button {
      @extend .action-black;
    }
    button.accept {
      margin-left: 20px;
    }
    button.reject {
      color: black;
      background-color: transparent;
      border: 1px solid black;
    }
  }
  .transient-message {
    @extend .text-13-400;
    text-align: center;
    margin: 10px 0px 30px 0px;
  }
  .message-to-driver {
    @extend .flex-by-column-centered;
    margin: 20px 0px 10px 0px;
    span {
      text-align: left;
      font-size: 14px;
    }
  }
  .route-summary {
    @extend .flex-by-row-left;
    font-size: 14.5px;
    width: 100%;
    margin: 3px 0px 3px 0px;
    padding: 0px 10px 0px 10px;
    justify-content: space-between;
    span {
      text-align: left;
    }
    .category {
      font-weight: 500;
    }
  }
  .route-summary.title {
    @extend .flex-by-row-centered;
    text-decoration: underline;
    text-align: center;
    margin: 10px 0px 5px 0px;
    font-weight: 500;
  }
  .note {
    width: 100%;
    font-size: 12.5px;
    text-align: right;
  }
  .delivery-info {
    width: 100%;
    background-color: var(--background-beige);
    padding: 20px;
    .field {
      width: 100%;
      font-size: 14.5px;
      @extend .flex-by-column-left;
      margin-bottom: 10px;
      label {
        font-weight: 500;
      }
      span {
        text-transform: capitalize;
      }
    }
    .field.restaurant-name {
      color: black;
    }
    .field.pickup-time {
      span {
        @extend .no-transform;
        text-transform: none;
      }
    }
  }
}
.list.driver-tasks.start-restaurant {
  padding-bottom: 20px;
}
.list.driver-tasks.availability {
  padding-bottom: 20px;
}
.driver-orders-stops {
  @extend .flex-by-column-centered;
  span {
    display: inline-block;
    @extend .text-13-400;
    font-size: 16px;
    font-weight: 500;
  }
}
.receipt {
  @extend .flex-by-column-centered;
  // align-items: stretch;
  .receipt-section {
    width: 100%;
  }
}
.order-header {
  width: 100%;
  // text-align: left;
  text-align: center;
  // font-size: 16px;
  margin-bottom: 20px;
  @extend .flex-by-row-centered;
  .order-index {
    margin-right: 10px;
  }
  .order-name {
    font-weight: 500;
    background-color: var(--background-grey);
    padding: 0px 10px;
  }
}
.order-view {
  width: 100%;
  @extend .flex-by-row-centered;
  margin-bottom: 20px;
  button {
    @extend .flex-by-row-centered;
    border: 1px solid black;
    margin: 10px;
    padding: 5px 10px 5px 30px;
    opacity: 0.25;
    position: relative;
    span {
      @extend .text-12-400;
      text-transform: capitalize;
      letter-spacing: 0.8px;
    }
    svg {
      display: none;
      position: absolute;
      left: 10px;
    }
  }
  button.on {
    opacity: 1;
    svg {
      display: block;
    }
  }
}

.assign-stop-index {
  @extend .flex-by-row-between;
}

.list {
  .meal-select-wrapper {
    background: transparent;
    margin-bottom: 20px;
  }
}
.back-to-top {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 3;
  border-radius: 50%;
  background-color: white;
  @extend .flex-by-row-centered;
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.5);
  width: 50px;
  height: 50px;
  svg {
    font-size: 20px;
  }
}
.list.driver-tasks.start-restaurant {
  // margin-bottom: 20px;
  .receipt {
    margin-bottom: 10px;
  }
}

.driver-summary {
  max-width: 500px;
  margin-top: 20px;
  width: 100%;

  .driver-summary-average {
    width: 100%;
    text-align: center;
    font-size: 14px;
  }

  .driver-summary-date {
    text-align: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .driver-summary-details {
    @extend .flex-by-column-centered;
    .driver-summary-row {
      font-size: 14.5px;
      width: 100%;
      line-height: 180%;
      .driver-summary-day {
        font-size: 14.5px;
        margin: 3px 0;
        font-weight: 500;
      }
      .driver-no-route {
        color: #888;
      }
    }
  }
}
@media only screen and (min-width: 600px) {
  .back-to-top {
    left: auto;
    right: 40px;
    bottom: 20px;
  }
}
