@import './index.scss';
.referrals {
  @extend .flex-by-column-top;
  @extend .card;
  position: fixed;
  top: 0px;
  width: 100%;
  height: 100vh;
  padding-top: var(--nav-height);
  box-shadow: 0px 4px 12px 2px rgba(28, 29, 33, 0.12);
  .loading {
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    margin-top: 0px;
    color: white;
    @extend .text-14-300;
    font-size: 20px;
    font-weight: 400;
    z-index: 4;
  }
  .referrals-title {
    @extend .flex-by-row-centered;
    @extend .card;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;

    height: var(--nav-height);
    background-color: #e8e02b;
    border-bottom: 1px solid var(--border-grey);
    box-sizing: border-box;
    span {
      font-size: 10px;
      font-weight: 600;
      font-family: 'Rock Salt';
      letter-spacing: 2.5px;
      padding-left: 40px;
      svg {
        display: inline-block;
        font-size: 14px;
        position: relative;
        top: 3px;
        transform: skew(-0.55turn, 0deg);
        margin-right: 8px;
      }
    }
    button.back-button {
      left: 0px;
      width: var(--nav-height);
      height: var(--nav-height);
      svg {
        margin: 0px;
      }
    }
  }
  .referral-code-body {
    // background-color: pink;
    width: 100%;
    max-width: 500px;
    padding: 0px 30px;
    @extend .flex-by-column-centered;
    align-items: stretch;
    .referral-code-section {
      flex-grow: 1;
      @extend .card;
      padding: 40px 20px 20px 20px;
      max-width: 500px;
      margin-bottom: 20px;
      // background-color: #e8e02b;
      .slogan {
        .slogan-text {
          @extend .flex-by-column-centered;
          @extend .text-title;
          // color: var(--background-purple);

          span {
            font-size: 17px;
            // font-size: 10px;
            font-weight: 600;
            font-family: 'Rock Salt';
            letter-spacing: 2.5px;
          }
          svg {
            // background-color: #e8e02b;
            color: var(--background-coral);
            --fa-secondary-opacity: 1;
            // --fa-primary-color: var(--background-coral);
            // --fa-secondary-color: var(--background-purple);
            --fa-primary-color: #f76b6b;
            // --fa-primary-color: #e8e02b;
            --fa-secondary-color: #ccc;
            font-size: 80px;
            margin-bottom: 20px;
          }
          svg.fa-envelope-open-text {
            --fa-secondary-color: white;
            --fa-primary-color: #ccc;
            font-size: 82px;
          }
          svg.fa-heart-circle {
            position: absolute;
            top: 50px;
            font-size: 49px;
            --fa-secondary-color: white;
            --fa-primary-color: #f76b6b;
          }
          span {
            margin: 20px 0px;
            // font-size: 17px;
          }
        }
      }
      .code-summary {
        margin-top: 20px;
        width: 100%;
        .code-summary-row {
          width: 100%;
          @extend .flex-by-row-between;
          @extend .text-12-400;

          label {
            font-weight: 500;
          }
        }
      }
    }
    .referral-code-section.code-rules-wrapper {
      background-color: #e8e02b;
    }

    .referral-code-section:last-child {
      margin-bottom: 80px;
      .slogan {
        .slogan-text {
          span {
            @extend .text-title;
            // b {
            //   // font-size: 10px;
            //   font-weight: 600;
            //   font-family: 'Rock Salt';
            //   letter-spacing: 2.5px;
            //   padding-left: 40px;
            // }
          }
        }
      }
    }
    .code-rules-wrapper {
      .code-rules {
        width: 100%;
        margin-top: 0px;
        // padding: 0px 20px;
        @extend .text-12-300;
        line-height: 1;
        font-weight: 400;
        @extend .flex-by-column-centered;
        // padding-left: 20px;
        p,
        li {
          margin-bottom: 10px;
          position: relative;
          line-height: 20px;
          // margin-left: 10px;
          width: calc(100% - 20px);
          list-style-type: disc;
          color: rgba(0, 0, 0, 0.7);
          // text-align: justify;
          b {
            font-weight: 600;
            // text-transform: capitalize;
            // color: rgba(0, 0, 0, 1);
            // color: #f76b6b;
          }
          b.red {
            color: #f76b6b;
          }
        }
        li:last-child {
          margin-bottom: 0px;
        }
        .notes {
          width: calc(100% - 20px);
          margin-top: 10px;
          p {
            font-size: 9px;
            line-height: 14px;
            margin: 0px;
            text-align: left;
            width: 100%;
            margin-bottom: 10px;
          }
        }
        // p:last-child {
        //   margin-bottom: 0px;
        // }
        // li::before {
        //   content: '';
        //   position: absolute;
        //   width: 10px;
        //   height: 10px;
        //   background-color: black;
        //   transform: rotate(45deg);
        //   top: 5px;
        //   left: 0px;
        // }
      }
    }
    .not-eligible {
      @extend .flex-by-column-centered;
      margin-top: 10px;
      padding: 0px 20px;
      svg {
        font-size: 40px;
        margin-bottom: 30px;
      }
      .fancy-header {
        margin-bottom: 30px;
      }
      span {
        @extend .text-12-300;
        line-height: 20px;
        b {
          font-weight: 600;
          text-transform: capitalize;
          color: rgba(0, 0, 0, 1);
        }
      }
    }

    .referral-code-section.code-actions {
      width: 100%;
      max-width: 500px;
      @extend .flex-by-column-centered;
      // box-shadow: none;
      > svg {
        // background-color: #e8e02b;
        color: var(--background-coral);
        --fa-secondary-opacity: 1;
        --fa-primary-color: var(--background-coral);
        --fa-secondary-color: var(--background-purple);
        font-size: 80px;
        margin-bottom: 20px;
      }
      .code-section {
        width: 100%;
        // @extend .card;
        margin: 10px 0px;
        padding: 30px 20px;
        // border: 1px dashed black;
        // background-color: var(--background-beige);
        @extend .flex-by-column-centered;

        button,
        a {
          margin: 0px 0px 0px 0px;
          // @extend .action-transparent;
          width: 200px;
          @extend .flex-by-row-centered;
          background: black;
          opacity: 0.8;
          padding: 0px 20px 0px 40px;
          height: 40px;
          box-shadow: none;
          box-sizing: border-box;
          color: white;
          position: relative;
          svg {
            font-size: 16px;
            margin-right: 5px;
            position: absolute;
            left: 20px;
          }
          span {
            font-family: 'Montserrat', sans-serif;
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.5px;
          }
        }
      }
      .code-section:last-child {
        margin-bottom: 0px;
      }
      .code-url {
        @extend .flex-by-column-centered;
        margin: 0px 0px 20px 0px;

        .code-name {
          @extend .flex-by-column-centered;
          margin-bottom: 20px;
          span {
            @extend .text-title;
            // font-size: 14px;
          }
        }
        .code-url-body {
          @extend .flex-by-row-centered;
          background-color: var(--background-beige);
          width: 100%;

          @extend .text-title;
          font-weight: 500;
          padding: 5px 10px;
          // border-radius: 3px;
          // border-bottom: 1px solid black;
          svg {
            margin-left: 10px;
          }
          span {
            font-size: 10px;
            // text-decoration: underline;
          }
        }
      }
      .code-url.link {
        margin-bottom: 40px;
        .link-instruction {
          @extend .text-12-300;
          margin-bottom: 20px;
          // text-align: justify;
          line-height: 20px;
        }
        .code-url-body {
          span {
            @extend .text-12-400;
            font-size: 9px;
            font-weight: 500;
          }
        }
      }
      .code-url.code {
        margin-bottom: 0px;
        .code-url-body {
          background-color: transparent;
          span {
            font-size: 24px;
          }
        }
      }
    }
  }
  .referrals-body {
    @extend .flex-by-column-top;
    width: 100%;
    overflow-y: scroll;
    .referrals-section {
      width: 100%;
      max-width: 500px;
      padding: 20px 10px;
      box-sizing: border-box;
      @extend .flex-by-column-centered;
      @extend .text-12-300;
      .referrals-section-title {
        margin-bottom: 20px;
        @extend .text-13-500;
        text-transform: uppercase;
      }
      .referrals-subsection {
        @extend .card;
        padding: 20px;
        margin-bottom: 20px;
        .referrals-subsection-title {
          position: relative;
          width: 100%;
          @extend .text-title-16;
          text-transform: capitalize;
          margin-bottom: 10px;
          @extend .flex-by-row-centered;
          // span {
          //   @extend .with-curls;
          // }
          padding: 0px 40px;
          button.toggle-sent-details {
            position: absolute;
            right: 0px;
          }
        }
        @extend .flex-by-column-centered;
        width: 100%;
      }
    }
    .referrals-section.referral-conditions {
      padding: 30px;
      ul.condition-list {
        width: 100%;
        li.condition-item {
          width: 100%;
          margin-bottom: 20px;
          span {
            display: inline-block;
            margin-bottom: 5px;
            position: relative;
            b {
              margin-right: 5px;
              font-weight: 500;
            }
          }

          ul.condition-section {
            li {
              margin-left: 30px;
              list-style-type: disc;
            }
          }

          ul.referral-cities {
            li::after {
              content: ',';
              margin-left: 1px;
            }
            li:last-child:after {
              content: '.';
            }
          }
        }
        li.condition-item:last-child {
          margin-bottom: 0px;
        }
      }
    }

    .referrals-section.your-referrals {
      .referrals-subsection {
        .referrals-list {
          width: 100%;
          @extend .flex-by-column-left;
          .referral-item {
            width: 100%;
            margin-bottom: 15px;
            @extend .flex-by-column-left;
            label {
              text-transform: capitalize;
              font-weight: 500;
              display: inline-block;
            }
            .referral-content {
              width: 100%;
              padding: 15px;
              background-color: var(--background-beige);
              box-sizing: border-box;
              .referral-field {
                width: 100%;
                @extend .flex-by-row-between;
                position: relative;
                box-shadow: border-box;
                span {
                  letter-spacing: 0.74px;
                  text-transform: capitalize;
                }
                span:last-child {
                  text-align: right;
                }
              }
            }
          }
          .referral-item:last-child {
            margin-bottom: 5px;
          }
        }
        .referrals-list.sent-referrals {
          .referral-item.rewards {
            .referral-content {
              .referral-field:last-of-type {
                padding-top: 10px;
                border-top: 1px solid var(--border-grey);
              }
            }
          }
        }
        .referrals-list.new-referrals {
          .referral-item.new-referral {
            position: relative;
            .delete-referral {
              position: absolute;
              right: 0px;
              top: 2px;
            }
            .referral-content {
              padding: 0px;
              margin-top: 10px;
              @extend .flex-by-column-left;
              input {
                margin: 0px;
                padding: 0px 0px 0px 15px;
                width: 100%;
                height: 40px;
                background-color: var(--background-grey);
                border: none;
                box-sizing: border-box;
              }
              .friend-contact {
                width: 100%;
                .PhoneInputCountry {
                  display: none;
                }
              }
            }
            .referral-content:first-of-type {
              margin-top: 0px;
            }
            .referral-content.has-error {
              border: 1px solid var(--background-coral);
              box-sizing: border-box;
            }
          }
          .add-friend-wrapper {
            width: 100%;
            @extend .flex-by-row-right;
            .add-friend {
              @extend .text-12-300;
              width: auto;
              @extend .flex-by-row-right;
              text-transform: capitalize;
              font-weight: 500;
              svg {
                margin-left: 15px;
                font-size: 16px;
              }
            }
          }
          .error-message {
            margin-top: 10px;
            margin-bottom: 0px;
            text-transform: none;
            line-height: 2;
            width: 100%;
            box-sizing: border-box;
            color: var(--background-coral);
            text-align: center;

            position: relative;
            svg {
              position: absolute;
              left: 0px;
              top: 5px;
              display: none;
            }
          }
          .error-message.has-error {
            opacity: 1;
            font-weight: 400;
          }
          .error-message.no-error {
            opacity: 0;
          }
          button.send-invite {
            @extend .action-black;
            @extend .flex-by-row-centered;
            box-sizing: border-box;
            padding: 0px 15px 0px 17px;
            align-self: stretch;
            height: 45px;
            span {
              line-height: 45px;
            }
            svg {
              font-size: 12px;
              margin: 2px 0px 0px 10px;
            }
          }
        }
      }
    }

    .referrals-section:last-child {
      margin-bottom: 40px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .referrals {
    .referrals-title {
      span {
        padding-left: 20px;
      }
      button.back-button {
        left: -20px;
      }
    }
  }
}
.referrals.code {
  position: relative;
  padding-top: 0px;
  box-shadow: none;
  margin-bottom: 100px;
}
@media only screen and (min-width: 1100px) {
  .referrals {
    .referrals-title {
      button.back-button {
        left: calc(10% + 10px);
      }
    }
  }
}
