@import "./index.scss";
.form-subsection.card-form {
  margin: 0px 20px;
  .form-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .form-field-wrapper {
      position: relative;
      display: flex;
      input {
        // background-color: pink;
        width: auto;
        flex-grow: 1;
        @extend.text-16-400;
        padding: 10px 10px;
        align-self: stretch;
        // margin-right: 10px;
        border: none;
      }
    }
    .form-field-wrapper.half {
      width: calc(50% - 10px);
      input {
        width: 100%;
      }
      &:last-child {
        margin-left: 10px;
      }
    }
  }
  input {
    @extend.text-16-400;
    padding: 10px 10px;
    align-self: stretch;
    border: none;
  }
  .error-overall {
    background-color: red;
    color: white;
    padding: 5px 10px;
    @extend .text-12-300;
    line-height: 1.5;
    font-weight: 400;
  }
}
.form-row.existing-card {
  @extend .flex-by-row-stretch;
  justify-content: stretch;
  width: 100%;
  border-bottom: 1px solid var(--border-grey);
  position: relative;
  .card-wrapper {
    @extend .flex-by-row-left;
    @extend .text-16-400;
    width: 100%;
    padding: 10px 0px;
    position: relative;
    .brand-icon {
      font-size: 24px;
      margin-right: 10px;
    }
    .brand-name {
      text-transform: capitalize;
      margin-right: 5px;
    }
    .last4 {
      text-transform: lowercase;
    }
    .show-card-action {
      position: absolute;
      right: 10px;
    }
  }
  .delete-card {
    @extend .card;
    @extend .flex-by-row-between;
    @extend .text-16-400;
    position: absolute;
    top: 100%;
    right: 0px;
    width: 50%;
    padding: 20px;
    z-index: 2;
    svg {
      font-size: 16px;
    }
  }
}
@media only screen and (min-width: 500px) {
  .form-subsection.card-form {
    input {
      font-size: 12px;
    }
  }
  .form-row.existing-card {
    .card-wrapper {
      font-size: 12px;
    }
    .delete-card {
      font-size: 12px;
      width: 150px;
      svg {
        font-size: 12px;
      }
    }
  }
}
