@import './index.scss';
.delivery-window {
  height: auto;
  position: relative;
  div.delivery-window-current {
    // @extend .action-underline;
    // height: 26px;
    // line-height: 26px;
    margin-bottom: 10px;
    padding: 0px;
    svg {
      margin: 0px 5px 0px 0px;
    }
    span {
      display: inline-block;
      line-height: 24px;
    }
  }
  ul.delivery-window-options {
    @extend .card;
    z-index: 10;
    position: absolute;
    top: 27px;
    width: 200px;
    right: 0px;
    padding: 20px 10px 20px 30px;
    li.delivery-window-option {
      margin-bottom: 20px;
      button.delivery-window-select {
        height: 50px;
        width: 100%;
        position: relative;
        @extend .flex-by-column-left;
        padding-right: 30px;
        span {
          text-align: left;
          @extend .text-12-300;
          line-height: 1.5;
          opacity: 0.7;
        }
        svg {
          display: none;
          position: absolute;
          font-size: 16px;
          right: 20px;
          top: 16px;
          margin-left: 20px;
        }
      }
      button.delivery-window-select.selected {
        span {
          font-weight: 500;
          opacity: 1;
        }
        svg {
          display: inline-block;
        }
      }
    }
    .delivery-window-option:last-child {
      margin-bottom: 0px;
    }
  }
}

.receipt {
  label {
    text-transform: capitalize;
  }
  .receipt-row {
    @extend .flex-by-row-between;
  }
}
// .order-by:before {
//   content: "";
//   width: 10px;
//   height: 10px;
//   background: green;
//   border-radius: 50%;
//   position: absolute;
//   left: -8px;
//   top: 6px;
//   @extend .blink;
// }

.delivery-window-in-receipt {
  @extend .flex-by-row-right;
  width: 100%;
  position: relative;
  z-index: 0;
  background-color: transparent;
}

.meal-select-wrapper {
  //display: none; // turn off for meal toggle
  @extend .flex-by-row-centered;
  // background: var(--background-beige);
  // margin-bottom: 8px;
  // width: 100%;
  // padding: 0px 40px;
  // height: 30px;
  position: relative;
  .meal-date {
    left: -100px;
  }
  // .meal-date::before {
  //   content: "";
  //   width: 14px;
  //   height: 4px;
  //   border-left: 3px solid black;
  //   border-right: 3px solid black;
  //   top: -4px;
  //   left: 30px;
  //   position: absolute;
  // }
  button:disabled {
    opacity: 0.35;
    pointer-events: none;
  }
  button {
    height: 26px;
    // width: 20px;
    // border-radius: 50%;
    // border: 1px solid var(--border-grey);
    // color: var(--border-grey);
    margin: 3px;
    color: black;
    // height: 25px;
    @extend .flex-by-row-centered;
    @extend .text-13-300;
    letter-spacing: 1.2px;
    font-weight: 300;
    border-bottom: 2px solid transparent;
    // color: var(--border-grey);
    text-transform: uppercase;
    // opacity: 0.4;
    padding: 0px;
    margin: 0px;
    margin-left: 20px;
  }
  button.selected-meal {
    // background-color: black;
    color: black;
    border-bottom: 2px solid black;
    font-weight: 500;
    // opacity: 1;
  }
  button:first-of-type {
    margin-left: 0;
  }
}

.delivery-window-banner {
  // display: block;
  @extend .subnav;
  position: relative;
  box-sizing: border-box;
  padding: 10px;
  background: var(--background-beige);
  border-bottom: 1px solid var(--border-grey);
  // padding-right: 140px;
  // background-color: white;
  @extend .flex-by-column-centered;
  // align-items: flex-start;
  // border-top: 1px solid var(--border-grey);
  margin: 0px;
  z-index: 4;
  top: 0px;
  // top: calc(var(--nav-height) + var(--announcement-height-short));

  border-bottom: none;
  // position: relative;
  // top: 0px;
  // left: 0px;
  .delivery-date-wrapper {
    @extend .text-10-300;
    // font-weight: 500;
  }
  .delivery-time-wrapper {
    @extend .flex-by-row-centered;
    width: 100%;
    height: 20px;
    margin-top: 4px;

    // font-weight: 400;
    // display: none;
    .order-by {
      position: relative;
      @extend .text-11-300;
      font-size: 9px;
      letter-spacing: 1px;
      margin-right: 3px;
      // padding-left: 7px;
      text-transform: capitalize;

      b {
        font-weight: 300;
      }
    }
    // background: white;
    .delivery-window {
      div.delivery-window-current {
        // height: var(--subnav-height);
        border: 0px solid white;
        margin: 0px;
        padding: 0px;

        @extend .flex-by-row-centered;
        span {
          @extend .text-11-300;
          line-height: 20px;
          // font-weight: 500;
          @extend .text-11-300;
          font-size: 9px;
          letter-spacing: 1px;
        }
        // height: 20px;

        // font-weight: 400;
        // margin-left: 3px;
        // padding-right: 10px;
        // color: red;
        text-transform: uppercase;
      }
      ul.delivery-window-options {
        right: 0px;
        top: calc(var(--subnav-height) / 2);
        li.delivery-window-option {
          button.delivery-window-select {
            span {
              @extend .text-12-300;
            }
          }
        }
      }
    }
  }
  .my-orders {
    position: fixed;
    // top: calc(var(--nav-height) + var(--announcement-height-short) + 16px);
    top: calc(var(--nav-height) + 16px);
    right: 18px;
    @extend .action-grey;
    height: 36px;
    span {
      font-size: 11px;
      letter-spacing: 0.825px;
    }
  }
}
.delivery-window-banner.show-order-confirmation {
  box-shadow: none;
}
.delivery-window-banner.is-anonymous {
  padding-right: 10px;
}
.delivery-window-banner {
  // top: var(--nav-height);
  top: 0px;
}
.delivery-window-banner.has-referral-code {
  box-shadow: none;
}

.meal-date {
  position: absolute;
  left: -130px;
  top: 8px;
  // border-radius: 5px;
  width: 50px;
  height: 50px;
  @extend .flex-by-column-centered;
  div {
    width: 50px;
    @extend .flex-by-row-centered;
  }
  .day-of-week {
    height: 20px;
    background-color: black;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    @extend .flex-by-row-centered;
    span {
      @extend .text-10-300;
      color: white;
      line-height: 15px;
      text-transform: capitalize;
      font-weight: 400;
    }
  }
  .date {
    @extend .flex-by-row-centered;
    height: 30px;
    border: 1px solid black;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    box-sizing: border-box;
    span {
      // padding: 2px 5px;
      @extend .text-title;
      font-size: 13px;
      display: inline-block;
      line-height: 25px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .delivery-window-banner {
    .my-orders {
      right: 12px;
      padding: 10px;
    }

    .meal-select-wrapper {
      .meal-date {
        left: -80px;
      }
    }
    .delivery-time-wrapper {
      padding-left: 15px;
      .order-by,
      .delivery-window {
        font-size: 9px;
        height: auto;
        white-space: nowrap;
        .delivery-window-current {
          height: 20px;
          padding: 0px;
          display: flex;
          justify-content: center;
          align-items: center;
          span {
            font-size: 9px;
            letter-spacing: 0.2px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .delivery-window-banner {
    flex-direction: row;
    justify-content: flex-start;
    padding-left: 0px;
    top: var(--nav-height);
    box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
    position: fixed;
    // border-bottom: 1px solid var(--border-grey);
    .my-orders {
      display: none;
    }
    .meal-select-wrapper {
      width: 20%;
      justify-content: flex-end;
      position: relative;
      box-sizing: border-box;
      .meal-date {
        top: 0px;
        position: relative;
        left: auto;
        border-radius: 0px;
        margin-right: 10%;
      }
    }
    .delivery-time-wrapper {
      width: auto;
      box-sizing: border-box;
      justify-content: flex-start;
      background: transparent;
      margin-top: 0px;
      padding-left: 20px;
      padding-bottom: 3px;

      .order-by {
        font-size: 12px;
      }
      .delivery-window {
        .delivery-window-current {
          span {
            font-size: 12px !important;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1000px) {
  .delivery-window-banner {
    // justify-content: center;
    .meal-select-wrapper {
      width: 35%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .delivery-window-banner {
    justify-content: center;
    .meal-select-wrapper {
      width: auto;
    }
  }
}
