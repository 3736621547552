@import "./index.scss";
.order-details {
  padding-top: 20px;
  width: 100%;
  // max-width: 500px;
  margin: 0px;
  @extend .flex-by-column-top;
  .meal-type {
    @extend .flex-by-row-centered;
    @extend .card;
    width: 100%;
    height: var(--nav-height);
    position: fixed;
    margin: 0px;
    top: 0px;
    left: 0px;
    z-index: 1;
    .meal-date {
      top: 12px;
      // left: auto;
      // right: calc(50% + 70px);
      left: 20px;
      position: absolute;
    }
    .order-type {
      @extend .text-title-16;
      // width: 100px;
      text-align: center;
      text-transform: capitalize;
      svg {
        margin-right: 8px;
      }
    }
  }
  .edit-delivery {
    // margin: 20px 0px 20px 0px;
    margin-bottom: 20px;
    @extend .flex-by-row-centered;
    flex-wrap: wrap;
    align-items: stretch;
    background-color: transparent;
    button {
      @extend .action-transparent;
      padding: 10px 20px;
      margin: 10px 15px;
      width: 220px;
      height: 40px;
      @extend .flex-by-row-centered;
      position: relative;
      box-sizing: border-box;
      // align-self: stretch;
      // flex-grow: 1;
      background-color: white;
      span {
        text-align: center;
        display: inline-block;
        // width: 100%;
      }
      svg {
        // margin-left: 10px;
        position: absolute;
        right: 15px;
      }
    }
  }
  .receipt {
    @extend .card;
    @extend .flex-by-column-centered;
    padding-top: 20px;
    width: 100%;
    .receipt-body {
      width: 100%;
      justify-content: flex-start;
      flex-grow: 1;
      align-self: stretch;
      // padding-top÷: 20px;
      .receipt-section {
        flex-grow: 0;
        .receipt-row {
          width: 100%;
        }
        .receipt-row.window {
          justify-content: flex-end;
        }
      }
      .receipt-section.order-highlight {
        background-color: transparent;
        margin: 0px;
      }
    }
  }
}
.delivery-image {
  width: 100%;
  img {
    width: 100%;
    height: auto;
  }
}
.order-details.lunch {
  .receipt.content {
    margin-bottom: 20px;
    padding: 20px;
    .receipt-body {
      border: 1px solid var(--border-grey); //var(--lunch-color);
    }
  }
}

.order-details.dinner {
  .receipt.content {
    // max-width: 500px;
    .receipt-body {
      border: 2px solid var(--dinner-color);
    }
  }
}

.order-details.earlyDinner {
  .receipt.content {
    // max-width: 500px;
    .receipt-body {
      border: 2px solid var(--early-dinner-color);
    }
  }
}

@media only screen and (min-width: 800px) {
  .order-details {
    .meal-type {
      .meal-date {
        left: calc(50vw - 230px);
      }
      .close-button {
        right: calc(50vw - 250px);
      }
    }
    .receipt {
      .receipt-body {
        align-items: center;
        align-self: center;
        justify-content: flex-start;
        height: auto;
        .receipt-section {
          max-width: 500px;
          align-self: center;
          flex-grow: 0;
        }
      }
    }
  }
}
