@import './index.scss';

.vegetarian-icon {
  position: absolute;
  left: 15px;
  top: 15px;
  padding: 15px;
  background: #99d41a;
  color: white;
  border-radius: 50%;
  z-index: 1;
}
