@import '../../../index.scss';
.dish {
  @extend .flex-by-column-centered;
  position: relative;
  padding: 20px 40px 10px 40px;
  flex-grow: 1;

  .dish-info {
    @extend .flex-by-column-centered;
    font-family: 'Theano Didot';
    font-size: 16px;
    line-height: 30px;
    letter-spacing: 0.6px;
    text-transform: capitalize;
    position: relative;
    margin-top: 32px;
    label {
      text-transform: uppercase;
      font-weight: 500;
      font-family: 'Montserrat', sans-serif;
      line-height: 1.6;
      letter-spacing: 2.6px;
      font-size: 15px;
      position: relative;
      color: rgb(75, 72, 72);
      margin-bottom: 8px;
    }
    label::before,
    label::after {
      content: '';
      position: absolute;
      top: 12px;
      display: block;
      width: 20px;
      height: 1px;
      background-color: rgb(219, 217, 217);
    }
    label::before {
      left: -30px;
    }
    label::after {
      left: calc(100% + 10px);
    }
    span {
      text-align: center;
    }
  }
  .dish-info:first-child {
    margin-top: 0px;
  }
  // .restaurant-name {
  //   text-align: center;
  //   font-family: "Theano Didot";
  //   font-size: 16px;
  //   line-height: 30px;
  //   letter-spacing: 0.6px;
  //   text-transform: capitalize;
  //   position: relative;
  //   // display: block;
  //   margin-top: 32px;
  // }
  // .restaurant-name::before,
  // .restaurant-name::after {
  //   content: "";
  //   position: absolute;
  //   top: 14px;
  //   display: block;
  //   width: 20px;
  //   height: 1px;
  //   background-color: rgb(219, 217, 217);
  // }
  // .restaurant-name::before {
  //   left: -30px;
  // }
  // .restaurant-name::after {
  //   left: calc(100% + 10px);
  // }
  // .dish-name {
  //   padding: 0;
  //   text-align: center;
  //   text-transform: uppercase;
  //   font-weight: 500;
  //   font-family: "Montserrat", sans-serif;
  //   line-height: 1.6;
  //   letter-spacing: 2.6px;
  //   font-size: 17px;
  // }

  .bookmark-button {
    position: absolute;
    bottom: 16px;
    right: 16px;
    svg {
      color: grey;
      font-size: 16px;
    }
  }
}

@media only screen and (min-width: 500px) {
  .dish {
    // width: 470px;
    // height: 300px;
    // margin-top: 15px;
  }
}
