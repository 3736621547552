@import './index.scss';
.restaurant-meals {
  width: 100%;
  @extend .flex-by-row-left;
  flex-wrap: wrap;
  .restaurant-meal {
    @extend .card;
    margin: 10px;
    padding: 20px;
    align-self: stretch;
    max-width: 500px;
    .meal-time {
      @extend .text-title;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    .meal-dishes {
      @extend .flex-by-column-centered;
      .meal-dish {
        @extend .flex-by-column-stretch;
        width: 100%;
        margin-bottom: 20px;
        .dish-content {
          @extend .flex-by-row-between;
          width: 100%;
          //   align-items: flex-start;
          .dish-name {
            @extend .overflow-ellipsis;
          }
          .dish-quantity {
            margin-left: 30px;
          }
        }
        .dish-special {
          width: 100%;

          .dish-comment {
            width: 100%;
            @extend .flex-by-row-between;
            align-items: flex-start;
            margin: 20px 0px;
            @extend .text-12-300;
            color: var(--text-grey);
            line-height: 1;
            .comment {
              //   @extend .text-12-300;
            }
            .quantity {
              margin-left: 20px;
            }
          }
        }
      }
    }
  }
}
