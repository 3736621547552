@import "./index.scss";
.checkout-form {
  width: 100%;
  // padding: 20px 0px;
  padding-top: var(--subnav-height);
  @extend .flex-by-column-centered;
  .list {
    padding-top: 0px;
    .receipt-banner {
      height: var(--subnav-height);
      @extend .subnav;
      padding: 0px 15px;
      // height: var(--title-height);
      .receipt-row {
        display: none;
      }
      .receipt-row.banner-title {
        // background-color: var(--background-beige);
        // padding: 3px 20px;
        @extend .text-14-300;
        // margin-top: 5px;
        width: calc(100% - 40px);
        @extend .flex-by-row-right;
        label {
          margin-right: 10px;
          text-transform: capitalize;
        }
        span.highlight {
          font-weight: 500 !important;
          text-transform: capitalize;
        }
      }
    }
    .list-title {
      @extend .flex-by-column-centered;
      box-shadow: none;
    }
    .list-title.cart-title {
      > span {
        position: absolute;
        top: 10px;
        font-size: 15px;
      }
    }

    // .delivery-info {
    //   width: calc(90% - 40px);
    //   margin-bottom: 0px;
    // }

    .paypal-button-container {
      margin-top: 10px;
      width: calc(100% - 40px);
    }
    .receipt {
      @extend .card;
      background: white;
      width: 100%;
      padding: 20px;
      margin: 20px 0px 20px 0px;
      max-width: 500px;
      .receipt-section {
        .receipt-row {
          font-size: 16px;
        }
      }
    }
    .checkout {
      @extend .flex-by-column-top;
      width: 100%;
      // margin-top: 80px;
      // min-height: calc(100vh - var(--nav-height));
      position: relative;
      z-index: 0;
      // @extend .card;
      // .checkout-total {
      //   margin-bottom: 20px;
      // }
      .form-section {
        width: calc(100% - 40px);
        input[name="phone"] {
          letter-spacing: 1.5px;
        }
        .form-section-title {
          line-height: 2;
          margin-bottom: 10px;
        }
        margin: 20px 0px;
        .form-row {
          position: relative;
          margin-bottom: 15px;
          .form-field-wrapper {
            padding: 0px;
            label: {
              text-transform: capitalize;
              margin-right: 10px;
            }
          }

          .card-icon {
            position: absolute;
            right: 6px;
            top: 6px;
            font-size: 24px;
          }

          .error-message {
            text-transform: none;
          }
        }
      }
      .form-section:first-child {
        margin-top: 20px;
      }
      .form-section:last-child {
        margin-bottom: 0px;
      }
      .form-section.save-card {
        .form-row {
          justify-content: flex-start;
          button.save-card {
            font-size: 12px;
          }
        }
      }
    }
  }
  .place-order {
    @extend .action-black;
    margin-top: 30px;
    text-transform: uppercase;
  }
  .place-order:disabled {
    opacity: 0.25;
  }
  .powered-by-paypal {
    margin-top: 10px;
    @extend .flex-by-row-centered;
    span {
      @extend .text-11-300;
      margin-right: 10px;
    }
  }
}

.checkout-auth {
  .checkout-auth-body {
    @extend .flex-by-column-centered;
    min-height: calc(100vh - var(--nav-height));
    width: 100%;
  }
}
.payment-method {
  @extend .flex-by-row-centered;
  // position: fixed;
  // top: calc(var(--nav-height) + var(--title-height));
  // margin: 20px;
  width: 100%;
  height: var(--subnav-height);
  margin-top: 0px;
  // border-bottom: 1px solid rgb(217, 217, 217);
  // background-color: white;
  // z-index: 2;
  button {
    // @extend .action-transparent;
    // min-width: calc(50vw - 30px);
    @extend .text-13-400;
    height: 40px;
    box-sizing: border-box;
    width: auto;
    // padding-left: 20px;
    position: relative;
    @extend .flex-by-row-centered;
    // margin-bottom: 10px;
    // border: none;
    box-sizing: border-box;
    padding: 0px;
    padding-left: 18px;
    padding-right: 2px;
    // padding-bottom: 7px;

    span {
      // position: absolute;
      // left: 40px;
      display: inline-block;
    }
    svg {
      position: absolute;
      left: 0px;
      top: 13px;
      // display: none;
      // border: 1px solid black;
    }
    border-bottom: 2px solid black;
  }
  button:first-child {
    margin-right: 25px;
  }

  button:last-child {
    margin-right: 0px;
  }
}
.payment-method.group-order {
  @extend .flex-by-column-centered;
  height: auto;
  button {
    margin: 10px 0px;
  }
}
.checkout {
  .free-order-title-wrapper {
    @extend .flex-by-column-centered;
    margin: 20px;
    margin-bottom: 10px;
    padding: 2px;
    align-self: stretch;
    border: 1px solid black;
    .free-order-title {
      @extend .flex-by-column-centered;
      margin: 0px;
      padding: 25px 0px;
      border: 1px solid black;
      align-self: stretch;
      .icon-wrapper {
        @extend .with-curls;
        margin-bottom: 10px;
        svg {
          font-size: 25px;
        }
      }

      span {
        @extend .text-title;
        font-size: 18px;
        text-transform: none;
        // @extend .text-13-500;
        // text-transform: uppercase;
        letter-spacing: 1px;
        display: inline-block;
        width: 100%;
        // line-height: var(--subnav-height);

        text-align: center;
      }
    }
  }
}
@media only screen and (min-width: 500px) {
  .checkout-form {
    .list {
      .receipt-banner {
        left: 0px;
        padding: 0px calc(50vw - 240px);
        .receipt-row.banner-title {
          justify-content: center;
        }
      }
      .receipt {
        .receipt-section {
          .receipt-row {
            font-size: 12px;
          }
          .receipt-row.total {
            justify-content: center;
          }
        }
      }
      .checkout {
        .form-section.save-card {
          .form-row {
            justify-content: flex-start;
            button.save-card {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  .checkout-form {
    .list {
      .receipt-banner {
        .receipt-row.banner-title {
          font-size: 12px;
          line-height: 24px;
          label {
            margin-right: 5px;
          }
          span {
            font-size: 13px;
            font-weight: 500;
          }
        }
      }
    }
  }
}

button.select-payment-method {
  color: var(--black);
  border-color: var(--black);
  @extend .text-13-400;
  font-weight: 500;
  svg {
    margin-right: 5px;
  }
}
button.select-payment-method.unselected-method {
  color: var(--border-grey);
  border-color: transparent;
  font-weight: 400;
  svg {
    color: transparent;
    // border-color: var(--border-grey);
  }
}

button.save-card {
  padding: 0px;
  @extend .flex-by-row-left;
  @extend .text-12-300;
  font-size: 16px;
  font-weight: 400;
  svg {
    border: 1px solid black;
    margin: 0px 10px 0px 0px;
    border-radius: 5px;
    padding: 2px !important;
    width: 20px !important;
    font-size: 30px !important;
    height: 20px;
    flex-shrink: 0;
    box-sizing: border-box;
  }
}
.form-section.save-card {
  margin-top: 10px;
  margin-bottom: 20px;
  .form-row {
    justify-content: flex-start;
  }
}

button.save-card.unchecked {
  svg {
    color: transparent;
  }
}

.form-field-wrapper {
  padding: 0px;
  label {
    @extend .text-12-300;
    font-size: 14px;
    font-weight: 500;
    text-transform: capitalize !important;
    margin-right: 10px;
  }
}
.card-title {
  @extend .flex-by-column-centered;
  align-items: flex-start;
}
.form-section.new-card-section {
  margin-top: 20px;
}
.new-card-form {
  margin-top: 0px;
  background-color: var(--background-grey);
  padding: 0px 0px 20px 0px;
  border-radius: 5px;
  align-items: flex-start;
  // padding-bottom: 30px;
  margin-bottom: 20px;
}
.stripe-badge {
  // margin-bottom: 30px;
  background-color: var(--background-grey);
  padding: 10px;
  border-radius: 5px;
  img {
    width: 100%;
    // border: 1px solid black;
  }
}
