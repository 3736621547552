@import "./index.scss";
.list.routes {
  max-width: 100vw;
  padding-bottom: 100px;
  @extend .flex-by-column-left;
  .route {
    display: block;
    width: 100%;
    margin: 20px;
    @extend .text-12-300;
    .route-info {
      padding: 0px 20px;
      @extend .flex-by-column-left;
      label {
        margin-right: 10px;
      }
      div {
        margin-bottom: 5px;
      }
      .route-header {
        position: relative;
        // border: 1px solid var(--background-lightgrey);
        width: 300px;
        padding: 20px;
        @extend .card;
      }
      .driver,
      .restaurant,
      .date,
      .duration,
      .route-id,
      .payment-info {
        // @extend .text-13-400;
        // font-weight: 500;
        text-transform: capitalize;
        @extend .flex-by-row-left;
        svg {
          margin-right: 10px;
          font-size: 14px;
        }
      }
      .payment-info {
        text-transform: none;
      }

      .driver-payment-form {
        @extend .card;
        padding: 20px;
        margin-top: 20px;
        width: 300px;
        box-sizing: border-box;
        @extend .flex-by-column-centered;
        .form-title {
          @extend .text-title;
          font-size: 16px;
          margin: 20px;
        }
        .field {
          @extend .flex-by-column-left;
          align-self: stretch;
          input {
            margin: 0px;
            // padding-right: -20px;
            width: 100%;
          }
          margin-bottom: 20px;
        }
        button[type="submit"] {
          margin: 20px;
          @extend .action-black;
        }
      }

      .date {
        svg {
          font-size: 15px;
        }
      }
    }
    .route-orders {
      width: 100%;
      @extend .flex-by-row-top-left;
      flex-wrap: wrap;
      .order {
        @extend .card;
        margin: 20px;
        padding: 60px 20px 20px 20px;
        @extend .flex-by-column-left;
        justify-content: flex-start;
        width: 300px;
        align-self: stretch;
        position: relative;
        .stop {
          margin-bottom: 20px;
          width: 100%;
          text-align: center;
          position: absolute;
          top: 20px;
          left: 0px;
          //   @extend .with-curls;
        }
        .address-info {
          @extend .flex-by-column-left;
          // position: absolute;
          width: calc(100% - 40px);
          // top: 65px;
          // left: 20px;
          margin-bottom: 20px;
          position: relative;
          svg {
            margin-right: 10px;
          }
          .message {
            width: 100%;
            margin: 10px 0px;
            button,
            textarea {
              width: 100%;
              margin-right: 0px;
            }
          }
          .address-map {
            width: 100%;
            height: 200px;
            margin: 10px 0px;
            img {
              // size cover
              width: 100%;
              height: 200px;
            }
          }
          .delivery-image {
            width: 100%;
            img {
              width: 100%;
              height: 300px;
            }
          }
        }
        .analytics {
          width: 100%;
          .user-message {
            .message-time {
              svg {
                margin-right: 5px;
              }
            }
            .message-content {
              background-color: var(--background-beige);
              padding: 10px;
              border-radius: 5px;
              b {
                @extend .text-12-300;
                font-weight: 500;
              }
            }
            margin-bottom: 20px;
          }
          .delay {
            width: 100%;
            @extend .flex-by-row-between;
            align-items: flex-start;
            margin-top: 30px;
            .delay-reasons {
              @extend .flex-by-column-left;
              label {
                margin-right: 10px;
                text-transform: capitalize;
                line-height: 1;
              }
              span {
                display: inline-block;
                font-size: 10px;
                margin-top: 1px;
                color: var(--text-grey);
                // font-style: italic;
              }
            }
            .walk-time {
              line-height: 1;
            }
            .walk-time.extra-long {
              color: var(--background-coral);
              font-weight: 500;
            }
            .walk-time.negative-delay {
              color: var(--background-lightgreen);
              font-weight: 500;
            }
          }
        }
        .skip-stop {
          margin: 5px;
        }
      }
    }
  }
}

.drink {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: green;
  color: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  @extend .flex-by-row-centered;
  font-size: 20px;
}
