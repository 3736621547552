@import '../../../index.scss';

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}

.geosuggest {
  position: relative;
  text-align: left;
  width: 100%;
  border-bottom: 1px solid black;
  margin: 0px 0px 20px 0px;
  .geosuggest__input-wrapper {
    input {
      width: 100%;
      font-family: 'Montserrat', sans-serif;
      font-size: 16px;
      letter-spacing: 0.74px;
      margin: 0px;
      padding: 10px 0px;
      // height: 44px;
      // line-height: 44px;
      box-sizing: border-box;
      border: none;
    }
  }
  .geosuggest__suggests-wrapper {
    width: 100%;
    z-index: 1;
    position: absolute;
    left: 0px;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    box-shadow: 4px 14px 14px 0px rgba(28, 29, 33, 0.12);

    ul.geosuggest__suggests.geosuggest__suggests--hidden {
      display: none;
    }
    ul {
      @extend .flex-by-column-centered;
      list-style-type: none;
      width: 100%;
      padding-inline-start: 0px;
      margin: 0px;
      li {
        // @extend .overflow-ellipsis;
        width: 100%;
        padding: 0px 8px;
        box-sizing: border-box;
        span {
          // @extend .text-12;
          height: 42px;
          line-height: 42px;
        }
      }
    }
  }
}

.quick-form-mobile {
  @extend .card-deep;
  @extend .flex-by-row-centered;
  width: 100vw;
  height: var(--quick-post-height);
  padding: 20px 20px 20px 12px;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 1;
  box-sizing: border-box;
  align-items: stretch;
  .avatar-wrapper {
    @extend .flex-by-row-left;
    flex-grow: 0;
    margin-right: 12px;
    .avatar {
      width: 34px;
      height: 34px;
      div {
        line-height: 34px;
        height: 34px;
        font-size: 16px;
      }
    }
  }
  .recommend-a-restaurant {
    @extend .flex-by-row-left;
    flex-grow: 1;
    text-align: left;
    padding-left: 10px;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: 1.2px;
    opacity: 0.5;
    background-color: rgba(228, 228, 228, 0.5);
  }
}

.quick-form-mobile.tasting {
  select {
    height: 35px;
  }
}

@media only screen and (min-width: 1300px) {
  .quick-post-mobile {
    // padding: 16px calc(50vw - 235px);
    // width: 500px;
    // right: calc(50vw - 250px);
    width: calc(50vw - 250px - 32px);
    right: 0px;
  }
}
