@import '../index.scss';

/* For actual receipt printing. Enlarge text */
.order.receipt-font {
  @extend .text-18-400;

  .order-name {
    .person-name {
      margin: 0px;
      @extend .text-title;
      text-align: left;
    }
  }
  .order-items {
    .item-wrapper {
      .item.without-quantity {
        label,
        .item-selections {
          font-size: 16px;
        }

        .item-selections {
          font-size: 16px;
        }

        .item-instructions {
          font-size: 16px;
        }
      }
    }
  }
}

.order {
  @extend .text-14-300;
  width: 100%;
  margin-bottom: 20px;

  .order-name {
    width: 100%;
    background-color: yellow;
    @extend .flex-by-column-between;
    padding: 10px;
    .person-name {
      // display: inline-block;
      // width: 100%;
      margin: 0px;
      @extend .text-title-16;
      font-weight: 500;
      text-align: left;
    }
    span.item-number {
      display: inline-block;
      width: 100%;
      text-align: center;
      text-transform: capitalize;
      @extend .text-12-400;
      svg {
        margin-right: 10px;
      }
    }
  }
  .order-items {
    width: 100%;
    padding: 20px;
    .item-wrapper {
      width: 100%;
      @extend .flex-by-column-centered;
      // padding: 10px 0px;
      position: relative;
      border-bottom: 1px dashed black;
      // margin-top: 10px;
      padding-bottom: 8px;
      margin: 10px 0px;
      .item-index {
        display: inline-block;
        width: 100%;
        position: relative;
        @extend .action-grey;
        @extend .text-12-400;
        text-transform: capitalize;
        text-align: center;
        .cancel-item {
          position: absolute;
          right: 20px;
          width: auto;
          height: 46px;
          top: 0px;
        }
      }
      .item:first-child {
        margin-top: 0px;
      }
      .item.without-quantity {
        // background-color: var(--background-grey);
        @extend .flex-by-column-left;
        white-space: normal;
        text-align: left;
        line-height: 2;
        width: 100%;
        // padding: 10px;

        .item-header {
          width: 100%;
          @extend .flex-by-row-between;
          align-items: flex-start;
          margin-bottom: 5px;
          span {
            display: inline-block;
            line-height: 1.5;
          }
          .item-name {
            text-transform: capitalize;
            font-weight: 500;
            margin-right: 20px;
            margin-bottom: 0px;
          }
          .item-price {
            text-align: right;
          }
        }
        // span {
        //   display: inline-block;
        // }
        label {
          text-transform: capitalize;
          font-weight: 500;
          margin-top: 5px;
          color: red;
        }
        label,
        .item-selections {
          font-size: 11px;
        }

        .item-selections {
          font-size: 11px;
          width: 100%;
          .selection-item-wrapper {
            width: 100%;
            @extend .flex-by-row-between;
          }
        }

        .item-instructions {
          // font-weight: 500;
          font-size: 11px;
          width: calc(100% - 10px);
          border: 1px solid red;
          padding: 0px 5px;
        }
      }
    }
    .item-wrapper:last-child {
      border: none;
      padding-bottom: 0px;
      // margin-bottom: 20px;
      margin-bottom: 0px;
    }
  }
  .action-wrapper {
    width: 100%;
    margin-top: 20px;
    @extend .flex-by-row-centered;
    .button-placeholder {
      @extend .action-black;
      background-color: white;
    }
    button {
      @extend .action-black;
      width: 100%;
      svg {
        margin-right: 10px;
      }
    }
  }
}
