@import './index.scss';
.menu-footer {
  position: fixed;
  left: 0px;
  bottom: 0px;
  z-index: 2;
  width: 100vw;
  padding: 20px;
  background-color: white;
  @extend .flex-by-row-between;
  @extend .card;
  button {
    width: calc(50vw - 30px);
    min-width: 0px;
    box-sizing: border-box;
    @extend .action-transparent;
  }
  button:last-child {
    background: black;
    color: white;
  }
}
