@import "./index.scss";
.list.order-form {
  width: 100%;
  max-width: 500px;
  margin: 0px;
  padding-top: 0px;
  @extend .flex-by-column-top;
  .list-title {
    .cart-thumbnail {
      position: fixed;
      right: 0px;
    }
    .edit-title {
      text-align: center;
      width: calc(100% - 2 * var(--nav-height));
      font-size: 18px;
    }
  }
  .order-date {
    position: fixed;
    height: var(--announcement-height-short);
    top: var(--nav-height);
    z-index: 5;
    width: 100%;
    background-color: var(--background-beige);
    box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
    @extend .flex-by-row-centered;
    span {
      @extend .text-12-400;
    }
  }
  .list-body {
    padding-top: 20px;
    .receipt {
      @extend .card;
      @extend .flex-by-column-centered;
      padding-top: 20px;
      width: calc(100% - 40px);
      .receipt-body {
        width: 100%;
        justify-content: flex-start;
        flex-grow: 1;
        align-self: stretch;

        .receipt-section {
          flex-grow: 0;
          .receipt-row {
            width: 100%;
          }
          .receipt-row.edit-delivery {
            margin-top: 50px;
            @extend .flex-by-row-centered;
            flex-wrap: wrap;
            button {
              margin: 10px 30px;
              @extend .action-transparent;
            }
            button.submit-changes {
              background-color: black;
              color: white;
            }
          }
        }
      }
    }
  }
  .list-actions {
    button {
      padding: 0px;
    }
  }
  .menu-two-dishes {
    padding-top: var(--announcement-height-short);
    width: 100%;
    .dish-choice-list-mobile {
      width: 100%;
      background-color: white;
      position: relative;
    }
    .dish-choice-list-desktop {
      background-color: white;
      position: relative;
    }
  }
}
.order-form.lunch {
  .receipt.content {
    // max-width: 500px;
    padding: 20px;
    .receipt-body {
      border: 2px solid var(--lunch-color);
    }
  }
}

.order-form.dinner {
  .receipt.content {
    .receipt-body {
      border: 2px solid var(--dinner-color);
    }
  }
}

.confirm-window {
  z-index: 100;
}

@media only screen and (min-width: 800px) {
  .list.order-form {
    max-width: 100vw;

    .list-title {
      .cart-thumbnail {
        right: calc(10% + 10px);
      }
      button.back-button {
        left: calc(10% + 10px);
      }

      .menu-two-dishes {
        justify-content: center;
        .dish-choice-list-mobile {
          display: none;
        }
        .dish-choice-list-desktop {
          display: flex;
        }
      }
    }
    .receipt {
      .receipt-body {
        align-items: center;
        align-self: center;
        justify-content: flex-start;
        height: auto;
        .receipt-section {
          max-width: 500px;
          align-self: center;
          flex-grow: 0;
        }
      }
    }
  }
}
