@import "./index.scss";
.checkout-banner {
  @extend .card;
  @extend .flex-by-column-centered;
  position: fixed;
  width: 100vw;
  // height: 150px;
  left: 0px;
  bottom: 0px;
  box-shadow: 0 0px 16px 8px rgba(28, 29, 33, 0.3);
  z-index: 101;
  background-color: var(--background-beige);
  // opacity: 0.95;

  .cart-length {
    flex-shrink: 0;
    display: inline-block;
    width: 22px;
    height: 22px;
    padding: 0px;
    color: white;
    font-size: 11px;
    line-height: 22px;
    text-align: center;
    border-radius: 50%;
    font-weight: 500;
    position: relative;
    text-align: center;
    // border: 1px solid red;
  }
  .cart-length.full.done {
    background-color: green;
  }
  // .cart-length.loading have dashed border and spinner
  .length-border {
    position: absolute;
    top: 0px;
    left: 0px;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    margin: 0px;
  }
  .length-border.loading {
    border: 1px dashed var(--text-grey);
    animation: spin 4s ease-in-out infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .order-confirmation {
    height: 40px;
    line-height: 40px;
    @extend .text-12-300;
    font-size: 12px;
    margin-top: 5px;
    b {
      text-transform: none;
      font-weight: 500;
      margin-right: 3px;

      .checkout-dish-name {
        display: inline-block;
        height: 24px;
        max-width: calc(100vw - 120px);
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      svg {
        margin: 0px 2px;
        font-size: 10px;
      }
    }
  }
  a {
    @extend .flex-by-row-centered;
    span {
      display: inline-block;
      width: 100%;
      text-align: center;
    }
  }
  .close-button {
    position: absolute;
    padding: 0px;
    height: 50px;
    width: 80px;
    top: 0px;
    right: 0px;
  }
  .cart-content {
    position: relative;
    @extend .flex-by-row-between;
    @extend .action-black;
    padding: 0px 20px;
    // width: calc(100vw - 160px);
    // max-width: 200px;

    height: 48px;
    // min-width: 100px;
    // width: 200px;
    svg {
      font-size: 18px;
    }
    span {
      margin: 0px 20px;
    }
  }
}
.checkout-banner.show.nomination-banner {
  justify-content: center;
  padding: 10px 20px;
  @extend .text-16-400;
  text-align: center;
  top: 0px;
  height: calc(2 * var(--nav-height));
  span {
    margin: 0px;
    height: auto;
  }
  b {
    letter-spacing: 1px;
    font-family: "Cardo", serif;
    font-size: 18px;
    font-weight: 500;
  }
  z-index: 201;
}
.checkout-banner.hide {
  bottom: calc(0px - var(--footer-height));
}

.checkout-banner.order-banner {
  top: calc(
    var(--nav-height) + var(--subnav-height) + var(--announcement-height-short)
  );
  bottom: auto;
  z-index: 5;
  box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 20%);
  height: 110px;
  padding-bottom: 20px;
  transition: top 1s, opacity 1s;
  z-index: 2;
  opacity: 1;
  border-top: 1px solid var(--border-grey);
}
.checkout-banner.order-banner.hide {
  // bottom: calc(100vh - var(--nav-height));
  // top: auto;
  top: calc(var(--nav-height) - 110px);
  opacity: 0;
}
@media only screen and (min-width: 800px) {
  .checkout-banner.order-banner.show {
    top: calc(var(--nav-height) + var(--subnav-height));
    border-top: 1px solid var(--border-grey);
  }
}
