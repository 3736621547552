@import '../../../index.scss';
.post {
  margin-bottom: 16px;
  position: relative;
  width: calc(100% - 32px);
  .recommender.statement {
    @extend .flex-by-row-left;
    padding: 16px 0px;
    height: 26px;
    box-sizing: content-box;

    span.recommends {
      line-height: 22px;
    }
  }
  .dish-wrapper {
    @extend .flex-by-column-left;
    @extend .card;
    padding: 16px 16px 32px 16px;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    .dish-image {
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 300px;
      background-position: center;
      background-size: cover;
      img {
        object-fit: cover;
        width: 100%;
        height: 300px;
      }
      .disclaimer-icon {
        width: 10px;
        height: 10px;
        position: absolute;
        right: 5px;
        top: 285px;
        z-index: 1;
        .i-icon {
          border-radius: 50%;
          width: 10px;
          height: 10px;
          display: block;
          text-align: center;
          font-size: 5px;
          font-style: italic;
          line-height: 10px;
          background: white;
          opacity: 0.5;
        }
        .from-yelp {
          @extend .card;
          visibility: hidden;
          font-size: 8px;
          padding: 0px 5px;
          border-radius: 5px;
          display: block;
          text-align: center;
          background-color: white;
          position: absolute;
          right: 0px;
          bottom: 15px;
          width: 100px;
          line-height: 30px;
          height: 30px;
          box-sizing: border-box;
        }
      }
    }

    .disclaimer-icon:hover {
      .from-yelp {
        visibility: visible;
      }
    }
    .section.recommender-name {
      @extend .flex-by-row-left;
      width: 100%;
      text-transform: capitalize;
      height: 24px;
      margin: 0px;
    }
    .dish-border {
      @extend .flex-by-column-centered;
      width: 100%;
      box-sizing: border-box;
      // border: 2px solid rgb(150, 146, 146);
      position: relative;
      align-items: stretch;
      .recommender.statement {
        @extend .flex-by-row-centered;
        width: 100%;
      }
      .post-actions {
        width: 100%;
        margin-bottom: 40px;
        @extend .flex-by-row-centered;
        a,
        button {
          font-family: 'Montserrat', sans-serif;
          font-size: 11px;
          font-weight: 500;
          letter-spacing: 1px;
          text-transform: capitalize;
          background-color: rgba(228, 228, 228, 0.6);
          padding: 10px 0px;
          margin: 0px;
          height: 40px;
          width: 90px;
          box-sizing: border-box;
          @extend .flex-by-row-centered;
          svg {
            margin-right: 10px;
          }
        }
        a.edit-post {
          margin-right: 28px;
        }
      }
      // .post-actions:nth-child(2) {
      //   margin-top: 36px;
      // }
    }
    .section {
      margin-top: 16px;
    }
    .section:first-child {
      margin-top: 0px;
    }
    .comments-section {
      @extend .flex-by-column-left;
      font-family: 'Montserrat';
      padding: 0px;
      font-size: 13px;
      font-weight: 300;
      letter-spacing: 0.6px;
      width: 100%;
      .comment-entry {
        margin: 0px 8px 8px 8px;
        line-height: 1.8;
        .author {
          font-weight: 600;
          text-transform: capitalize;
          margin-right: 5px;
        }
      }
      .comment-entry:last-child {
        margin-bottom: 0px;
      }
      a.see-all-reviews {
        font-size: 11px;
        border-bottom: 1px solid black;
      }
    }
    .comments-section:last-child {
      margin-bottom: 10px;
    }
    .buttons-section {
      @extend .flex-by-column-centered;
      width: 100%;
      position: relative;
      padding: 16px 0px;
      .bookmark-button {
        @extend .flex-by-row-centered;
        font-family: 'Montserrat', sans-serif;
        font-size: 11px;
        font-weight: 500;
        letter-spacing: 1px;
        width: 200px;
        height: 40px;
        background-color: rgba(228, 228, 228, 0.5);
        margin: 0px;
        margin-bottom: 16px;
        padding: 0px;
        svg {
          margin-right: 10px;
        }
      }
      .bookmark-button:last-child {
        margin-bottom: 0px;
      }
    }
    div.section:last-of-type {
      margin-bottom: 0px;
    }
    // .buttons-section:last-child {
    //   margin-bottom: 0px;
    // }
  }

  .dish-wrapper.has-image {
    padding-top: 300px;
    img {
    }
  }
  .add-comment {
    @extend .flex-by-row-centered;
    align-items: stretch;
    width: 100%;
    textarea {
      margin-top: 16px;
      padding: 6px;
      width: 100%;
      border: none;
      background-color: #eee;
    }
  }
}

.post:first-of-type {
  margin-top: 16px;
}

.counts-section {
  @extend .flex-by-row-left;
  width: 100%;
  padding: 0px 8px;
  box-sizing: border-box;
  .counts {
    margin: 0px 5px 0px 0px;
    font-size: 13px;
    font-weight: 300;
    letter-spacing: 0.6px;
    padding: 0px;
    span {
      font-weight: 500;
    }
    svg {
      font-size: 14px;
      margin-left: 5px;
    }
  }
  .counts:last-child {
    margin-right: 0px;
  }
  .counts.likes {
    position: relative;
    .recommender-list {
      @extend .flex-by-column-left;
      @extend .card-deep;
      width: auto;
      background-color: white;
      z-index: 1;
      padding: 20px 28px 20px 20px;
      position: absolute;
      top: 40px;
      left: 5px;
      max-height: 100vh;
      .profile-link {
        margin-bottom: 15px;
        width: auto;
      }
      .profile-link:last-child {
        margin-bottom: 0px;
      }
    }
    .recommender-list::before {
      content: '';
      width: 20px;
      height: 20px;
      transform: rotate(45deg);
      background-color: white;
      z-index: 2;
      position: absolute;
      top: -6px;
      left: 15px;
    }
  }
  .counts.bookmark-by-icon {
    position: absolute;
    display: none;
    right: 16px;
  }
}
.time-monitor {
  @extend .flex-by-column-left;
  width: 100%;
  margin: 20px 0px;
  .stamp {
    width: 100%;
    .note {
      display: inline-block;
      font-weight: 500;
      width: 50%;
      text-align: right;
      margin-right: 5%;
    }
    .time {
      display: inline-block;
      width: 40%;
    }
  }
}
@media only screen and (min-width: 500px) {
  .post {
    width: 500px;
    .dish-wrapper {
      .buttons-section {
        flex-direction: row;
        .bookmark-button:first-child {
          margin-bottom: 0px;
          margin-right: 20px;
        }
      }
    }
  }
}
