@import "../../index.scss";
@import "../tasting_menu/components/index.scss";
.auth-option {
  @extend .flex-by-column-centered;
  width: 300px;
  height: 45px;
  margin-bottom: 20px;
  border: 1px solid black;
  box-sizing: border-box;
  position: relative;
  border-radius: 5px;
  svg {
    margin-right: 12px;
    font-size: 15px;
    position: absolute;
    left: 40px;
  }
  span,
  button,
  a {
    @extend .flex-by-row-centered;
    width: 100%;
    font-family: "Montserrat", sans-serif;
    font-size: 13px;
    font-weight: 500;
    height: 50px;
    padding: 0px;
    margin: 0px;
    letter-spacing: 0.7px;
    text-transform: capitalize;
  }
}

.auth-option.by-email {
  svg {
    left: 55px;
  }
}
.auth-option.continue-with-google,
.auth-option.continue-with-facebook {
  @extend .flex-by-row-centered;
  @extend .text-12-300;

  width: 100%;
  /* text-transform: uppercase; */
  font-weight: 500;
  /* font-size: 15px; */
  padding: 0px 30px;
  height: 80px;
  /* line-height: 60px; */
  /* flex-grow: 1; */
  // margin: 0px 20px 10px 20px;
  /* max-width: 300px; */
  // width: calc(100% - 40px);
  // align-self: stretch;
  text-align: center;
  color: black;
  opacity: 0.8;
  // background-color: white;
  position: relative;
  button {
    span {
      font-size: 18px;
    }
  }
  @media only screen and (min-width: 800px) {
    // width: 200px;
    align-self: auto;
    // margin: 0
  }
}
.auth-option.continue-with-google {
  background-color: #4285f4;
  border: none;
  padding: 0px 15px;
  button {
    justify-content: flex-start;
    align-items: flex-start;
    width: auto;
    span {
      color: white;
      // text-align: left;
      width: auto;
      font-weight: 600;
    }
  }
}

.get-email {
  @extend .flex-by-column-centered;
  input {
    width: 100%;
    margin: 0px 0px 10px 0px;
  }
  .continue {
    button {
      border: none;
      background-color: black;
      color: white;
      height: 100%;
    }
  }
}
.form-row.verification-code {
  border: none;
  // width: 100%;
  // align-items: stretch;
  @extend .flex-by-column-centered;
  align-items: center;
  .verification-input {
    width: 100% !important;
    @extend .flex-by-row-centered;
    input {
      margin-right: 0px;
    }
  }
  button {
    align-self: stretch;
    @extend .action-black;
    background-color: rgb(255, 191, 0);
    color: black;
    height: 60px;
    // width: calc(100% - 40px);
    width: 100%;
    flex-grow: 1;
    border: none;
    font-size: 15px;
  }
}
.form-row.password-row.auth {
  position: relative;

  button {
    margin: 0px 15px;
    padding: 0px;
    /* font-size: 14px; */
    font-weight: 400;
    position: absolute;
    top: 20px;
    right: 0px;
    /* background-color: red; */
    height: 20px;
    line-height: 20px;
    border-bottom: 1px solid black;
  }
}
.logo-sign-in {
  display: block;
  background-image: url("../../logo_beige.png");
  height: calc(var(--nav-height) + 20px);
  width: 100%;
  z-index: 1;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: calc(var(--nav-height) - 20px);
  padding-top: calc(var(--nav-height) - 0px);
  text-align: center;
  @extend .text-12-300;
  line-height: 1.4;
  // text-transform: uppercase;
  font-weight: 500;
  // text-align: right;
  // @extend .text-title;
  // font-size: 14px;
  // text-transform: lowercase;
  @media only screen and (max-height: 600px) and (max-width: 400px) {
    height: 50px;
    margin-bottom: 60px;
    padding-top: 60px;
    width: 50%;
    text-align: left;
    padding-left: 20px;
    // font-size: 10px;
  }

  @media only screen and (max-height: 700px) and (max-width: 400px) {
    height: 40px;
    margin-bottom: 50px;
    padding-top: 60px;
    // padding-left: 20px;
    // font-size: 10px;
  }
}
.new-user {
  // width: 300px;

  width: calc(100% - 20px);
  margin-top: 20px;
  z-index: 1;
  @extend .flex-by-column-centered;
  max-width: 400px;
  h2 {
    font-weight: 400;
    font-family: "Theano Didot", serif;
    line-height: 1.4;
    letter-spacing: 1px;
    font-size: 22px;
    text-transform: capitalize;
  }
  .info-section-title {
    @extend .text-13-400;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 14px;
    margin: 30px 0px 3px 0px;
    text-align: left;
    width: 100%;
  }
  .form-row {
    // width: calc(100% - 50px);
    width: 100%;
    margin-bottom: 8px;
    height: 45px;
    // border: 2px solid var(--border-grey);
    // background-color: rgb(245, 245, 245);
    @extend .flex-by-row-stretch;
    & > input,
    & > input::placeholder {
      // background-color: rgb(245, 245, 245);
      margin: 0px;
      font-size: 16px;
      padding-left: 5px;
      border-bottom: none;

      // background-color: transparent;
      // border: 1px solid rgb(245, 245, 245);
    }
    & > input:-webkit-autofill,
    & > input:-webkit-autofill:hover,
    & > input:-webkit-autofill:focus,
    & > input:-webkit-autofill:active {
      background-color: rgb(245, 245, 245);
    }
    & > input:nth-child(2) {
      margin-left: 15px;
    }
  }

  .popup-container {
    top: 0px;
    .get-verification-code {
      height: 100vh;
      width: 100vw;
      box-sizing: border-box;
      .form-row {
        .form-field {
          input {
            padding-left: 10px;
            border-bottom: none;
          }
        }
      }
      .form-row.status,
      .form-row.verification-code {
        border: none;
        width: 100%;
      }
    }
    .form-popup {
      background-color: white;
      z-index: 100;
      @extend .flex-by-column-centered;
      padding: 50px 30px;
      position: relative;
      .error-message {
        position: absolute;
        top: calc(var(--nav-height) + 20px);
        width: calc(100% - 60px);
      }
      .back-button {
        background-color: transparent;
        color: black;
      }
      .form-row {
        width: 100%;
        .PhoneInput {
          width: 100%;
          @extend .flex-by-row-centered;
          input {
            // width: 100%;
            margin: 0px;
            @extend .text-16-400;
          }
        }
        .PhoneInputCountry {
          display: none;
        }
        input[type="tel"] {
          margin: 0px;
        }
      }
    }
  }

  button[type="submit"],
  .continue-as-guest {
    // width: 100%;
    // background-color: black;
    // color: white;
    // margin-top: 20px;
    @extend .action-black;
    background-color: rgb(255, 191, 0);
    color: black;
    height: 60px;
    width: calc(100% - 40px);
    border: none;
    font-size: 15px;
    // width: calc(100% - 50px);
  }
  .send-code {
    margin-top: 0px;
    width: calc(100% - 40px);
  }
  button[type="submit"].main-action.sign-in {
    background-color: #dbd9d9;
  }
  // button[type="submit"] {
  //   margin-top: 20px;
  // }
}
.form-row.auth {
  // height: 60px;
  @extend .flex-by-column-centered;
  padding: 0px;
  margin: 0px 20px 10px 20px;
  width: calc(100% - 40px);
  // align-self: stretch;
  box-sizing: content-box;
  border: none;
  input {
    border: 1px solid var(--border-grey);
    line-height: 60px;
    padding-left: 10px;
    font-size: 16px;
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    margin: 0px;
    padding: 0 0 0 15px;
    width: 100%;
    box-sizing: border-box;
  }
  input::placeholder {
    padding-left: 0px;
    font-size: 16px;
  }
  .form-field.PhoneInput {
    // border: 1px solid var(--border-grey);
    flex-grow: 1;
    align-self: stretch;
    position: relative;
    margin-bottom: 10px;
    .PhoneInputCountry {
      display: none;
    }
  }
}

.send-code {
  align-self: stretch;
  @extend .action-black;
  background-color: rgb(255, 191, 0);
  color: black;
  height: 60px;
  // width: calc(100% - 40px);
  width: 100%;
  flex-grow: 1;
  border: none;
  font-size: 15px;
}
.form-row.auth.phone {
  margin-top: 20px;
}
.main-action {
  // margin-top: 5px;
  width: 200px;
  height: 45px;
}
.form-notes {
  margin-bottom: 20px;
  font-size: 13px;
  letter-spacing: 0.6px;
  // width: calc(100% - 50px);
  // @extend .flex-by-column-centered;
  @extend .flex-by-row-centered;
  text-align: center;
  line-height: 2;
  color: white;
  z-index: 3;
  // a {
  //   margin-top: 7px;
  //   width: 100%;
  //   text-transform: uppercase;
  // }
  .alternative-action {
    background-color: transparent;
    border-bottom: 1px solid white;
    margin: 0px;
    text-transform: capitalize;
    font-size: 13px;
    letter-spacing: 0.6px;
    padding: 0px;
    color: white;
  }
  .alternative-action {
    margin-left: 5px;
  }
  .send-code {
    margin-top: 0px;
  }
  .alternative-action.forget-password {
    // margin-top: 10px;
    margin-left: 0px;
    font-size: 8px;
    text-transform: uppercase;
    // border: none;
  }
  .alternative-action.sign-in {
    text-transform: uppercase;
    margin-left: 10px;
  }
  margin-top: 12px;
  margin-bottom: 0px;
}

.form-notes.send-instructions,
.form-notes.phone-code-verification {
  margin-bottom: 15px;
  margin-top: 20px;
  color: black;
  padding: 0px 20px;
  line-height: 1.6;
}
.form-notes.already-signed-up {
  margin-top: 40px;
  > span {
    font-size: 10px;
  }
  @media only screen and (max-width: 600px) {
    margin-top: 20px;
  }
}
@media only screen and (min-width: 500px) {
  .new-user {
    .main-action {
      width: 300px;
    }
    .popup-container {
      .form-popup {
        .form-row,
        .form-notes {
          width: 300px;
        }
      }
    }
  }
}
.auth.error-message {
  // position: absolute;
  // top: calc(var(--nav-height) + 10px);
  // left: 30px;
  width: calc(100% - 60px);
  margin: 0px;
  margin-top: 20px;
  text-align: center;
  @extend .text-12-300;
  font-weight: 400;
  line-height: 1.5;
  text-transform: none;
  // background-color: var(--warning-red);
  // color: white;
}
// @media only screen and (max-width: 360px) {
//   .new-user {
//     width: calc(100vw - 60px);
//   }
// }
