@import './index.scss';
.restaurant-sign {
  @extend .flex-by-column-centered;
  .variation.a {
    background-color: var(--lunch-color);
    margin-bottom: 20px;
    //   color: var(--lunch-color);
    @extend .flex-by-column-centered;
    svg.heart {
      display: inline-block;
      font-size: 14px;
      position: relative;
      transform: skew(-0.55turn, 0deg);
      margin-right: 10px;
      display: none;
    }
    .slogan {
      //   font-size: 20px;
      //   font-weight: 600;

      //   letter-spacing: 2.5px;
      text-align: center;
      @extend .text-title-16;

      margin-bottom: 20px;
      //   text-transform: uppercase;
    }
    .website-url {
      @extend .text-title;

      margin-bottom: 20px;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: 28px;
    }
    .delivery-date {
      text-align: center;
      @extend .text-title-16;
      //   font-size: 20px;
      //   font-weight: 600;
      //   font-family: "Rock Salt";
      //   letter-spacing: 2.5px;
      margin-bottom: 20px;
      text-transform: capitalize;
    }
    .cities {
      @extend .text-12-300;
      @extend .flex-by-column-centered;
    }
    width: 400px;
    height: 400px;
  }
}
