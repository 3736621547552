@import './index.scss';
.announcement {
  width: 100%;
  padding-right: 30px;
  box-sizing: border-box;
  @extend .flex-by-row-centered;
  top: var(--nav-height);
  left: 0px;
  @extend .card;
  // box-shadow: 0px 4px 12px 2px rgba(28, 29, 33, 0.12);
  height: var(--announcement-height-short);
  position: fixed;
  background-color: #e8e02b;
  margin: 0px;
  svg.heart {
    display: inline-block;
    font-size: 14px;
    position: relative;
    transform: skew(-0.55turn, 0deg);
    margin-right: 10px;
  }
  span {
    font-size: 10px;
    font-weight: 600;
    font-family: 'Rock Salt';
    letter-spacing: 2.5px;
  }

  svg.open-announcement {
    margin-left: 10px;
    font-size: 14px;
    position: absolute;
    right: 25px;
  }
  z-index: 2;
}

.announcement.double-line {
  span:first-of-type::after {
    content: ',';
  }
  z-index: 1;
  display: flex;
  position: relative;
  height: auto;
  top: auto;
  left: auto;
  right: auto;
  align-items: flex-start;
  justify-content: center;
  padding: 0px 20px;
  svg.heart {
    position: absolute;
    top: 50px;
    transform: skew(-0.55turn, 0deg) rotate(-15deg) scale(1, 1.4);
    font-size: 35px;
    left: 80px;
  }
  span {
    text-align: center;
    transform: skew(-0.52turn, 0deg) rotate(-15deg) scale(1, 1.1);
    font-size: 18px;
    letter-spacing: 3px;
    line-height: 2;
  }
  span:first-of-type {
    margin-right: 80px;
    margin-top: 10px;
    top: 70px;
    left: 20px;
    position: relative;
  }
  span:last-of-type {
    margin-left: 40px;
    top: 80px;
    position: relative;
  }
  div.open-announcement {
    position: absolute;
    bottom: 100px;
    @extend .action-underline;
  }
}
@media only screen and (min-width: 800px) {
  .announcement {
    position: fixed;
    height: 45px;
    top: calc(var(--nav-height) + 13px);
    right: 30px;
    left: auto;
    box-shadow: none;
    justify-content: center;
    z-index: 7;
    padding: 0px;

    span {
      font-size: 12px;
    }
    svg.open-announcement {
      display: none;
    }
  }
}

.referral-code-error {
  box-shadow: 0 4px 4px 0px rgba(28, 29, 33, 0.2);
  width: 100vw;
  max-width: 450px;
  height: var(--announcement-height-short);
  top: var(--nav-height);
  position: fixed;
  @extend .flex-by-row-centered;
  background-color: var(--background-red);
  color: white;
  z-index: 1;
  svg {
    margin-right: 10px;
  }
  span {
    // align-self: stretch;
    // flex-grow: 1;
    @extend .text-13-400;
    text-align: center;
    height: 35px;
    line-height: 35px;
  }
  button {
    color: white;
    position: absolute;
    right: 0px;
    svg {
      font-size: 20px;
    }
  }
}
.referral-code-error.without-referral-code {
  top: var(--nav-height);
}

@media only screen and (min-width: 800px) {
  .announcement {
    // right: auto;
    // left: calc(50vw + 180px);
    right: 15px;
    width: 692px;
  }

  // .announcement.ordering-with-code,
  .referral-code-error {
    width: auto;
    padding: 0px 20px;
  }
}

@media only screen and (min-width: 1200px) {
  .announcement {
    width: 350px;
  }
  .list {
    .referral-code-error {
      width: 500px;
    }
  }
}

@media only screen and (max-width: 350px) {
  .announcement {
    svg.open-announcement {
      right: 15px;
    }
  }
}
