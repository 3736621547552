@import '../../../index.scss';
.avatar {
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.avatar.letter-only {
  @extend .flex-by-row-centered;
  background: rgba(228, 228, 228, 0.5);
  width: 28px;
  height: 28px;
  border-radius: 50%;
  font-family: 'Montserrat';
  text-transform: uppercase;
  .initial {
    // display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 15px;
    height: 15px;
    // color: white;
  }
}

.profile-link {
  @extend .flex-by-row-left;
  .avatar {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    .initial {
      padding-left: 2px;
      padding-bottom: 1px;
      width: 20px;
      text-align: center;
      line-height: 20px;
      height: 20px;
      font-size: 11px;
    }
  }
  span.name {
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.6px;
    line-height: 15px;
    text-transform: capitalize;

    white-space: pre;
  }
}
