@import "./index.scss";
.receipt {
  .receipt-section {
    .receipt-row {
      .fee-container {
        height: 24px;
        text-align: right;
        @extend .flex-by-row-right;
        // position: relative;
        .cross-out {
          margin-right: 5px;
          line-height: 1;
          color: grey;
        }
        .cross-out.regular {
          text-decoration: line-through;
        }
        .cross-out.member {
          color: var(--background-brown);
        }
        .regular {
          text-decoration: line-through;
          text-align: right;
          margin-right: 10px;
          color: grey;
        }
        .member-icon {
          background-color: var(--background-brown);
          // position: absolute;
          // right: 45px;
          // top: 3px;
          @extend .flex-by-row-centered;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          margin-right: 5px;
          svg {
            margin: 0px;
            font-size: 9px;
            color: black;
            display: block;
          }
        }
        .fee {
          @extend .flex-by-row-right;
        }
      }
      .fee-container.member {
        .fee.member {
          // margin-left: 20px;
          // font-weight: 400;
          // color: var(--background-brown);
          // position: relative;
        }
      }
    }
    .receipt-row.has-credit.total {
      .highlight {
        font-weight: 300;
      }
    }
    .receipt-row.credit {
      margin-top: 10px;
      padding-top: 5px;
      border-top: 1px solid var(--border-grey);
      .highlight {
        font-weight: 300;
      }
    }
    .receipt-row.banner-title {
      display: none;
    }
    .receipt-row.service-fee {
      @extend .flex-by-row-between;
      align-items: flex-start;
      button,
      label {
        b {
          font-weight: 300;
        }
      }
      > button {
        text-align: left;
        margin: 0px;
        padding: 0px;
        @extend .text-12-300;
        white-space: nowrap;
        svg {
          margin: 0px 0px 0px 2px;
        }
      }
    }
    .button-with-info {
      // background-color: red;
      position: relative;
    }
    .service-fee-explanation {
      @extend .card;
      @extend .flex-by-column-centered;
      // background-color: --var(--background-beige);
      background-color: #444;
      z-index: 1;
      position: absolute;
      // padding: 30px 25px;
      left: 0px;
      padding: 15px 50px 15px 20px;
      width: 230px;
      max-width: 450px;
      // background-color: rgba(0, 0, 0, 0.5);
      color: white;
      .explanation-row {
        margin-bottom: 10px;
        > label {
          display: inline-block;
          font-weight: 500;
          margin-bottom: 10px;
        }
        span {
          display: inline-block;
          text-align: left;
          text-transform: none;
          line-height: 1.5;
        }
        .service-fees {
          margin-top: 10px;
          .service-fee-container {
            @extend .flex-by-row-left;
            label {
              margin: 0px 10px;
            }
            // padding-right: 10px;
            // svg {
            //   position: absolute;
            //   right: 5px;
            // }
          }
        }
      }
      .explanation-row:last-of-type {
        margin: 0px;
      }
      .join {
        @extend .action-black;
        position: absolute;
        right: 20px;
        bottom: 20px;
        background-color: white;
        color: black;
      }
      .got-it {
        position: absolute;
        right: 10px;
        top: 15px;
        color: white;
        svg {
          font-size: 16px;
        }
        // @extend .action-black;
      }
    }
    .service-fee-explanation.to-pay {
      top: 130px;
    }
    .service-fee-explanation.paid {
      top: 72px;
    }
    .service-fee-explanation::before {
      content: "";
      width: 14px;
      height: 14px;
      box-shadow: -1px -1px 1px 0 #c8c8c81a;
      position: absolute;
      top: -5px;
      // background-color: blue;
      display: block;
      z-index: 100;
      transform: rotate(45deg);
      background-color: #444;
    }
    .service-fee-explanation.regular::before {
      left: 75px;
    }
    .service-fee-explanation.express::before {
      left: 128px;
    }
    .receipt-row.time-stamps {
      margin-top: 40px;
    }
    .receipt-row.window {
      justify-content: flex-end;
    }
    .receipt-row.offer-code {
      b {
        font-weight: 600;
      }
    }
    .receipt-row.saving {
      span {
        padding: 0px 5px;
      }
    }
    .receipt-row.saving.member {
      span {
        background-color: var(--background-gold);
        border-radius: 5px;
      }
    }
    .receipt-row.saving.member.member-only {
      font-size: 10px;
      span {
        background-color: var(--background-brown);
        border-radius: 5px;
      }
    }
    .receipt-row.saving.regular {
      // padding-top: 10px;
      position: relative;
      // span {
      //   background-color: #bbb;
      //   border-radius: 5px;
      //   width: 50px;

      // }
      label {
        text-transform: none;
      }
      span {
        padding: 0px;
      }
      padding-bottom: 50px;
      button {
        position: absolute;
        bottom: 0px;
        right: 0px;
        @extend .action-black;
        height: 40px;
        // width: 60px;
      }
    }
    .receipt-row.saving.regular.already {
      margin-top: 5px;
      padding-top: 5px;
      padding-bottom: 0px;
      border-top: 1px solid grey;
    }
    .split-line {
      margin: 10px 0;
      width: 100%;
      height: 1px;
      background-color: var(--border-grey);
    }
  }
  .receipt-section.cart-summary {
    padding: 20px 0px 0px 0px;
  }
}

.receipt.group {
  .receipt-section.cart-summary {
    padding: 20px;
  }
}

@media only screen and (max-width: 400px) {
  .receipt {
    .receipt-section {
      .receipt-row.service-fee {
        button,
        label {
          b {
            display: none;
          }
        }
      }
    }
  }
}
