@import './index.scss';
.list.order-list {
  max-width: unset;
  @extend .flex-by-column-centered;
  .set-view {
    @extend .subnav;
    .view-button {
      // @extend .action-transparent;
      height: var(--subnav-height);
      opacity: 0.2;
      margin: 0px 20px;
      padding: 0px;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 1.8px;
      text-transform: uppercase;
      box-sizing: border-box;
      border-bottom: 1px solid transparent;
      svg {
        display: none;
      }
    }
    .view-button.selected {
      opacity: 1;
      border-bottom: 1px solid black;
      svg {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .week-title {
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1.8px;
    padding: 10px 0;
  }
}

// .receipt {
//   @extend .flex-by-row-centered;
//   .receipt-body {
//     width: 100%;
//     align-self: stretch;
//     flex-grow: 1;
//     @extend .flex-by-column-centered;
//     .meal-type {
//       width: 100%;
//       height: 40px;
//       @extend .flex-by-row-centered;
//       margin: 10px 0px;
//       .meal-date {
//         top: 0px;
//         left: 12px;
//       }
//     }
//     .receipt-section {
//       flex-grow: 1;
//       align-self: stretch;
//       @extend .flex-by-column-centered;
//       .receipt-row.restaurant,
//       .receipt-row.total {
//         width: 100%;
//       }
//       .receipt-row.restaurant {
//         margin-bottom: 20px;
//         line-height: 1.8;
//       }
//       .receipt-row.arrive {
//         align-items: flex-start;
//       }
//       .receipt-row.window {
//         justify-content: flex-end;
//       }
//     }
//   }
// }

// .receipt:last-child {
//   margin-bottom: 150px;
// }

.rating {
  @extend .flex-by-column-left;
  margin-top: 20px;

  // align-self: stretch;
  width: 100%;
  // padding: 20px;
  .feedback {
    @extend .flex-by-column-centered;
    background-color: var(--background-beige);
    padding: 20px;
    @extend .text-12-300;
    margin-bottom: 20px;
    width: 100%;
    .feedback-question {
      width: 100%;
      text-align: left;
      margin-bottom: 15px;
    }
    .answer-wrapper {
      width: 100%;
      @extend .flex-by-column-centered;
      button {
        @extend .action-transparent;
        // align-self: stretch;
        position: relative;
        // padding: 10px 40px 10px 20px;
        // background-color: pink;
        // border: 1px solid var(--background-grey);
        width: 50px;
        border: 1px solid black;
        // margin: 0px 10px 0px 0px;
        margin-bottom: 10px;
        @extend .flex-by-row-centered;
        span {
          @extend .text-12-300;

          // ?letter-spacing: 0.6p;
          font-size: 13px;
          text-transform: none;
          span {
            font-weight: 500;
          }
          // text-align: left;
          // background-color: lawngreen;
        }
        svg {
          position: absolute;
          right: 20px;
        }
      }
      button:last-child {
        margin-bottom: 0px;
      }
      button.saving,
      button.undecided {
        // color: var(--background-grey);
        // span {
        //   color: var(--background-grey);
        // }
        opacity: 0.3;
        text-transform: capitalize;
        svg {
          display: none;
        }
      }

      button.selected {
        svg {
          display: inline-block;
        }
      }
    }
    textarea {
      // width: 100%;
      min-height: 100px;
      @extend .text-12-300;
      text-transform: none;
      align-self: stretch;
      padding: 0px 10px;
      border: none;
    }
    textarea::placeholder {
      text-transform: none;
    }
    button.save-comment {
      @extend .action-black;
      margin-top: 20px;
    }
    button.save-comment.saving {
      @extend .action-black;
    }
  }
  .feedback:last-child {
    margin-bottom: 0px;
  }
}

@media only screen and (min-width: 500px) {
  .set-view {
    justify-content: center;
  }
  .receipt {
    .rating {
      .feedback {
        .answer-wrapper {
          flex-direction: row;
          justify-content: space-between;
          button {
            margin: 0px;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 800px) {
  .list.with-banner-on-top.order-list {
    width: 100%;
    max-width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    // .receipt {
    //   width: 300px;
    //   margin: 10px;
    //   align-self: stretch;
    //   justify-content: space-between;
    //   .rating {
    //     .feedback.binary {
    //       .answer-wrapper {
    //         flex-direction: column;
    //         button:first-child {
    //           margin-bottom: 5px;
    //         }
    //       }
    //     }
    //     .feedback.words {
    //       padding: 20px 0px;
    //     }
    //   }
    // }
  }
}
