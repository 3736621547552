@import "../../../index.scss";
nav {
  @extend .flex-by-row-right;
  background-color: white;
  position: fixed;
  @media print {
    position: absolute;
  }
  width: 100vw;
  box-sizing: border-box;
  height: var(--nav-height);
  top: 0px;
  left: 0px;
  box-shadow: 0 4px 32px 0 rgba(28, 29, 33, 0.12);
  .anonymous-user {
    @extend .flex-by-row-right;

    li {
      @extend .action-grey;
      font-size: 10px;
    }
    li:first-child {
      margin-right: 20px;
      background-color: transparent;
    }
  }

  button.user,
  .page-mode {
    margin: 0px;
    padding: 0px;
    width: var(--nav-height);
    height: var(--nav-height);
    @extend .flex-by-row-centered;
    svg {
      font-size: 22px;
    }
    svg.fa-home-alt {
      font-size: 24px;
    }
  }
  .title {
    font-family: "Satisfy";
    font-weight: 600;
    font-size: 20px;
    letter-spacing: 0.6px;
    position: absolute;
    left: 16px;
  }

  .user-menu {
    position: fixed;
    top: calc(var(--nav-height) + 0px);
    right: 0px;
    background-color: white;
    box-sizing: border-box;
    // border-top: 1px solid rgba(28, 29, 33, 0.12);
    box-shadow: 0 16px 32px 0px rgba(28, 29, 33, 0.12);
    // width: auto;
    min-width: 50vw;
    max-width: 500px;
    padding: 20px 0px 60px 0px;
    max-height: calc(100vh - var(--nav-height));
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    li {
      @extend .flex-by-row-left;
      padding: 0px 30px;
      flex-shrink: 0;
      height: 55px;
      // height: 55px;
      svg {
        position: absolute;
        left: 20px;
        font-size: 19px;
      }
      a,
      button {
        width: 100%;
        height: 100%;
        line-height: 55px;
        box-sizing: border-box;
        max-height: 100%;
        // padding: 15px 0px 15px 40px;
        padding-left: 30px;
        font-size: 12px;
        font-family: "Montserrat";
        font-weight: 400;
        letter-spacing: 1.8px;
        text-transform: uppercase;
        position: relative;
        text-align: left;
        display: flex;
        flex: 1;
        // border-bottom: 1px solid rgba(28, 29, 33, 0.12);
      }

      // button::before,
      // a:before {
      //   content: '';
      //   display: inline-block;
      //   width: 4px;
      //   height: 4px;
      //   border: 1px solid black;
      //   transform: rotate(45deg);
      //   margin-left: -40px;
      //   margin-right: 34px;
      //   margin-bottom: 2px;
      // }
    }
  }
}

.page.with-title {
  nav {
    box-shadow: none;
  }
}

@media only screen and (min-width: 500px) {
  nav {
    padding-left: calc(50vw - 250px);
    padding-right: calc(50vw - 250px);
    .title {
      left: calc(50vw - 250px + 16px);
    }
    .user-menu {
      // min-width: 500px;
      // max-width: 300px;
      min-width: 0px;
      right: calc(50vw - 250px);
    }
  }
}

// @media only screen and (min-height: 600px) {
//   nav {
//     .user-menu {
//       li {
//         a,
//         button {
//           padding: 20px 40px;
//         }
//       }
//     }
//   }
// }
