@import '../../../index.scss';
.bookmark-details {
  @extend .flex-by-column-top;
  @extend .card;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  min-height: 100vh;
  box-sizing: border-box;
  padding: 80px 15px 160px 15px;
  align-content: flex;
  & > div,
  & > textarea {
    width: calc(100vw - 30px);
    box-sizing: border-box;
  }
  .dish {
    flex-grow: 0;
    padding-top: 0px;
  }
  .review-content {
    border: 1px solid black;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.8px;
    padding: 15px;
    flex-grow: 1;
  }
  .status-wrapper {
    @extend .flex-by-row-centered;
    margin-bottom: 15px;
    button.select-status {
      text-transform: capitalize;
      letter-spacing: 0.8px;
      height: 40px;
      width: 200px;
      margin-right: 15px;
      padding: 0px 16px 0px 0px;
      position: relative;
      color: #bbb;
      border: 0px;
      background: rgba(228, 228, 228, 0.6);
      color: black;
      svg {
        margin-right: 10px;
        color: transparent;
      }
    }
    button.select-status.selected {
      svg {
        color: black;
      }
    }
    button:last-child {
      margin-right: 0px;
    }
  }

  .button-wrapper {
    @extend .flex-by-row-centered;
    position: fixed;
    bottom: 0px;
    height: 160px;
    button.write-review {
      margin: 10px 0px;
      padding: 5px 0px;
      border-bottom: 1px solid black;
      text-transform: capitalize;
      font-family: 'Montserrat', sans-serif;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 0.8px;
      line-height: 18px;
      box-sizing: content-box;
    }
    button.write-review[type='submit'] {
      padding: 0px;
      margin: 0px;
    }
  }
}
@media only screen and (min-width: 500px) {
  .bookmark-details {
    & > div,
    & > textarea {
      width: var(--mobile-width);
    }
  }
}
