@import './index.scss';
.hero2 {
  //https://images.pexels.com/photos/1546360/pexels-photo-1546360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2
  background-image: url('https://images.pexels.com/photos/984888/pexels-photo-984888.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  // background-image: url('https://images.pexels.com/photos/1739748/pexels-photo-1739748.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  // background-image: url('https://images.pexels.com/photos/2079438/pexels-photo-2079438.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  // background-image: url('https://images.pexels.com/photos/688835/pexels-photo-688835.jpeg');
  // background-image: url('https://images.unsplash.com/photo-1616113364365-b6013f3dad25?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80');
  // background-image: url('https://images.pexels.com/photos/564883/pexels-photo-564883.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2');
  background-size: cover;
  background-position: 0px 0px;
  width: 100%;
  height: 25vh;
  min-height: 200px;
  // padding: 30px;
  //   margin-top: 10px;
  position: relative;
  @extend .flex-by-column-centered;
  //   align-items: flex-start;
  text-align: center;
  //   box-shadow: 0 4px 32px 0 rgba(117, 117, 117, 0.3);
  //   border: 1px solid rgba(217, 217, 217, 0.6);
  //   border-top: 0px;
  color: rgba(0, 0, 0, 0.8);
  //   color: white;
  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0px;
    top: 0px;
  }
  .text-border {
    z-index: 1;
    // width: 260px;
    // height: 260px;
    background-color: transparent;
    // border-radius: 50%;
    // border: 1px solid white;
    @extend .flex-by-row-centered;
    .text {
      z-index: 1;
      // width: 240px;
      // height: 240px;
      // padding: 50px;
      // border-radius: 50%;
      // background-color: white;
      @extend .flex-by-column-centered;
      color: white;
      .slogan {
        // @extend .text-13-400;
        // font-size: 26px;
        // font-weight: 500;
        // line-height: 1.4;
        // margin-top: 5px;
        @extend .text-13-400;
        // font-weight: 500;
        // font-size: 13px;
        line-height: 1.8;
        letter-spacing: 1px;
        // padding: 0px 10px;

        // font-size: 15px;
        // font-weight: 600;
        // font-family: 'Rock Salt';
        // letter-spacing: 2.5px;

        p {
          margin: 5px 0px;
        }
      }
      .notes {
        @extend .text-title;
        text-transform: none;
        font-size: 30px;
        line-height: 1.2;
        letter-spacing: 0.6px;
        font-weight: 500;
        padding-bottom: 20px;
        // width: 40%;
      }
      .learn-more {
        // background-color: rgba(255, 255, 255, 0.7);
        // background-color: rgba(219, 217, 217);
        background-color: white;
        // border: 1px solid black;
        height: 40px;
        padding: 0px 15px;
        line-height: 40px;

        // line-height: 20px;
        // border-bottom: 1px solid white;
        @extend .text-13-400;
        // @extend .text-title;
        font-size: 11px;
        letter-spacing: 1px;
        // margin-top: 30px;
        text-transform: uppercase;
        font-weight: 500;
        box-shadow: 0 4px 32px 0 rgba(117, 117, 117, 1);
      }
    }
  }
}

// @media only screen and (min-width: 600px) {
//   .hero2 {
//     height: 240px;
//     background-position: -100px 250px;
//   }
// }
@media only screen and (min-width: 400px) {
  .hero2 {
    background-position: 0px -60px;
  }
}
@media only screen and (min-width: 500px) {
  .hero2 {
    background-position: 0px -130px;
  }
}
@media only screen and (min-width: 700px) {
  .hero2 {
    background-position: 0px -250px;
  }
}
@media only screen and (min-width: 850px) {
  .hero2 {
    background-position: 0px -350px;
  }
}

@media only screen and (min-width: 1000px) {
  .hero2 {
    background-position: 0px -400px;
  }
}

@media only screen and (min-width: 1100px) {
  .hero2 {
    background-position: 0px -500px;
  }
}

@media only screen and (min-width: 1300px) {
  .hero2 {
    background-position: 0px -550px;
  }
}

@media only screen and (min-width: 1650px) {
  .hero2 {
    background-position: 0px -600px;
  }
}

@media only screen and (min-width: 1750px) {
  .hero2 {
    background-position: 0px -650px;
  }
}

@media only screen and (min-width: 1900px) {
  .hero2 {
    background-position: 0px -700px;
  }
}

@media only screen and (min-width: 2000px) {
  .hero2 {
    background-position: 0px -750px;
  }
}
