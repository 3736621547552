@import "./index.scss";
.select-date {
  margin-left: 10px;
  background-color: var(--background-grey);
  padding: 10px 15px;
  border-radius: 8px;
}
.list.restaurants {
  padding-top: 0px;
  width: 100vw;
  max-width: 100vw;
  .list-title {
    .save-dishes {
      // @extend .action-black;
      position: fixed;
      right: 40px;
    }
  }
  .restaurant-list {
    @extend .flex-by-column-left;
    width: 100%;
    position: relative;
    // padding: 20px 0px;
    // margin-top: 20px;
    .legends {
      margin-top: 10px;
      @extend .flex-by-row-left;
      .legend {
        margin-right: 10px;
        @extend .text-12-300;
        text-transform: capitalize;
        svg {
          margin-right: 10px;
        }
      }
      .legend.commission {
        svg {
          color: green;
        }
      }
      .legend.doordash {
        svg {
          color: red;
        }
      }
      .legend.ubereats {
        svg {
          color: black;
        }
      }
    }
    .restaurant-section {
      padding: 30px;
      width: 100%;
      @extend .flex-by-column-left;
      .level-title {
        @extend .flex-by-row-left;
        @extend .text-title;
        width: 100%;
        margin: 10px 0px;
        position: relative;
      }
      .level-restaurants {
        width: 100%;
        @extend .flex-by-row-left;
        flex-wrap: wrap;
        .add-restaurant {
          align-self: stretch;
        }
        .restaurant-item {
          margin: 40px 30px 0px 0px;
          @extend .card;
          padding: 15px 50px 15px 20px;
          background-color: white;
          position: relative;
          // @extend .flex-by-column-centered;
          svg {
            margin-right: 5px;
            // display: none;
          }
          .restaurant-name {
            @extend .text-12-400;
            margin-bottom: 0;
            text-transform: capitalize;
            // background-color: rgb(240, 240, 240);
            height: 20px;
            line-height: 20px;
            display: block;
            // padding: 0px 10px;
            border-bottom: 1px solid black;
            @extend .overflow-ellipsis;
          }
          button {
            // height: 40px;
            position: absolute;
            right: 15px;
            top: 15px;
            // background-color: grey;
            border: 1px solid black;
            @extend .flex-by-row-centered;
            width: 20px;
            height: 20px;
            svg {
              color: black;
              display: block;
              margin: 0px;
              font-size: 12px;
            }
          }
          .restaurant-last-selected-time {
            font-size: 12px;
            color: #aaa;
            text-align: center;
          }
          .restaurant-markups {
            background-color: var(--background-beige);
            margin-top: 10px;
            padding: 10px 20px;
            @extend .flex-by-column-left;
            .platform {
              @extend .flex-by-row-left;
              @extend .text-12-300;
              padding: 0px;
              margin-bottom: 10px;
              width: 100%;
              label {
                margin-right: 10px;
                text-transform: capitalize;
                .platform-name {
                  margin-right: 5px;
                }
              }
              input {
                margin: 0px; // 5px;
                padding: 5px;
                border: none;
                width: 30px;
                text-align: center;
                // line-height: 15px;
              }
            }
            .platform:last-child {
              margin: 0px;
            }
          }
          .restaurant-commission,
          .restaurant-markup {
            position: absolute;
            top: -10px;
            svg {
              display: block;
              // background-color: red;

              position: absolute;
              z-index: 0;
              right: -10px;
              top: -8px;
              font-size: 30px;
              margin: 0px;
            }
            span {
              top: -2px;
              right: 0px;
              position: absolute;
              z-index: 2;
              font-size: 12px;
              color: white;
              font-weight: 500;
              letter-spacing: 1px;
            }
          }
          .restaurant-commission {
            left: -10px;
            svg {
              color: green;
            }
          }
          .restaurant-markup.doordash {
            left: 60px;
            svg {
              color: red;
            }
          }
          .restaurant-markup.ubereats {
            left: 100px;
            svg {
              color: black;
            }
          }
        }
        .restaurant-item.selected {
          background-color: black;
          color: white;

          svg {
            display: inline-block;
          }
          .restaurant-name {
            font-weight: 600;
          }
        }
      }
      margin-top: 20px;
      padding: 20px;
    }
    .restaurant-section.admitted {
      background-color: var(--background-lightgreen);
    }
    .restaurant-section.in.trial {
      background-color: var(--background-yellow);
    }
    .restaurant-section.deprecated {
      background-color: var(--background-coral);
    }
  }
}
.list.dishes {
  width: 100%;
  max-width: 100vw;
  flex-wrap: wrap;
  padding: 10px;
  @extend .flex-by-column-left;
  .list-title {
    span {
      text-align: center;
    }
    .toggle-editting-restaurant {
      margin-left: 10px;
      svg {
        font-size: 16px;
      }
    }

    padding-right: 60px;
    .close-button {
      height: var(--nav-height);
      padding: 0px;
      z-index: 2;
      width: 60px;
      position: absolute;
      right: 0px;
    }
    // box-shadow: none;
  }
  .link-wrapper {
    @extend .flex-by-row-left;
    a {
      margin: 0px 20px 0 0;
    }
    margin-bottom: 20px;
  }
  .view-restaurant-history {
    @extend .action-black;
    margin: 20px;
  }
  @extend .flex-by-column-centered;
  .restaurant-summary {
    width: 100%;
    height: 120px;
    @extend .flex-by-column-centered;
    background-color: var(--background-beige);
    border-top: 1px solid var(--background-darkgrey);
    @extend .text-16-400;
    .restaurant-actions {
      width: 100%;
      background-color: var(--background-beige);
      padding: 10px;
      border: 0px;
      @extend .flex-by-row-centered;
      button {
        @extend .action-black;
        background-color: white;
        color: black;
        border: 1px solid black;
        margin-right: 20px;
      }
    }
  }
}
.list.dishes {
  padding: 10px 20px;
  @extend .flex-by-column-left;
  .dish-item.add-dish {
    @extend .card;
    width: 100px;
    height: 100px;
    @extend .flex-by-row-centered;
    svg {
      font-size: 32px;
    }
  }
  .dish-cateogry {
    width: 100vw;
    @extend .flex-by-row-left;
    flex-wrap: wrap;
    padding-top: 100px;
    position: relative;
    .category-name {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100px;
      line-height: 100px;
      text-align: center;
      @extend .text-title;
      width: 100%;
    }
    .dish-item {
      @extend .flex-by-column-top;
      //   display: block;
      padding: 30px 10px 60px;
      @extend .card;
      margin: 10px;
      width: 200px;
      align-self: stretch;
      text-align: center;
      box-sizing: content-box;
      position: relative;

      .hero-image {
        position: absolute;
        color: green;
        font-size: 14px;
        font-weight: 500;
        bottom: 20px;
      }

      .set-hero-image {
        position: absolute;
        bottom: 15px;
      }
      .toggle-hero-image.in {
        background-color: green;
      }
      .dish-item-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: cover;
      }
      .dish-name {
        text-align: center;
        margin-top: 10px;
        @extend .text-12-400;
        text-transform: capitalize;
      }
    }
  }
  .restaurant {
    margin: 20px 10px;
    padding: 20px 20px 40px 20px;
    width: 100%;

    @extend .flex-by-column-centered;
    background-color: white;
    // z-index: 2;
    .restaurant-section {
      width: 100%;
      @extend .flex-by-row-between;
      flex-wrap: wrap;
      align-items: flex-start;
      @extend .card;
      margin-bottom: 20px;
      padding: 20px;
      .order-method-list {
        width: 200px;
        margin-top: 10px;
        .expand-toggle {
          width: 100%;
          background-color: var(--background-beige);
          padding: 0px 32px;
          height: 40px;
          @extend .flex-by-row-between;
          @extend .text-13-400;
          span {
            display: inline-block;
          }
        }
        .select-option-list {
          @extend .card;
          top: 40px;
          width: 200px;
          @extend .text-13-400;
        }
      }
      .restaurant-field {
        min-width: 400px;
        width: 40%;
        margin-right: 20px;

        label {
          @extend .text-14-300;
          letter-spacing: 1px;
          text-transform: capitalize;
        }
        span.id {
          @extend .text-bubble;
        }

        input {
          @extend .text-12-300;
          width: 100%;
        }

        margin-bottom: 20px;
      }
      .restaurant-field.restaurant-preorder {
        @extend .flex-by-column-left;
        align-items: flex-start;
        label {
          // height: 20px;
          display: inline-block;
          line-height: 20px;
          margin-bottom: 10px;
        }
        input[type="checkbox"] {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: 10px 0px 40px 0px;
        }
      }
      .restaurant-field.restaurant-maydelay {
        @extend .flex-by-column-left;
        align-items: flex-start;
        label {
          // height: 20px;
          display: inline-block;
          line-height: 20px;
          margin-bottom: 10px;
        }
        input {
          display: inline-block;
          width: 40px;
          height: 40px;
          margin: 10px 0px 0px 0px;
        }
      }
      .restaurant-field.restaurant-id {
        width: 100%;
        @extend .flex-by-row-between;
        .create-account {
          margin-left: 40px;
          @extend .action-black;
        }
      }
      .restaurant-field.restaurant-level {
        width: 100%;
        @extend .flex-by-row-between;
        // background-color: pink;
        .select-wrapper {
          .expand-toggle {
            @extend .text-bubble;
            @extend .flex-by-row-centered;
            padding-right: 50px;
            svg {
              position: absolute;
              top: 10px;
              right: 20px;
            }
            width: 200px;
          }
          .select-option-list {
            @extend .card;
            top: 40px;
            width: 200px;
            button.select-option {
              @extend .text-12-300;
            }
          }
        }
      }
    }
    .restaurant-action-wrapper {
      width: 100%;
      @extend .flex-by-row-centered;
      margin-top: 30px;
      button {
        @extend .action-black;
        margin: 0px 20px;
        width: 100px;
      }
      button.delete-restaurant {
        background-color: transparent;
        border: 1px solid var(--background-coral);
        color: var(--background-coral);
      }
    }
  }
}
.list.dish-details {
  z-index: 4;
  // max-width: 100vw;

  form.dish-item {
    @extend .flex-by-column-top;
    //   display: block;
    padding: 30px;
    margin: 10px;
    width: 100%;

    box-sizing: border-box;
    .dish-item-image {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
      margin-bottom: 30px;
    }

    .dish-field {
      width: 100%;
      @extend .flex-by-column-left;
      margin-bottom: 20px;
      label {
        @extend .text-13-400;
        text-transform: capitalize;
        font-weight: 500;
        margin-bottom: 5px;
      }
      input {
        margin: 0px;
        width: 100%;
      }
    }
    button[type="submit"] {
      @extend .action-black;
      margin-top: 30px;
    }
  }
}

@media only screen and (min-width: 500px) {
  .list.dishes {
    // flex-direction: row;
    // justify-content: flex-start;
    .list-title {
      .view-restaurant-history {
        background-color: red;
      }
    }
    .restaurant {
      .restaurant-section {
        .restaurant-field.restaurant-id,
        .restaurant-field.restaurant-level {
          justify-content: flex-start;
        }
      }
    }
  }
}
