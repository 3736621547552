@import './index.scss';
.select-date {
  margin-left: 10px;
  background-color: var(--background-grey);
  padding: 10px 15px;
  border-radius: 8px;
}
.list-title.edit-meal {
  @extend .flex-by-column-centered;
  .save-dishes {
    // @extend .action-black;
    position: fixed;
    right: 40px;
    top: 20px;
    z-index: 100;
  }
  .edit-cutoff {
    @extend .flex-by-row-centered;
    @extend .text-12-300;
    label {
      text-transform: capitalize;
      font-weight: 400;
      margin-right: 10px;
    }
    input {
      display: inline-block;
      margin: 0px;
      max-width: 50px;
      text-align: center;
    }
    span {
      margin: 0 5px;
    }
  }
  .early-delivery {
    // border: 1px solid black;
    @extend .action-transparent;
    margin: 20px;
    padding: 0px 20px;
    position: relative;
    svg {
      position: absolute;
      right: 10px;
    }
  }
  .restaurant-summary {
    @extend .flex-by-column-centered;
    .restaurant-name {
      @extend .text-title;
    }
    .restaurant-preorder {
      margin-top: 3px;
      @extend .text-13-400;
      text-transform: capitalize;
      text-align: center;
    }
    .restaurant-maydelay {
      margin-top: 3px;
      @extend .text-13-400;
      text-transform: capitalize;
      text-align: center;
    }
  }
}
.list.restaurants {
  padding-top: 0px;

  .restaurant-list {
    @extend .flex-by-row-left;
    width: 100vw;
    flex-wrap: wrap;
    position: relative;
    padding: 0px 30px 30px 30px;
    .restaurant-item {
      margin: 10px 0px 0px 10px;
      @extend .card;
      padding: 10px 15px;
      svg {
        margin-right: 5px;
        display: none;
      }
      .restaurant-name {
        @extend .text-12-400;
        text-transform: capitalize;
      }
      .restaurant-last-selected-time {
        font-size: 12px;
        color: #aaa;
        text-align: center;
      }
    }
    .restaurant-item.add-restaurant {
      align-self: stretch;
      svg {
        display: inline-block;
      }
    }
    .restaurant-item.selected {
      background-color: black;
      color: white;

      svg {
        display: inline-block;
      }
      .restaurant-name {
        font-weight: 600;
      }
      .restaurant-last-selected-time {
        color: #666;
      }
    }
  }
}
