@import '../../index.scss';
@import '../tasting_menu/components/index.scss';
.dividor {
  margin: 50px 0px;
  // width: calc(90% - 40px);
  width: 100px;
  @extend .flex-by-row-centered;
  height: 1px;
  background-color: var(--border-grey);
  position: relative;
  span {
    @extend .text-13-500;
    letter-spacing: 1px;
    text-transform: uppercase;
    text-align: center;
    background-color: white;
    padding: 0px 10px;
  }
}
