@import "../../../index.scss";

body {
  .page {
    padding-top: var(nav-height);
  }
  // .page::-webkit-scrollbar {
  //   display: none;
  // }

  // /* Hide scrollbar for IE, Edge and Firefox */
  // .page {
  //   -ms-overflow-style: none; /* IE and Edge */
  //   scrollbar-width: none; /* Firefox */
  // }
  div {
    box-sizing: border-box;
  }
}
@keyframes lineInserted {
  from {
    height: 0;
  }
  to {
    height: auto; /* your line height here */
  }
}
.show-gradually {
  animation-duration: 3s;
  animation-name: lineInserted;
  transition: all 3s ease-in-out;
}
@keyframes rotater {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotater 2s linear infinite;
  -moz-animation: rotater 2s linear infinite;
  -ms-animation: rotater 2s linear infinite;
  -o-animation: rotater 2s linear infinite;
  animation: rotater 2s linear infinite;
}

.blink {
  animation: blinker 0.8s linear infinite alternate;
}

@keyframes blinker {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.form-popup {
  background-color: white;
  z-index: 100;
  @extend .flex-by-column-centered;
  padding: 30px 20px;
  .popup-title {
    @extend .text-13-500;
    letter-spacing: 2.4px;
  }
  button {
    @extend .action-black;
    margin-top: 20px;
  }
}

.text-10-300 {
  font-size: 10px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
}
.text-11-300 {
  font-size: 11px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
}
.text-12-300 {
  font-size: 12px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
}
.text-13-300 {
  font-size: 13px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 1.8px;
  font-family: "Montserrat", sans-serif;
}
.text-13-400 {
  font-size: 13px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.6px;
  font-family: "Montserrat", sans-serif;
}
.text-14-300 {
  font-size: 14px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: 0.8px;
  font-family: "Montserrat", sans-serif;
}

.text-12-400 {
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1.8px;
  font-family: "Montserrat", sans-serif;
  text-transform: uppercase;
}

.text-13-500 {
  font-size: 13px;
  font-weight: 500;
  line-height: 2;
  letter-spacing: 2.4px;
  font-family: "Montserrat", sans-serif;
}

.text-16-400 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.74px;
  font-family: "Montserrat", sans-serif;
}

.text-18-400 {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.74px;
  font-family: "Montserrat", sans-serif;
}

.text-18-500 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}

.text-15-400 {
  font-size: 15px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 1px;
  font-family: "Montserrat", sans-serif;
}
.text-title {
  font-family: "Theano Didot", serif;
  font-weight: 400;
  font-size: 22px;
  letter-spacing: 0.6px;
  text-transform: capitalize;
}
.text-title-16 {
  font-family: "Theano Didot", serif;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 0.6px;
  // text-transform: capitalize;
}
.list {
  width: 100%;
  max-width: 500px;
  @extend .flex-by-column-centered;
  position: relative;
  padding-top: 20px;
  padding-bottom: var(--footer-height);
  .list-title {
    @extend .flex-by-row-centered;
    width: 100%;
    // height: 60px;
    height: var(--nav-height);
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 105;
    @extend .card;
    @extend .text-title;
    .back-button {
      height: var(--nav-height);
    }
  }
  .list-body {
    width: 100%;
    @extend .flex-by-column-centered;
    // padding-top: 20px;
    padding-bottom: 200px;
    max-width: 500px;
  }
  .list-actions {
    width: 100vw;
    // margin: 60px 0px;
    padding: 30px 20px;
    position: fixed;
    bottom: 0px;
    left: 0px;
    @extend .flex-by-row-stretch;
    @extend .card;
    box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
    z-index: 2;
    button,
    a {
      text-transform: capitalize;
      border: 1px solid black;
      box-sizing: border-box;
      @extend .action-black;
      min-width: 0px;
      width: 100px;
      max-width: 170px;
      font-size: 12px;
      flex-grow: 1;
      flex-shrink: 1;
      // text-transform: uppercase;
    }
    button:first-child,
    a:first-child {
      // @extend .action-transparent;
      background: transparent;
      color: black;
      margin-right: 20px;
    }
    button:last-child,
    a:last-child {
      // text-transform: uppercase;
    }
  }
}
.list.with-banner-on-top {
  padding-top: calc(var(--subnav-height) + 20px);
  .list-title {
    box-shadow: none;
  }
}
.subnav {
  height: var(--subnav-height);
  position: fixed;
  top: var(--nav-height);
  border-bottom: 1px solid rgb(217, 217, 217);
  @extend .flex-by-row-centered;
  font-family: "Theano Didot", serif;
  font-weight: 400;
  font-size: 12px;
  letter-spacing: 1px;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
  background-color: var(--background-beige);
  z-index: 2;
}
.fancy-header {
  // position: absolute;
  // top: -30px;
  position: relative;
  left: 0px;
  background-color: black;
  width: 80px;
  height: 1px;
}
.fancy-header::before {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border: 1px solid black;
  transform: rotate(45deg);
  left: calc(50% - 3px);
  top: -4px;
  background-color: white;
}
.receipt {
  width: 90%;
  @extend .text-12-300;
  @extend .card;
  padding: 20px;
  margin-bottom: 20px;
  max-width: 500px;
  .receipt-section {
    padding: 20px;
    margin-bottom: 20px;
    background-color: var(--background-beige);
    // border-bottom: 1px solid rgb(217, 217, 217);
    position: relative;
    .receipt-subsection {
      margin-bottom: 20px;
      // border-bottom: 1px solid rgb(217, 217, 217);
    }
    .receipt-subsection:last-child {
      margin-bottom: 0px;
    }
    .receipt-title {
      @extend .flex-by-row-centered;
    }
    .receipt-row {
      @extend .flex-by-row-between;
      align-items: flex-start;
      text-transform: capitalize;
      .highlight {
        font-weight: 500;
      }
      label {
        height: 24px;
      }
      > span {
        display: inline-block;
        text-align: right;
        overflow: hidden;
        white-space: normal;
        word-wrap: break-word;
      }
      svg {
        margin-right: 4px;
        font-size: 12px;
      }
    }
    .receipt-row.email {
      & > span {
        text-transform: lowercase;
      }
    }
  }
  .receipt-section:last-child {
    border-bottom: none;
    margin-bottom: 0px;
  }
}
.page {
  .auth-title {
    @extend .flex-by-column-centered;
    // text-transform: uppercase;

    // text-transform: capitalize;
    // background-color: var(--background-beige);
    height: var(--nav-height);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    border-bottom: 1px solid var(--border-grey);
    //  box-shadow: 0 0px 2px 4px rgba(28, 29, 33, 0.2);
    @extend .card;
    text-transform: capitalize;
    z-index: 100;
    background-color: white;
    span {
      @extend .text-title;
      font-size: 16px;
    }
    label {
      @extend .text-10-300;
      font-size: 8px;
      margin-top: 3px;
      color: #333;
    }
    @media only screen and (min-width: 800px) {
      padding: 0px 10vw;
    }
  }
}
.text-bubble {
  // display: inline-block;
  // width: 100%;
  background-color: var(--background-beige);
  @extend .text-12-300;
  // line-height: 1;
  font-weight: 400;
  box-shadow: 0px;
  padding: 0px 20px;
  margin-left: 20px;
  border-radius: 5px;
  height: 40px;
  line-height: 40px;
  box-sizing: border-box;
}
.loading-container {
  @extend .flex-by-column-centered;
  .loading {
    position: absolute;
    margin: 0px;
  }
  .loading-content {
    opacity: 0.25;
  }
}
.error-message {
  @extend .text-12-300;
  line-height: 1.8;
  text-transform: capitalize;
  color: rgb(208, 1, 27);
  margin-bottom: 20px;
  font-weight: 400;
}
.error-message::before {
  content: "Error: ";
}
.error-message::after {
  content: "!";
}
.form-section:last-of-type {
  margin-bottom: 50px;
}
.form-section:first-of-type {
  margin-top: 50px;
}
.form-section {
  margin: 40px 0px;
  position: relative;
  .form-row {
    width: 100%;
    @extend .flex-by-row-between;
    justify-content: space-between;
    margin-bottom: 10px;
    .form-field-wrapper:first-child {
      margin-left: 0px;
    }
    .form-field-wrapper {
      width: 100%;
      input {
        margin: 0px;
        width: 100%;
      }
      .error-message {
        opacity: 0;
        margin-bottom: 0px;
      }
    }
    .form-field-wrapper.half {
      width: calc(50% - 5px);
    }
    .form-field-wrapper.half:first-child {
      margin-right: 10px;
    }
    .form-field-wrapper.wrong {
      input {
        border-bottom: 1px solid rgb(208, 1, 27);
      }
      .error-message {
        opacity: 1;
      }
    }
  }
  .form-row:last-child {
    margin-bottom: 0px;
  }
}
.sub-nav-1 {
  height: var(--subnav-height);
  border-top: 1px solid #d9d9d9;
  border-bottom: 1px solid #d9d9d9;
  background-color: var(--background-beige);
  box-sizing: border-box;
  @extend .flex-by-row-centered;
  width: 100%;
  position: fixed;
  top: var(--nav-height);
  left: 0px;
  z-index: 2;
  button {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    letter-spacing: 1.8px;
    height: 50px;
    text-transform: uppercase;
    margin: 0px 20px;
  }
  button.selected {
    font-weight: 700;
  }
}
.sub-nav-2 {
  height: 80px;
  @extend .flex-by-row-centered;
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
  background-color: white;
  position: fixed;
  @media print {
    position: absolute;
  }
  top: calc(var(--nav-height) + var(--subnav-height));
  z-index: 2;
  button {
    font-family: Montserrat, sans-serif;
    font-size: 12px;
    letter-spacing: 1.8px;
    height: 15px;
    text-transform: uppercase;
    box-sizing: content-box;
    padding: 0px 0px 7px 0px;
    margin: 0px 20px;
  }
  button.selected {
    border-bottom: 2px solid black;
  }
}
