@import "../tasting_menu/components/index.scss";
.site-hero-image {
  position: absolute;
  display: block;
  width: 100vw;
  height: 400px;
  top: 0px;
  left: 0px;
  // top: var(--nav-height);
  // left: 0px;
  object-fit: cover;
  .pic {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 400px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    // @media only screen and (min-width: 500px) {
    //   width: 60vw;
    // }
    // -webkit-animation: fade 4.5s infinite;
    // animation: fade 4.5s infinite;
  }
  // @media only screen and (min-width: 500px) {
  //   width: 60vw;

  // }
}

// n: how many images you are using
// a: how long you want each image to display for
// b: how long you want each fade transition to last
// animation-duration = t = (a + b) * n
// animation-delay = t/n or a + b

// a: 1s
// b: 0.5s
// n: 3
// t: 4.5s
// d: 1.5s

// 0%
// a/t * 100% = 22%
// (a + b) / t * 100% = 33%
// 100% - (b/t * 100%) = 89%
// 100%

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
