@import "./index.scss";
.driver-person {
  padding: 5px 10px;
  height: 30px;
  border-radius: 10px;
  @extend .flex-by-row-left;
  color: white;
  box-shadow: rgba(28, 29, 33, 0.3) 0px 4px 32px 0px;
  svg:first-child {
    margin-right: 10px;
    font-size: 16px;
  }
  svg:last-child {
    margin-left: 10px;
  }
  span {
    // @extend .text-title;
    @extend .text-12-300;
    // font-size: 15px;
    font-weight: 400;
  }
}
.drivers-planning {
  padding-top: var(--subnav-height);
  @extend .flex-by-column-centered;
  .meal-select-wrapper {
    @extend .sub-nav-1;
    .view-mode {
      button.expand-toggle {
        margin: 0px;
        font-size: 10px;
        line-height: 1.4;
      }
      .select-option-list {
        button.select-option {
          font-size: 10px;
          line-height: 1.4;
          margin: 0px;
          // margin: 0px 10px 0px 0px;
        }
      }
    }
    .ask-drivers {
      @extend .action-black;
      span {
        font-size: 10px;
      }
      margin-left: 20px;
    }
    .restaurant-list {
      width: 200px;
      left: 100px;
      .react-dropdown-select-dropdown {
        width: auto;
        z-index: 100;
      }
    }
  }
  .driver-planning-panel {
    @extend .flex-by-column-top;
    position: relative;
    width: 100vw;
    align-items: flex-start;
    z-index: 1;
    .left-panel {
      display: block;
      width: 100vw;
      height: calc(50vh - 100px);
      box-sizing: border-box;
      .stops-map {
        width: 100vw;
        height: calc(50vh - 100px);
        position: absolute;
        top: 0px;
      }
    }
    .right-panel {
      width: 100vw;
      position: relative;
      box-sizing: border-box;
      padding: 20px;
      .drivers-on-duty-desktop-place-holder {
        display: none;
      }
      .drivers-on-duty {
        @extend .card;
        right: 0px;
        height: auto;
        margin: 0px;
        margin-bottom: 20px;
        padding: 10px;
        box-sizing: border-box;
        @extend .flex-by-column-left;
        .driver-actions {
          @extend .flex-by-column-left;
          @extend .text-13-400;
          margin: 0px 0px 10px 0px;
          flex-wrap: nowrap;
          box-sizing: border-box;
          width: 100%;
          padding: 10px;
          .info-section {
            @extend .flex-by-row-left;
            div.driver-name {
              margin: 0px 10px 0px 0px;
            }

            .number-of-stops,
            .eta {
              @extend .text-12-300;
              font-size: 11px;
              @extend .flex-by-row-centered;
              flex-wrap: nowrap;
              background-color: var(--background-grey);
              border-radius: 10px;
              padding: 0px 15px;
              height: 30px;
              line-height: 30px;
              margin-right: 10px;
              display: inline-block;
              width: auto;
              white-space: nowrap;
            }
            .pick-time-input {
              font-size: 14px;
              padding: 0;
              margin: 0 0 0 6px;
              width: 50px;
            }
          }
          .action-section {
            @extend .flex-by-row-right;
            margin-top: 20px;
            button {
              margin: 0px 20px 0px 0px;
              padding: 0px;
              width: 40px;
              @extend .flex-by-column-centered;
              // background-color: var(--background-beige);
              span {
                @extend .text-11-300;
                font-size: 10px;
                line-height: 1;
              }
              svg {
                font-size: 15px;
                margin: 0px 0px 8px 0px;
              }
            }
          }
          div.driver-name {
            @extend .driver-person;
            justify-content: center;
            span {
              margin: 0px;
            }
            box-shadow: none;
          }
          /*
          .number-of-stops {
          }
          .eta {
          }
          button.driver-toggle {
          }
          button.calculate-driver-time {
          }
          */
          button.finalize-driver-route {
            margin-right: 0px;
          }
          button.finalize-driver-route.restricted {
            color: red;
            font-weight: 500;
          }
        }
        .driver-actions:last-child {
          margin-bottom: 0px;
        }
      }
      .ops-order-list {
        @extend .flex-by-row-left;
        align-items: flex-start;
        width: 100%;
        flex-wrap: wrap;
        // padding: 0px 20px;
        .receipt {
          align-self: stretch;
          width: 100%;
          // width: 300px;
          // margin-right: 20px;
        }
      }
    }
  }
  .drivers-availability {
    background-color: white;
    z-index: 1;
    top: var(--nav-height);
    position: fixed;
    width: calc(100vw - 40px);
    max-width: 500px;
    padding: calc(var(--subnav-height) + 20px) 20px 60px 20px;
    box-sizing: border-box;
    @extend .flex-by-column-top;
    height: calc(100vh - var(--nav-height) - 20px);
    overflow-y: scroll;

    .available-drivers-title {
      position: fixed;
      top: var(--nav-height);
      @extend .flex-by-row-centered;
      // @extend .list-title;
      width: calc(100vw - 40px);
      max-width: 500px;
      left: calc(max(20px, 50vw - 250px));
      // border: none;
      background-color: var(--background-beige);
      height: var(--subnav-height);
      @extend .card;
      border-bottom: 1px solid var(--border-grey);
      // border-bottom: 1px solid black;
      span {
        // @extend .text-16-400;
        @extend .text-title;
      }
      z-index: 2;
    }
    .driver-section {
      width: 100%;
      margin-bottom: 20px;
      .section-title {
        @extend .text-title;
        font-size: 16px;
        margin-bottom: 20px;
      }
      .driver-list {
        .driver {
          align-self: stretch;
          @extend .flex-by-column-left;
          margin-bottom: 20px;
          @extend .card;
          padding: 20px;
          position: relative;

          .driver-status {
            align-self: stretch;
            .field {
              @extend .flex-by-row-left;
              margin: 10px 0px;
              padding-left: 25px;
              position: relative;
              svg {
                position: absolute;
                font-size: 16x;
                left: 0px;
              }
              span {
                @extend .text-12-300;
              }
            }
            .field:first-child {
              margin-top: 0px;
            }
            .field.phone {
              margin-bottom: 0px;
            }
            .field.status {
              display: none;
            }
            .field.driver-name {
              @extend .flex-by-row-centered;
              @extend .text-12-300;
              width: auto;
              flex-grow: 0;
              align-self: auto;
            }
            button {
              @extend .action-black;
            }
          }
          // .status.rejected {
          //   color: var(--background-coral);
          // }
          // .status.expired {
          //   color: var(--background-orange);
          // }
          // .status.waiting {
          //   color: var(--background-lightgreen);
          // }
          // .status.accepted {
          //   color: var(--background-darkgreen);
          // }
          .action-wrapper {
            width: 100%;
            margin: 20px 0px 0px 0px;
            @extend .flex-by-row-centered;
          }
        }
        .driver:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }

  .list-title {
    height: var(--subnav-height);
    @extend .subnav;
    @extend .flex-by-column-top;
    justify-content: space-between;
    border-top: 1px solid var(--border-grey);
    .title {
      height: calc(var(--subnav-height) - 40px);
      @extend .flex-by-column-bottom;
      text-transform: uppercase;
    }
  }

  .driver-action {
    @extend .flex-by-row-between;
    align-items: flex-start;
    margin-bottom: 15px;
    width: 100%;
    .driver-label {
      @extend .text-12-300;
      margin-right: 10px;
      text-align: right;
      text-transform: capitalize;
    }
    .order-status {
      @extend .text-12-300;
      @extend .flex-by-row-centered;
      // avoid element shrink
      flex-shrink: 0;
      background-color: var(--background-grey);
      border-radius: 10px;
      padding: 0px 15px;
      height: 30px;
    }

    .select-stop {
      // position: absolute;
      right: 0px;
      @extend .flex-by-row-right;
      flex-wrap: wrap;
      width: calc(100% - 50px);
    }
    .order-status {
      @extend .text-12-300;
      @extend .flex-by-row-centered;
      background-color: var(--background-grey);
      border-radius: 10px;
      padding: 0px 15px;
      height: 30px;
    }
  }
  .driver-action.assign-driver {
    margin-bottom: 7px;
  }
  .driver-action.place-order {
    margin-bottom: 20px;
    .need-to-order {
      color: red;
      font-weight: 500;
    }
  }
}
.drivers-availability-v2 {
  background-color: white;
  z-index: 1;
  top: var(--nav-height);
  position: fixed;
  width: calc(100vw - 40px);
  max-width: 500px;
  padding: calc(var(--subnav-height) + 20px) 20px 60px 20px;
  box-sizing: border-box;
  @extend .flex-by-column-top;
  height: calc(100vh - var(--nav-height) - 20px);
  overflow-y: scroll;

  .available-drivers-title {
    position: fixed;
    top: var(--nav-height);
    @extend .flex-by-row-centered;
    // @extend .list-title;
    width: calc(100vw - 40px);
    max-width: 500px;
    left: calc(max(20px, 50vw - 250px));
    // border: none;
    background-color: var(--background-beige);
    height: var(--subnav-height);
    @extend .card;
    border-bottom: 1px solid var(--border-grey);
    // border-bottom: 1px solid black;
    span {
      // @extend .text-16-400;
      @extend .text-title;
    }
    z-index: 2;
  }
  .driver-section {
    width: 100%;
    margin-bottom: 20px;
    .section-title {
      @extend .text-title;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .driver-list {
      .driver {
        align-self: stretch;
        @extend .flex-by-column-left;
        margin-bottom: 20px;
        @extend .card;
        padding: 20px;
        position: relative;
        .driver-name {
          @extend .flex-by-row-centered;
          @extend .text-12-300;
        }
        .reset-driver {
          @extend .action-black;
          position: absolute;
          top: 20px;
          right: 20px;
        }
        .reset-driver:disabled {
          background-color: #333;
        }
        .driver-name {
          // min-width: 100px;
          @extend .flex-by-row-left;
          color: white;
          padding: 5px 10px;
          svg {
            margin-right: 10px;
          }
          border-radius: 5px;
          margin-bottom: 10px;
          font-weight: 500;
        }
        .driver-status {
          align-self: stretch;
          .field {
            @extend .flex-by-row-left;
            margin: 10px 0px;
            padding-left: 25px;
            position: relative;
            svg {
              position: absolute;
              font-size: 16x;
              left: 0px;
            }
            span {
              @extend .text-12-300;
            }
          }
          .field:first-child {
            margin-top: 0px;
          }
          .field.phone {
            margin-bottom: 0px;
          }
          .field.status {
            display: none;
          }
          button {
            @extend .action-black;
          }
        }
        // .status.rejected {
        //   color: var(--background-coral);
        // }
        // .status.expired {
        //   color: var(--background-orange);
        // }
        // .status.waiting {
        //   color: var(--background-lightgreen);
        // }
        // .status.accepted {
        //   color: var(--background-darkgreen);
        // }
        .action-wrapper {
          width: 100%;
          margin: 20px 0px 0px 0px;
          @extend .flex-by-row-centered;
        }
      }
      .driver:last-child {
        margin-bottom: 0px;
      }
      // add animation for one .driver to disappear by sliding up
      .driver {
        transition: all 0.5s;
      }
    }
  }
}
.drivers-on-duty-v2 {
  @extend .card;
  right: 0px;
  height: auto;
  margin: 0px;
  margin-bottom: 20px;
  padding: 10px;
  box-sizing: border-box;
  @extend .flex-by-column-left;
  .info-section {
    @extend .flex-by-row-left;
    div.driver-name {
      margin: 0px 10px 0px 0px;
    }

    .number-of-stops,
    .eta {
      @extend .text-12-300;
      font-size: 11px;
      @extend .flex-by-row-centered;
      flex-wrap: nowrap;
      background-color: var(--background-grey);
      border-radius: 10px;
      padding: 0px 15px;
      height: 30px;
      line-height: 30px;
      margin-right: 10px;
      display: inline-block;
      width: auto;
      white-space: nowrap;
    }
  }
  .action-section {
    margin-top: 8px;
    @extend .flex-by-row-right;
    button {
      margin: 0px 20px 0px 0px;
      padding: 0px;
      width: 40px;
      @extend .flex-by-column-centered;
      // background-color: var(--background-beige);
      span {
        @extend .text-11-300;
        font-size: 10px;
        line-height: 1;
      }
      svg {
        font-size: 15px;
        margin: 0px 0px 8px 0px;
      }
    }
  }
  div.driver-name {
    @extend .driver-person;
    justify-content: center;
    span {
      margin: 0px;
    }
    box-shadow: none;
  }
  /*
    .number-of-stops {
    }
    .eta {
    }
    button.driver-toggle {
    }
    button.calculate-driver-time {
    }
    */
  button.finalize-driver-route {
    margin-right: 0px;
  }
  button.finalize-driver-route.restricted {
    color: red;
    font-weight: 500;
  }
  .driver-actions:last-child {
    margin-bottom: 0px;
  }
}
.receipt {
  @extend .flex-by-column-centered;
  // align-items: stretch;
  .receipt-section {
    width: 100%;
  }
}
.order-header {
  width: 100%;
  // text-align: left;
  text-align: center;
  // font-size: 16px;
  margin-bottom: 20px;
  @extend .flex-by-row-centered;
  .order-index {
    margin-right: 10px;
  }
  .order-name {
    font-weight: 500;
    background-color: var(--background-grey);
    padding: 0px 10px;
  }
}
.order-view {
  width: 100%;
  @extend .flex-by-row-centered;
  margin-bottom: 20px;
  button {
    @extend .flex-by-row-centered;
    // border: 1px solid black;
    margin: 10px;
    padding: 5px 10px 5px 30px;
    // opacity: 0.25;
    position: relative;
    span {
      @extend .text-12-400;
      text-transform: capitalize;
      letter-spacing: 0.8px;
    }
    svg {
      display: none;
      position: absolute;
      left: 10px;
    }
  }
  button.on {
    opacity: 1;
    svg {
      display: block;
    }
  }
}

.assign-stop-index {
  @extend .flex-by-row-between;
}
.deliver-button {
  @extend .action-black;
}

.page-shortcut {
  position: fixed;
  bottom: 10px;

  z-index: 3;
  border-radius: 50%;
  background-color: white;
  @extend .flex-by-row-centered;
  box-shadow: 0 8px 32px 0 rgba(28, 29, 33, 0.5);
  width: 50px;
  height: 50px;
  svg {
    font-size: 20px;
  }
}
// .go-to-bottom {
//   left: 10px;
// }
.back-to-top {
  left: 10px;
}
@media only screen and (min-width: 500px) {
  .drivers-planning {
    .driver-planning-panel {
      width: 100vw;
      flex-direction: row;
      .left-panel {
        width: 60vw;
        height: calc(100vh - var(--nav-height) - var(--subnav-height));
        min-width: 500px;
        .stops-map {
          position: fixed;
          width: 60vw;
          height: calc(
            100vh - var(--nav-height) - var(--subnav-height) - 100px
          );
          top: calc(var(--nav-height) + var(--subnav-height));
          min-width: 500px;
          z-index: 3;
          left: 0px;
        }
      }
      .right-panel {
        width: 40vw;
        max-width: calc(100vw - 500px);
        .drivers-on-duty-desktop-place-holder {
          display: block;
          padding-top: 60px;
        }
        .drivers-on-duty {
          flex-direction: column;
          width: 40vw;
          position: fixed;
          right: 0px;
          max-width: calc(100vw - 500px);
          z-index: 2;
          top: calc(var(--nav-height) + var(--subnav-height));
          button {
            svg {
              margin-left: 10px;
            }
          }
          .driver-actions {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin: 0px 20px 0px 5px;
            width: 100%;

            .info-section {
            }
            .action-section {
              margin: 0px;
            }
          }
        }
        .ops-order-list {
          // max-width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: left;
          .receipt {
            align-self: stretch;
            margin: 20px;
            width: 300px;
            justify-content: flex-start;
          }
          // .receipt:last-child {
          //   margin-right: 10px;
          // }
          // .receipt:first-child {
          //   margin-left: 10px;
          // }
        }
      }
    }
  }
}
