@import "../../index.scss";
.popup-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0px;
  top: 0px;
}
.popup-overlay {
  height: 100vh;
  width: 100vw;
  background: rgba(0, 0, 0, 0.7);
  opacity: 0.8;
  position: fixed;
  left: 0px;
  top: 0px;
  // z-index: 200;
}
