@import "./index.scss";
.calendar-header {
  @extend .subnav;
  // height: var(--nav-height);
  height: 50px;
  @extend .flex-by-row-left;
  position: fixed;
  top: 0px;
  z-index: 100;
  width: 100vw;
  .current-month {
    height: calc(var(--subnav-height) - 40px);
    @extend .flex-by-row-centered;
    text-transform: uppercase;

    button.move-month {
      margin: 20px;
    }
  }
}
.calendar {
  // padding-top: var(--subnav-height);
  padding-bottom: 100px;
  .week-days {
    width: 100%;
    @extend .flex-by-row-space-around;
    position: sticky;
    top: var(--subnav-height);
    .daily-cell {
      width: calc(100% / 7);
      height: 40px;
      @extend .flex-by-column-centered;
      text-transform: capitalize;
      @extend .text-14-300;
      border-right: 1px solid #d9d9d9;
    }
  }
  .swap-meals {
    position: fixed;
    top: 10px;
    right: 20px;
    z-index: 100;
    @extend .flex-by-row-right;
    span {
      margin: 0px 5px;
      border: 1px solid black;
      padding: 5px 10px;
    }
    button {
      @extend .action-underline;
      margin-left: 20px;
      border: none;
      background-color: var(--border-grey);
      padding: 0px 10px;
      height: 30px;
      border-radius: 15px;
    }
  }
  .menu-calendar {
    z-index: 100;
    width: 100vw;
    @extend .flex-by-column-stretch;
    padding-bottom: 100px;
    .calendar-row {
      width: 100%;
      @extend .flex-by-row-top;
      position: relative;
      border: 2px solid black;
      border-bottom: none;
      padding-top: 40px;
      .daily-cell {
        width: calc(100% / 7);
        @extend .flex-by-column-top;
        border-top: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        text-align: center;
        padding: 0px 0px 70px 0px;
        align-self: stretch;
        position: relative;

        .date {
          // position: absolute;
          width: 100%;
          height: 60px;
          position: relative;
          @extend .flex-by-column-centered;
          .day-of-week {
            @extend .text-12-300;
            font-size: 10px;
            font-weight: 500;
            line-height: 30px;
            // margin-bottom: 5px;
            margin-left: 10px;
            margin-bottom: 10px;
            color: black;
            background-color: transparent;
          }
          .date-number {
            @extend .flex-by-column-centered;
            width: 30px;
            height: 30px;
            // top: 20px;
            border-radius: 50%;
            @extend .text-16-400;
            font-weight: 500;
            // font-size: 15px;
            background-color: black;
            color: white;
            margin-bottom: 10px;
            // font-size: 20px;
            z-index: 1;
          }

          .daily-summary {
            position: absolute;
            left: 0px;
            top: 0px;
            @extend .flex-by-column-left;
            // border: 1px solid blue;
            // border: 1px solid black;
            justify-content: flex-start;
            padding: 0px;
            height: 14px;
            .GMV,
            .revenue,
            .profit {
              // @extend .text-12-300;
              @extend .flex-by-row-left;
              align-items: flex-start;
              margin-bottom: 3px;
              // font-weight: 500;
              // font-size: 14px;
              // @extend .card;
              // padding: 0px 8px;
              // border-radius: 5px;
              // line-height: 1.4;
              // background-color: var(--background-grey);
              // position:absolute;
              // height: 14px;
              label {
                // background-color: white;
                font-size: 10px;
                // display: none;
                // width: 12px;
                text-align: left;
                // height: 12px;
                // line-height: 12px;
                // background-color: var(--background-grey);
                // border-radius: 50%;
                margin-right: 2px;
                font-weight: 500;
              }
              span {
                @extend .text-12-300;
                font-size: 10px;
                line-height: 1.2;
                background-color: transparent;
                font-weight: 500;
              }
            }
            .revenue {
              color: grey;
            }
            // .GMV {
            //   left: 0px;
            //   top: 0px;
            //   text-align: left;
            // }
            // .revenue {
            //   left: 0px;
            //   top: 20px;
            //   text-align: left;
            // }
            // .profit {
            //   right: 0px;
            //   top: 0px;
            //   text-align: right;
            // }
            .profit.positive {
              color: green;
            }
            .profit.negative {
              color: red;
            }
          }
        }
        .daily-menu {
          @extend .flex-by-column-top;
          flex-grow: 1;
          position: relative;
          // padding-top: 40px;
          width: 100%;

          .meal-container {
            @extend .card;
            width: 100%;
            margin: 5px 0px;
            padding: 5px;
            @extend .flex-by-column-centered;
            align-content: stretch;
            .meal-type {
              @extend .text-12-300;
              font-size: 9px;
              font-weight: 500;
              text-transform: uppercase;
              width: 100%;
              position: relative;
              @extend .flex-by-row-left;
              margin-bottom: 5px;
              button {
                @extend .text-12-300;
                font-size: 10px;
                margin: 0px;
                padding: 0px 5px;
                // width: 50px;
                background-color: var(--border-grey);
                margin-left: 5px;
                line-height: 1.5;
                border-radius: 5px;
              }
            }
            .meal-type.lunch {
              color: var(--lunch-calendar-color);
            }
            .meal-type.dinner {
              color: var(--dinner-calendar-color);
            }
            .meal-type.earlyDinner {
              color: var(--early-dinner-calendar-color);
            }
            .meal-summary {
              @extend .flex-by-row-between;
              @extend .text-12-300;
              width: 100%;
              height: 30px;
              font-size: 9px;
              margin-bottom: 5px;
              align-items: flex-start;
              .meal-revenue {
                @extend .flex-by-column-left;
                span {
                  line-height: 1.3;
                  font-weight: 500;
                }
                span:last-child {
                  color: grey;
                }
              }
              .meal-profit {
                @extend .flex-by-row;
                justify-content: flex-end;
                text-align: right;
                // background: grey;
                line-height: 1.3;
                .total {
                  font-weight: 500;
                  // background: blue;
                  // margin-right: 2px;
                }
                .percent {
                  // font-size: 10px;
                }
              }
              .meal-profit.positive {
                color: green;
              }
              .meal-profit.negative {
                color: red;
              }
            }

            // .select-to-swap {
            //   // @extend .action-grey;
            //   // margin: 10px 15px;
            //   // font-size: 10px;
            //   // align-self: stretch;
            //   // flex-grow: 1;
            //   position: absolute;
            //   top: 0px;
            //   right: 0px;
            //   @extend .text-12-300;
            // }
          }
          .meal-container.to-swap {
            background-color: yellow;
          }
          .meal-wrapper {
            width: 100%;
            height: auto;
            padding: 10px;
            .meal-accounting {
              @extend .flex-by-column-centered;

              background-color: white;
              margin-bottom: 10px;
              align-self: stretch;
              align-content: stretch;
              .meal-setting {
                flex-grow: 1;
                align-self: stretch;
                @extend .flex-by-column-centered;
                @extend .text-12-400;
                margin: 8px 8px 0px 8px;
                padding-bottom: 5px;
                border-bottom: 1px dashed black;
                .restaurant-name,
                .restaurant-delivery-zone,
                .meal-id {
                  width: 100%;
                  margin: 0px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  word-break: normal;
                  span {
                    font-size: 6px;
                  }
                }
                .restaurant-delivery-zone {
                  font-size: 6px;
                }
                .meal-id {
                  text-transform: lowercase;
                }

                // margin: 5px 0px 0px 0px;
              }
              .meal-stats {
                margin: 0px 8px;
                flex-grow: 1;
                align-self: stretch;
                .stats-section {
                  border-bottom: 1px dashed black;
                  padding: 10px 0px;
                  .stats-row {
                    @extend .flex-by-row-between;
                    @extend .text-12-300;
                    align-items: flex-start;
                    margin-bottom: 3px;
                    label {
                      font-weight: 500;
                      text-transform: capitalize;
                      text-align: left;
                    }

                    span {
                      letter-spacing: 1.4px;
                    }
                  }
                  .stats-row:last-child {
                    margin-bottom: 0px;
                  }
                  .stats-row.income-rate {
                    border-top: 1px solid black;
                    padding-top: 5px;
                  }
                }
                .stats-section:last-child {
                  border: none;
                }
              }

              .restaurant-name {
                color: black;
                @extend .text-13-400;
              }
            }
            .meal-accounting:last-child {
              margin-bottom: 0px;
            }
            .meal-name {
              width: 100%;
              @extend .text-title-16;
              margin-bottom: 5px;
              // border: 1px solid black;
              // margin-bottom: 10px;
            }

            .meal-id {
              // margin: 5px 0px;
              width: 100%;
              @extend .text-12-300;
              @extend .flex-by-column-centered;
              font-size: 6px;
            }
            button {
              width: 100%;
              margin-bottom: 5px;
              align-self: stretch;
              // height: 130px;
              // border: 1px solid black;
              padding: 10px;
              // box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
              @extend .flex-by-column-between;
            }
            button:last-child {
              margin-bottom: 0px;
            }

            .edit-dishes {
              @extend .action-underline;
              width: 50px;
              // margin-top: 5px;
              width: auto;
              svg {
                margin-left: 8px;
              }
              bottom: 10px;
            }
            button.edit-restaurant {
              @extend .flex-by-column-centered;
              position: relative;
              height: auto;
              border: 1px solid black;

              .restaurant-name {
                @extend .text-title;
                color: black;
                line-height: 1.2;
                @extend .overflow-ellipsis;
                width: 100%;
                font-size: 11px;
              }
              .restaurant-delivery-zone {
                margin-bottom: 5px;
                font-size: 8px;
              }
              .last-served {
                margin-top: 5px;
                text-transform: none;
                font-size: 8px;
              }

              &.draft {
                background: #ccc;
              }

              .meal-orders {
                position: absolute;
                right: -10px;
                bottom: 10px;
                opacity: 0.7;
                color: white;
                padding: 7px 5px;
                width: 80px;
                @extend .flex-by-column-top;
                transform: rotate(-30deg);
                // @extend .text-12-400;
                font-size: 11px;
                line-height: 1.8;
                font-weight: 500;
                letter-spacing: 1px;
                text-transform: none;
                // span {
                //   font-size: 13px;
                // }
                // label {
                //   font-size: 7px;
                //   margin-top: 3px;
                // }
              }

              .meal-dishes {
                @extend .flex-by-row-centered;
                flex-wrap: wrap;
                .meal-dish-image {
                  width: 30px;
                  height: 30px;
                  border-radius: 50%;
                  object-fit: cover;
                  margin-right: 5px;
                  margin-top: 5px;
                }
              }
            }
            button.edit-restaurant.lunch {
              .meal-orders {
                background-color: var(--background-coral);
              }
            }

            button.edit-restaurant.dinner {
              .meal-orders {
                background-color: var(--background-darkgreen);
              }
            }
            button.select-restaurant {
              span {
                font-size: 10px;
              }
            }
          }
          .meal-wrapper.lunch {
            background-color: var(--lunch-calendar-color);
            .edit-restaurant {
              @extend .card;
            }
            margin-bottom: 10px;
            border-bottom: 1px solid black;
          }
          .meal-wrapper.earlyDinner {
            background-color: var(--early-dinner-calendar-color);
            .edit-restaurant {
              @extend .card;
            }
            margin-bottom: 10px;
            border-bottom: 1px solid black;
          }
          .meal-wrapper.dinner {
            background-color: var(--dinner-calendar-color);

            .edit-restaurant {
              @extend .card;
            }
            margin-top: 20px;
          }
          .meal-wrapper.daily {
            background-color: var(--background-pink);
            margin-bottom: 20px;
            .meal-name {
              padding-bottom: 10px;
              border-bottom: 1px dashed black;
              margin-bottom: 0px;
            }
            .daily-summary {
              width: 100%;
              height: auto;
              .daily-stats {
                margin: 8px 8px 0px 8px;
                padding: 5px 0px;
                flex-grow: 1;
                align-self: stretch;
                background-color: transparent;
                .stats-row {
                  @extend .flex-by-row-between;
                  @extend .text-12-300;
                  align-items: top;
                  margin-bottom: 3px;
                  label {
                    font-weight: 500;
                    text-transform: capitalize;
                    text-align: left;
                  }

                  span {
                    letter-spacing: 1.4px;
                  }
                }
                .stats-row {
                  margin-bottom: 0px;
                }
              }
            }
          }
        }

        .daily-menu.today {
          // .date {
          //   background-color: var(--background-purple);
          //   color: white;
          // }
          background-color: rgba(95, 92, 255, 0.1);
        }
        .daily-menu.past {
          // background-color: white;
          // background-color: rgba(95, 92, 255, 0.1);
          // background-color: var(--background-beige);
          background: grey;
          // .date {
          //   opacity: 0.2;
          //   font-weight: 400;
          // }
          button.select-restaurant {
            @extend .action-grey;
            opacity: 1;
            // border: 2px solid black;
          }
        }
        .daily-menu.hide-meals {
          // background-color: red;
          width: 100%;
          // flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: flex-start;

          // display: block;
          // padding: 5px;
          .meal-thumbnail-container {
            @extend .flex-by-row-between;
            width: calc(100% - 40px);
            padding-right: 30px;
            position: relative;
            // border: 1px solid black;
            margin: 0px 0px 5px 40px;
            .delete-meal {
              position: absolute;
              right: 0px;
              // top: 5px;
              color: black;
              // width: 20px;
              margin: 0px;

              background-color: var(--background-beige);
              display: flex;
              @extend .text-12-300;
              font-size: 10px;
              font-weight: 500;
              text-align: center;
              width: 30px;
              height: 25px;
              line-height: 25px;
              @extend .flex-by-row-centered;
              svg {
                font-size: 14px;
              }
            }
            .meal-thumbnail {
              @extend .text-11-300;

              padding: 0px 5px;
              text-align: left;
              // border-radius: 3px;
              @extend .flex-by-row-left;
              position: relative;
              // width: calc(100% - 15px);
              flex-grow: 1;
              svg {
                color: white;
                position: absolute;
                right: 5px;
              }
              span {
                color: white;
                font-weight: 500;

                // width: calc(100% - 30px);
                display: inline-block;
                text-align: left;
              }
              .meal-revenue {
                width: 25px;
                margin: 0px 10px 0px 0px;
                flex-grow: 0;
                flex-shrink: 0;
              }
              span.restaurant-name {
                @extend .overflow-ellipsis;
                margin: 0px;
              }
              span.restaurant-commission {
                position: absolute;
                left: -45px;
                top: -2px;
                text-align: center;
                // width: 16px;
                // height: 16px;
                line-height: 15px;
                font-size: 10px;
                // background-color: rgba(255, 255, 255, 0.6);
                font-weight: 600;
              }
              max-width: 100%;
            }

            .meal-thumbnail.lunch {
              background-color: var(--lunch-calendar-color);
              border: 1px solid var(--lunch-calendar-color);
              span.restaurant-commission {
                // border: 1px solid var(--lunch-calendar-color);
                color: var(--lunch-calendar-color);
              }
            }

            .meal-thumbnail.earlyDinner {
              background-color: var(--early-dinner-calendar-color);
              border: 1px solid var(--early-dinner-calendar-color);
              span.restaurant-commission {
                // border: 1px solid var(--lunch-calendar-color);
                color: var(--early-dinner-calendar-color);
              }
            }

            .meal-thumbnail.dinner {
              background-color: var(--dinner-calendar-color);
              border: 1px solid var(--dinner-calendar-color);
              span.restaurant-commission {
                // border: 1px solid var(--dinner-calendar-color);
                color: var(--dinner-calendar-color);
              }
            }

            .meal-thumbnail.confirmed {
              // svg {
              //   position: absolute;
              //   right: 5px;
              //   color: white;
              //   top: 3px;
              //   font-size: 16px;
              // }
              span {
                color: white;
              }
            }
            // .meal-thumbnail.unconfirmed {
            //   background-color: transparent;
            // }
            .meal-thumbnail.lunch.unconfirmed {
              background-color: white;
              span {
                color: var(--lunch-calendar-color);
              }
              span.restaurant-commission {
                color: var(--lunch-calendar-color);
              }
            }
            .meal-thumbnail.earlyDinner.unconfirmed {
              background-color: white;
              span {
                color: var(--early-dinner-calendar-color);
              }
              span.restaurant-commission {
                color: var(--early-dinner-calendar-color);
              }
            }
            .meal-thumbnail.dinner.unconfirmed {
              background-color: white;
              span {
                color: var(--dinner-calendar-color);
              }
              span.restaurant-commission {
                color: var(--dinner-calendar-color);
              }
            }
            .meal-thumbnail {
              background-color: transparent;
              // padding-right: 5px;
            }
            .meal-thumbnail.cuisine-filtered {
              border: 2px solid var(--background-purple);
              background-color: var(--background-purple);
            }
          }
        }
        .daily-revenue {
          // background-color: red;
          // @extend .text-14-300;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 1px;
          margin: 10px 0px;
          padding: 8px 10px;
          background-color: var(--background-pink);
          opacity: 0.8;
          color: white;
          position: absolute;
          bottom: 10px;
          box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
        }
      }
      .daily-cell.accounting {
        padding-bottom: 20px;
      }
      .daily-cell:first-child {
        border-left: none;
      }
      .daily-cell.hide-meals {
        padding: 5px 5px 0px 5px;
      }

      .daily-cell.shown {
        background-color: var(--background-beige);
        // border: 1px solid black;
        .date {
          .date-number {
            // box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
            background-color: var(--background-coral);
          }
        }
      }
      .daily-cell.unshown {
        .date {
          span {
            opacity: 1;
          }
        }
      }
      .daily-cell.past {
        background-color: white;
        .date {
          // opacity: 0.5;
          .date-number {
            // box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
            background-color: black;
          }
        }
      }
      // .daily-cell.past.hide-meals {
      //   opacity: 0.5;
      // }
      .daily-cell.today {
        .date {
          .date-number {
            // box-shadow: 0px 0px 16px rgba(28, 29, 33, 0.4);
            background-color: var(--background-darkgreen);
          }
        }
      }
      .daily-cell.day-of-week {
        flex-grow: 0;
        span {
          text-transform: capitalize;
        }
      }
    }
    // .calendar-row.planning-week {
    .calendar-row.show-cuisine-filter {
      padding-top: 120px;
    }
    .calendar-row:first-child {
      background-color: white;
      // border-top: 1px solid #d9d9d9;
      // .daily-cell {
      //   border-bottom: none;
      // }
    }
  }
}
.restaurant-contact {
  @extend .card;
  background-color: white;
  z-index: 1;
  padding: 20px 40px;
  position: fixed;
  top: calc(var(--nav-height));
  @extend .flex-by-column-top;
  // height: calc(100vh - 2 * var(--nav-height) -  var(--subnav-height));
  // max-height: 500px;
  max-width: 100vw;
  .word {
    @extend .text-10-300;
    margin: 10px 0px;
  }
  .meal-name,
  .restaurant-date,
  .restaurant-name {
    text-transform: capitalize;
    @extend .text-13-400;
    color: black;
    margin: 0px;
  }
  .meal-status {
    padding: 0px;
    width: 100%;
    margin: 40px 0px 20px 0px;
    @extend .text-13-400;
    @extend .flex-by-row-left;
    svg {
      border: 1px solid black;
      color: var(--background-darkgreen);
      margin-right: 10px;
      font-size: 16px;
      padding: 2px;
    }
    span {
      text-transform: none;
    }
  }
  .meal-status.unconfirmed {
    svg {
      color: transparent;
    }
  }
  .contact-info {
    flex-grow: 1;
    @extend .flex-by-column-even;
    background-color: var(--background-beige);
    width: 100%;
    @extend .text-13-400;
    text-transform: capitalize;
  }
  .action-wrapper {
    width: 100%;
    @extend .flex-by-row-between;
    margin: 20px 0px;
    button {
      @extend .action-black;
      width: 100px;
    }
    button.save-status {
      margin-right: 20px;
    }
    button.cancel-status {
      color: black;
      background-color: transparent;
      border: 1px solid black;
    }
  }
}

@media only screen and (min-width: 500px) {
  .calendar {
    .menu-calendar {
      .calendar-row {
        .daily-cell {
          .date {
            flex-direction: row;
            button {
              position: absolute;
              right: 10px;
              top: 10px;
            }
          }
        }
      }
    }
  }
}
