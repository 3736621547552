@import './index.scss';
@import './DriverList.scss';
.future-meal {
  color: var(--background-darkgreen);
}
.same-day-meal {
  color: var(--background-purple);
}
.past-meal {
  color: var(--background-orange);
}
.driver-for-stop {
  margin-top: 10px;
  align-self: stretch;
  @extend .flex-by-row-left;
  button.select-driver {
    @extend .driver-person;
  }

  .select-stop {
    button.stop {
      box-shadow: rgba(28, 29, 33, 0.4) 0px 4px 32px 0px;
    }
  }
}
.stops-map {
  height: calc(100vh - var(--nav-height) - var(--subnav-height));
  position: fixed;
  top: calc(var(--nav-height) + var(--subnav-height));
  .address-marker {
    @extend .flex-by-row-left;
    position: relative;
    // background: black;
    width: auto;
    svg {
      font-size: 22px;
    }
    span {
      margin-left: 5px;
      @extend .text-16-400;
      font-family: 'Theano Didot', serif;
      letter-spacing: 0.4px;
      width: auto;
      white-space: nowrap;
    }
    .order-first-name {
      text-transform: capitalize;
    }
  }
  .stop-on-map {
    position: relative;
    // border: 1px solid red;

    .address-marker.home-marker {
      // color: var(--background-purple);

      button.toggle-details {
        svg {
          font-size: 26px;
        }
      }
      @extend .flex-by-row-top;
      justify-content: flex-start;
      .order-name {
        @extend .flex-by-row-left;
        padding-left: 8px;
        position: relative;
        .order-stop-index {
          display: block;
          position: absolute;
          // width: 20px;
          // height: 20px;
          // border-radius: 50%;
          // padding: 0px;
          // margin: 8px;
          color: white;
          left: -19px;
          top: -2px;
          @extend .text-12-300;
          font-size: 11px;
          font-weight: 500;
        }
        .order-firstName {
          @extend .text-12-300;
          font-weight: 600;
          line-height: 1.2;
          // font-size: 11px;
          text-transform: capitalize;
          // background-color: var(--background-beige);
          position: relative;
          left: -8px;
          top: -1px;
          // padding: 0px 10px;
          // z-index: 1;
          svg {
            display: inline-block;
            position: absolute;
            left: 0px;
            top: 0px;
          }
        }

        .order-eta {
          @extend .text-12-300;
          font-weight: 600;
          position: relative;
          top: -1px;
          left: -4px;
        }
        // .apartment {
        //   display: block;
        //   width: 10px;
        //   height: 10px;
        //   background-color: orange;
        //   z-index: 100;
        //   position: absolute;
        //   right: 0px;
        //   top: 0px;
        // }
      }
    }
    .address-marker.home-marker.show-in-background {
      opacity: 0.2;
    }
    .driver-list-on-map {
      position: absolute;
      @extend .card;
      background-color: transparent;
      // padding: 10px;
      z-index: 10;
      opacity: 1;
      margin-top: 5px;
      @extend .flex-by-column-left;

      .driver-for-stop:first-child {
        margin-top: 0px;
      }
    }
  }
  .address-marker.restaurant-marker {
    color: black;
    @extend .flex-by-column-left;
    // background-color: red;
    // padding-left: 20px;
    // padding-left: 12px;
    // border: 1px solid red;
    width: auto;
    // background-color: white;

    button.restaurant-details {
      padding: 2px 15px 2px 25px;
      @extend .flex-by-row-left;
      position: relative;
      width: 100%;
      border: none;
      box-shadow: none;
      background-color: rgba(255, 255, 255, 0.9);
      svg {
        position: absolute;
        left: 5px;
        top: 8px;
        font-size: 12px;
      }
      .needs-work {
        position: absolute;
        right: 5px;
        top: 0px;
        height: 100%;
        @extend .flex-by-column-centered;
        align-items: center;
        justify-content: center;
        span {
          font-size: 8px;
          line-height: 10px;
          font-weight: 700;
          color: red;
          margin: 0px;
        }
      }
      .all-good {
        position: absolute;
        right: 24px;
        top: 0px;
        color: green;
        left: auto;
        @extend .flex-by-row-centered;
        width: auto;
        svg {
          font-size: 9px;
        }
      }
      .commission {
        position: absolute;
        right: -5px;
        top: -5px;
        display: block;
        line-height: 14px;
        width: 14px;
        background-color: black;
        color: white;
        font-size: 7px;
        border-radius: 50%;
      }
      .restaurant-name {
        // margin-left: 30px;
        text-align: left;
        // width: calc(100% - 50px);
        // height: 200px;
        position: relative;
        // top: -5px;
        margin: 0px;
        // margin-left: 5px;
        // @extend .card;
        // padding-right: 10px;
        @extend .overflow-ellipsis;
        // span {
        //   display: inline-block;
        //   // width: calc(100% - 10px);
        //   height: auto;
        //   color: black;
        // }
        // svg {
        //   // position: relative;
        //   right: 8px;
        //   top: 8px;
        //   left: auto;
        // }
      }
      .restaurant-name.short-name,
      .restaurant-name.long-name {
        color: black;
      }
    }

    .restaurant-work-status {
      // @extend .card;
      z-index: 1;
      width: 100%;
      padding: 10px;

      background-color: var(--background-grey);
      span.restaurant-commission {
        display: inline-block;
        border-radius: 0px;
        margin: 0px;
        padding: 0px;
      }
      button.work {
        @extend .flex-by-row-left;
        color: white;
        border-radius: 10px;
        padding: 4px 10px;
        svg {
          font-size: 12px;
        }
        span {
          text-transform: none;
        }
      }
      button.work.commission {
        margin: 10px 0px;
      }

      button.work.no {
        background-color: green;
      }
      button.work.yes {
        background-color: red;
      }
    }
  }
  .address-marker.restaurant-marker.selected {
    color: red;
    span {
      border: 1px solid red;
      background-color: white;
      color: red;
      padding: 0px 5px;
    }
  }
  .address-marker.restaurant-marker.selected.confirmed {
    span {
      background-color: red;
      color: white;
      padding: 0px 5px;
    }
  }
  .address-marker.restaurant-marker.considering {
    color: green;
    span {
      background-color: green;
      color: white;
      padding: 0px 5px;
    }
  }
  .address-marker.restaurant-marker.show-details {
    width: 200px;
    border: 1px solid var(--text-grey);
    box-sizing: border-box;
    @extend .card;

    button.restaurant-details {
      .restaurant-name {
        white-space: normal;
      }
      z-index: 1;
    }
  }
  .address-marker.restaurant-marker.hide-details {
    width: 90px;
    button.restaurant-details {
      .restaurant-name {
        span {
          @extend .overflow-ellipsis;
        }
      }
    }
  }
}
.mode-select {
  @extend .flex-by-row-centered;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  button.mode {
    @extend .action-transparent;
    margin: 10px;
    svg {
      margin-left: 10px;
      display: none;
    }
    opacity: 0.3;
  }
  button.selected {
    opacity: 1;
    svg {
      display: block;
    }
  }
  button.show-filters {
    @extend .action-transparent;
    margin: 10px;
    position: absolute;
    right: 10px;
    svg {
      margin-left: 10px;
    }
  }
  .restaurant-filters {
    position: absolute;
    padding: 10px 0px;
    @extend .flex-by-column-left;
    top: var(--nav-height);
    @extend .card;
    right: 10px;

    button {
      margin: 10px;
      @extend .flex-by-row-left;
      svg {
        margin-left: 10px;
        display: none;
      }
      span {
        @extend .text-12-300;
        opacity: 0.5;
      }
    }
    button.selected {
      span {
        font-weight: 500;
        opacity: 1;
      }
      svg {
        display: block;
      }
    }
  }
}
.restaurant-legends {
  position: fixed;
  top: 0px;
  @extend .flex-by-row-centered;
  width: 100%;
  left: 0px;
  height: var(--nav-height);
  .meal-type {
    @extend .text-12-300;
    margin-right: 30px;
    svg {
      margin-right: 5px;
    }
  }
}
.stops-map.restaurants-map {
  height: calc(100vh - 100px - var(--nav-height));
  top: calc(var(--nav-height));
  width: 100vw;
  .address-marker.restaurant-marker {
    position: relative;
    // border: 1px solid grey;
    svg {
      font-size: 10px;
    }
    .restaurant-name {
      font-size: 10px;
      color: black;
    }
    .restaurant-notes {
      @extend .flex-by-row-left;

      .restaurant-gmv {
        background: rgb(95, 92, 255, 0.8);
        color: white;
        padding: 0px 5px;
        border: none;
        font-size: 8px;
        height: 12px;
        line-height: 12px;
      }
      .restaurant-commission {
        background: rgb(248, 114, 17, 0.8);
        color: white;
        padding: 0px 5px;
        border-radius: 0%;
        border: none;
        font-size: 8px;
        height: 12px;
        line-height: 12px;
        margin: 0px;
      }
      // width: 200px;
      // background-color: red;
    }
    // .restaurant-commission {
    //   background-color: red;
    //   position: absolute;
    //   width: 12px;
    //   height: 12px;
    //   color: white;
    //   line-height: 12px;
    //   text-align: center;
    // }
  }
  .address-marker.restaurant-marker.on-map {
    @extend .flex-by-column-top;
    padding-top: 10px;

    position: relative;

    svg {
      position: absolute;
      font-size: 15px;
      top: 0px;
      left: 0px;
    }
    span {
      font-size: 20px;
    }
    .short-name {
      display: inline-block;
    }
    .last-meal,
    .full-name {
      display: none;
    }
  }

  .address-marker.restaurant-marker.on-map.future-meal {
    color: var(--background-darkgreen);
  }
  .address-marker.restaurant-marker.on-map.same-day-meal {
    color: var(--background-purple);
  }
  .address-marker.restaurant-marker.on-map.past-meal {
    color: var(--background-orange);
  }

  .address-marker.restaurant-marker.on-map:hover {
    padding: 10px;
    color: var(--background-orange);
    background-color: white;
    width: 200px;
    .short-name {
      display: none;
    }
    .last-meal,
    .full-name {
      display: inline-block;
    }
  }
  .address-marker.restaurant-marker.on-map.center {
    color: red;
  }
}

.stop-on-map {
  position: relative;
  // border: 1px solid red;

  .address-marker.home-marker {
    // color: var(--background-purple);

    button.toggle-details {
      svg {
        font-size: 26px;
      }
    }
    @extend .flex-by-row-top;
    justify-content: flex-start;
    .order-name {
      @extend .flex-by-column-left;
      justify-content: flex-start;
      position: relative;
      // background-color: yellow;
      padding: 0px 0 0 8px;
      .order-stop-index {
        display: block;
        position: absolute;
        // width: 20px;
        // height: 20px;
        // border-radius: 50%;
        // padding: 0px;
        // margin: 8px;
        color: white;
        left: 0px;
        top: -2px;
        @extend .text-12-300;
        font-size: 9px;
        font-weight: 500;
      }
      .order-firstName {
        @extend .text-12-300;
        font-weight: 600;
        line-height: 1.2;
        // font-size: 11px;
        text-transform: capitalize;
        // background-color: var(--background-beige);
        position: relative;
        left: -12px;
        top: 0px;
        font-size: 9px;
        text-align: left;
        background-color: black;
        line-height: 1;
        margin-bottom: 3px;
        // padding: 0px 10px;
        // z-index: 1;
      }

      .order-eta {
        @extend .text-12-300;
        font-weight: 600;
        position: relative;
        top: 0px;
        left: -12px;
        width: 80px;
        // width: auto;
        text-align: left;
        // background-color: red;
        margin: 0px;
        font-size: 9px;
        line-height: 1;
      }
    }
  }
  .address-marker.home-marker.show-in-background {
    opacity: 0.2;
  }
  .driver-list-on-map {
    position: absolute;
    @extend .card;
    background-color: transparent;
    // padding: 10px;
    z-index: 10;
    opacity: 1;
    margin-top: 5px;
    @extend .flex-by-column-left;

    .driver-for-stop:first-child {
      margin-top: 0px;
    }
  }
}
.address-marker.restaurant-marker {
  // color: var(--background-orange);
  color: red;
}
