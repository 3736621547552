@import './index.scss';
.hero {
  background-color: var(--hero-color);
  width: 100%;
  padding: 30px 30px 0px 30px;
  //   margin-top: 10px;
  position: relative;
  @extend .flex-by-column-centered;
  //   align-items: flex-start;
  text-align: center;
  //   box-shadow: 0 4px 32px 0 rgba(117, 117, 117, 0.3);
  //   border: 1px solid rgba(217, 217, 217, 0.6);
  //   border-top: 0px;
  color: rgba(0, 0, 0, 0.8);
  //   color: white;
  .slogan {
    @extend .text-13-400;
    font-size: 23px;
    line-height: 1.4;
    // text-transform: capitalize;
    // @extend .text-title;
    // font-size: 22px;
    // line-height: 1.2;
    // letter-spacing: 1px;
    // font-size: 15px;
    // font-weight: 600;
    // font-family: 'Rock Salt';
    // letter-spacing: 2.5px;

    p {
      margin: 5px 0px;
    }
  }
  .notes {
    @extend .text-11-300;
    // width: 40%;
  }
  .deco {
    // color: var(--background-coral);
    // --fa-secondary-opacity: 1;
    // --fa-primary-color: var(--background-coral);
    // --fa-secondary-color: var(--background-purple);
    color: rgba(0, 0, 0, 0.8);
    font-size: 60px;
    position: absolute;
    right: 30px;
    bottom: 0px;
  }
}
.hero::after {
  content: '';
  display: block;
  width: 100%;
  height: 60px;
  background-color: var(--hero-color);
  position: absolute;
  top: 100%;
  left: 0px;
}
@media only screen and (min-width: 1000px) {
  .hero {
    position: fixed;
    top: 0px;
    z-index: 100;
  }
}
