@import './index.scss';
.menu-link {
  background-color: white;
  z-index: 1;
  height: 300px;
  @extend .flex-by-column-centered;
  top: calc(50vh - 150px);
  position: fixed;
  padding: 60px 40px 0px 40px;
  width: calc(100vw - 40px);
  left: 20px;
  .link-to-menu {
    @extend .text-13-400;
    text-transform: uppercase;
    // margin-bottom: 50px;
    position: fixed;
    top: calc(50vh - 90px);
    font-weight: 500;
  }
  .link {
    text-align: center;
    @extend .text-title;
    text-transform: lowercase;
    // @extend .text-13-400;
    // text-transform: uppercase;
    font-weight: 300;
    font-size: 14px;
    line-height: 1.5;
    letter-spacing: 1.2px;
    text-decoration: underline;
  }
  button.copy-link {
    margin-top: 40px;
    @extend .action-grey;
    // position: fixed;
    bottom: calc(50vh - 110px);
    width: 180px;

    svg {
      margin-right: 15px;
    }
    height: 50px;
  }
  button.close-button {
    top: calc(50vh - 155px);
    right: 16px;
  }
}
@media only screen and (min-width: 800px) {
  .menu-link {
    width: 800px;
    left: calc(50vw - 400px);
    button.close-button {
      right: calc(50vw - 400px);
    }
  }
}
