@import "./index.scss";
.cart-thumbnail {
  @extend .flex-by-column-centered;
  position: fixed;
  top: 0px;
  right: 0px;
  // padding-top: 7px;
  height: var(--nav-height);
  width: 80px;
  // width: auto;
  // background-color: var(--background-beige);
  // padding: 3% 0px;
  // height: 50px;
  // width: 100%;
  // height: 45px;
  box-sizing: border-box;
  // margin-top: 3%;
  // z-index: 3;
  // position: fixed;
  // border-bottom: 1px solid rgb(217, 217, 217);
  z-index: 101;
  .cart-content {
    // width: 100%;
    position: relative;
    // top: -5px;
    // margin-right: 10px;
    height: 29px;
    // padding-bottom: 5px;
    @extend .flex-by-row-centered;
    svg {
      font-size: 18px;
    }
    .cart-length {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-color: black;
      color: white;
      font-size: 11px;
      line-height: 18px;
      margin-left: 4px;
      text-align: center;
      border-radius: 50%;
      // position: relative;
      // top: -7px;
      // position: absolute;
      // top: 20px;
      // right: 0px;
    }
    .cart-length.paid {
      // @extend .blink;
    }
  }
  .arrive-by {
    @extend .text-12-300;
    text-transform: capitalize;
    font-size: 9px;
    letter-spacing: 1px;
    width: 100px;
    line-height: 15px;
    text-align: center;
  }
}
.cart-thumbnail.full {
  .cart-content {
    span.cart-length {
      background-color: green;
      // @extend .blink;
    }
  }
}
.cart-thumbnail.hide {
  opacity: 0;
}
