@import './index.scss';
.dish-image {
  display: block;
  align-self: stretch;
  //   position: absolute;
  position: relative;
  left: 0px;
  width: 40%;
  flex-grow: 1;
  background-position: center;
  background-size: cover;

  .count-in-cart {
    @extend .flex-by-column-centered;
    cursor: pointer;
    position: absolute;
    bottom: 20px;
    left: calc(50% - 45px);
    // width: 100%;
    background-color: rgba(0, 128, 0, 0.8);
    color: white;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    // box-shadow: 0 0 8px 2px rgba(28, 29, 33, 0.3);
    span {
      display: inline-block;
      text-align: center;
    }
    span.count {
      @extend .text-title;
      font-size: 24px;
    }
    span.already {
      margin-top: 2px;
    }
    span.already,
    span.in-cart {
      @extend .text-10-300;
      line-height: 15px;
    }
  }
  .option-index {
    position: absolute;
    top: 0px;
    left: 0px;
    @extend .flex-by-column-centered;
    width: 100%;
    height: 50px;
    background-color: rgba(0, 0, 0, 0.3);
    span {
      @extend .with-curls;
      @extend .text-18-400;
      font-family: 'Theano Didot', serif;
      text-transform: none;
      color: white;
    }
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
  }
}
.dish-info {
  text-align: center;
  align-self: stretch;
  padding-bottom: 20px;
}
.dish-description {
  // display: none;
  @extend .text-12-300;
  color: var(--text-black);
  // height: 48px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  width: 100%;
  overflow: hidden;
  // white-space: nowrap;
  text-overflow: ellipsis;
}
.restaurant-name {
  color: var(--text-grey);
  @extend .text-12-300;
  text-transform: capitalize;
  margin-bottom: 5px;
}
.dish-name {
  @extend .text-13-500;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.dish-note {
  @extend .text-title-16;
  margin: 10px 0px;
  text-transform: capitalize;
  svg {
    margin-right: 10px;
  }
}

.dish-price-wrapper {
  width: 100%;
  margin: 10px 0px 0px 0px;
  bottom: 220px;
  .dish-price {
    width: 100%;
    @extend .flex-by-row-centered;
    @extend .text-13-500;
    // letter-spacing: 0.6px;
    // font-size: 15px;
    color: var(--text-grey);
    text-transform: uppercase;
    line-height: 2;
    span.price-type {
      text-transform: capitalize;
      margin-right: 6px;
      width: 50%;
      text-align: right;
    }
    div.price-value {
      // width: 50%;
      // text-align: left;
      span {
        padding: 3px 6px;
        border-radius: 3px;
        font-weight: 500;
        color: black;
      }
    }
    div.price-value.doordash-price {
      span {
        text-decoration: line-through;
        padding: 0px;
      }
      span.not-delivered {
        text-decoration: none;
        text-transform: capitalize;
        display: inline-block;
        white-space: nowrap;
      }
    }
    div.price-value.our-price {
      span {
        font-weight: 500;
        padding: 0px;
      }
    }
  }
}
.dish-comment {
  box-shadow: 0 8px 8px 0 rgba(28, 29, 33, 0.25);
  margin: 0px;
  padding: 20px 20px 30px 20px;
  border-top: 1px solid var(--border-grey);
  width: 100%;
  height: 160px;
  @extend .flex-by-column-centered;
  label {
    @extend .text-12-300;
    text-transform: capitalize;
    margin-bottom: 5px;
  }
  textarea {
    // width: 100%;
    align-self: stretch;
    height: 90px;
    display: block;
    background: var(--background-grey);
    border: none;
    box-sizing: border-box;
    padding: 5px 10px;
    @extend .text-16-400;
    text-transform: lowercase;
  }
  textarea::placeholder {
    text-transform: none;
  }

  textarea:disabled:hover {
    cursor: not-allowed;
  }
}
.dish-info {
  align-self: stretch;
  flex-grow: 1;
  @extend .flex-by-column-between;
  .dish-info-upper,
  .dish-info-lower {
    position: relative;
    width: 100%;
  }
}
@media only screen and (min-width: 800px) {
  .dish-image {
    width: 100%;
    height: 200px;
  }
  .dish-info {
    padding: 15px 0px;
  }
  .dish-info-upper {
    .dish-name {
      margin: 0px;
      font-size: 12px;
    }
    .dish-description {
      margin-top: 5px;
      line-height: 1.5;
    }
    margin-bottom: 10px;
  }
  .dish-info-lower {
    // margin: 10px 0px 0px 0px;
    .dish-price-wrapper {
      margin: 0px;
    }
  }
  .dish-comment {
    box-shadow: none;
    border: none;
    padding: 0px 20px;
    margin: 24px 0px 0px 0px;
    textarea {
      font-size: 13px;
      letter-spacing: 0.8px;
    }
  }

  .dish-price-wrapper {
    // @extend .flex-by-row-centered;
    // margin-top: 20px;
    .dish-price {
      width: auto;

      span.price-type {
        // width: auto;
        width: 50%;
        display: inline-block;
        white-space: pre;
        margin-right: 8px;
      }
      span.price-value {
        width: 50%;
      }
    }
  }
}
