@import '../../index.scss';
.disclaimer {
  @extend .flex-by-column-top;
  h3 {
    position: fixed;
    top: 0px;
    left: 0px;
    margin: 0px;
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    background-color: white;
    box-shadow: 0 16px 32px 0px rgba(28, 29, 33, 0.12);
  }
  padding: 100px 20px;
  .timestamp {
    @extend .text-12;
    margin-bottom: 30px;
  }
  .section {
    @extend .text-12;
    @extend .flex-by-column;
    width: 100%;
    .section-title {
      @extend .title-14;
      display: inline-block;
      width: 100%;
      margin: 20px 0px;
      text-align: center;
    }
    p {
      text-align: justify;
      width: 100%;
      ul {
        margin: 24px 0px;
        padding-left: 16px;
        li {
          display: block;
          @extend .flex-by-row-left;
          @extend .text-12;
          margin-bottom: 12px;
          text-align: left;
        }
        li::before {
          display: inline-block;
          content: '';
          margin-right: 10px;
          width: 6px;
          height: 6px;
          border-radius: 50%;
          background-color: var(--eerie-black);
        }
      }
      a {
        text-decoration: underline;
      }
    }
    .section2 {
      width: 100%;
      margin: 10px 0px;
      .section-title {
        @extend .text-12-upper;
        width: 100%;
        margin: 15px 0px;
        text-align: left;
      }
      .section3 {
        .section-title {
          margin: 10px 0px;
          font-weight: normal;
        }
      }
    }
  }
}

@media only screen and (min-width: 400px) {
  .disclaimer {
    .section {
      max-width: 400px;
    }
  }
}
