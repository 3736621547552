@import './index.scss';
.weekly-calendar {
  @extend .flex-by-column-centered;
  width: 100%;
  padding: 15px 0px;
  margin-bottom: 10px;
  .month {
    font-family: 'Theano Didot', serif;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.6px;
    padding: 10px 12px;
    margin-bottom: 5px;
  }
  .days {
    width: 100%;
    @extend .flex-by-row-stretch;
    .move-week {
      width: 11%;
      max-width: 42px;
      padding: 0px;
      @extend .flex-by-column-centered;
    }
    .day {
      @extend .flex-by-column-centered;
      width: 11%;
      max-width: 42px;
      .day-of-week {
        font-size: 13px;
        // font-weight: 600;
        letter-spacing: 0.6px;
        color: rgb(117, 117, 117);
        // text-transform: uppercase;
        text-transform: capitalize;
        padding: 5px 12px;
      }
      .day-of-month {
        font-size: 13px;
        font-weight: 400;
        letter-spacing: 0.6px;
        padding: 5px 12px;
      }
    }
    .day:nth-child(2),
    .day:nth-last-child(2) {
      .day-of-month {
        color: #cbcbcb;
      }
    }
  }
}
