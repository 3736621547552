@import '../../index.scss';
@import '../tasting_menu/components/index.scss';
.confirm-window {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.confirm-alert {
  border: solid 1px rgb(217, 217, 217);
  z-index: 10;
  background: white;
  box-shadow: 0 0 0.5rem 0 rgba(0, 0, 0, 0.16);
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 2;
    letter-spacing: 0.6px;
    font-family: 'Montserrat', sans-serif;
    text-align: center;
  }
  .confirm-buttons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    button {
      font-size: 14px;
      text-transform: none;
      width: 120px;
      height: 40px;
      margin: 20px;
      border: 1px solid black;
    }
  }
}

.yes-button {
  background-color: black;
  color: white;
}
/*.no-button {
  color: #ccc;
  border: 1px solid #ccc;
} */
