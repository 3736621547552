@import './index.scss';
@import './DriverList.scss';
.select-stop {
  @extend .flex-by-row-left;
  button {
    display: block;
    border: 1px solid white;
    margin: 0px 0px 0px 10px;
    // box-shadow: rgba(28, 29, 33, 0.4) 0px 4px 32px 0px;
    @extend .flex-by-row-centered;
    background-color: white;
    span {
      @extend .text-title;
      font-size: 14px;
      font-weight: 400;
      // color: white;
    }
  }
  button.stop {
    width: 26px;
    height: 26px;
    // @extend .card;
    border-radius: 50%;
    padding: 0px;
    margin: 4px;
  }
  button.driver {
    @extend .driver-person;
    box-shadow: none;
    margin: 0px 0px 8px 8px;
  }
  button.selected {
    span {
      color: white;
    }
    border: none;
  }
}
