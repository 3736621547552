@import './index.scss';
.page {
  .list.stats {
    max-width: 100vw;
    @extend .flex-by-row-left;
    flex-wrap: wrap;
    padding: 30px;
    padding-top: calc(var(--nav-height) + var(--subnav-height)); //#{$body_padding}
    .meal-select-wrapper {
      position: fixed;
      top: var(--nav-height);
      width: 100vw;
      left: 0px;
      z-index: 3;
      height: var(--subnav-height);
      @extend .flex-by-row-centered;
      margin: 0px;
      background-color: var(--background-beige);
      box-shadow: rgba(28, 29, 33, 0.2) 0px 4px 4px 0px;
      button {
        margin-right: 20px;
      }
      button:last-child {
        margin-right: 0px;
      }
      button.selected {
        span {
          font-weight: 500;
          border-bottom: 1px solid black;
        }
      }
    }
    .growth-chart {
      width: auto;
      position: relative;
      @extend .flex-by-row-left;
      flex-wrap: wrap;
      padding-top: 20px;
      .meal-select-wrapper {
        top: calc(var(--nav-height) + var(--subnav-height));
        border-top: 1px solid black;
      }
    }
    .chart-container {
      @extend .flex-by-column-right;
      @extend .card;
      margin: 20px;
      position: relative;
      padding: 20px;
      .weekly-chart {
        width: 500px;
        background-color: white;
        padding: 20px;
      }
      button: {
        margin-right: 20px;
      }
    }
  }
}
