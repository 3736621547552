@import "../../index.scss";
.back-button {
  @extend .flex-by-row-centered;
  width: var(--nav-height);
  height: var(--nav-height);
  position: absolute;
  padding: 0px;
  left: 0px;
  top: 0px;
  z-index: 100;
  svg {
    font-size: 18px;
  }
  @media only screen and (min-width: 800px) {
    left: 10vw;
  }
}
.back-button.left-end {
  @media only screen and (min-width: 800px) {
    left: 0px;
  }
}
