@import './index.scss';
.paypal-button-container {
  width: 200px;
  z-index: 0;
  @extend .flex-by-column-centered;
  .delivery-info {
    margin-top: 0px;
    width: 100%;
  }
  .processing {
    position: fixed;
    top: 0px;
    left: 0px;
    color: white;
    background: rgba(0, 0, 0, 0.8);
    height: 100vh;
    width: 100vw;
    z-index: 101;
    @extend .flex-by-row-centered;
    @extend .text-12-300;
  }
  // .place-order.waiting {
  //   visibility: hidden;
  // }
}
