@import '../../index.scss';
.form-container.enter-password {
  @extend .flex-by-column-around;
  box-sizing: border-box;
  padding: 30px 60px;
  height: 300px;
  top: calc(50vh - 150px);
  .form-body {
    margin: 0px;
  }
}
