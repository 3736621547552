@import "./index.scss";
.form-section.delivery-info {
  .edit-delivery {
    position: absolute;
    right: 10px;
    top: 5px;
    svg {
      font-size: 18px;
    }
  }
  .form-row {
    .geosuggest {
      margin: 0px;
      border: 0px;
      .geosuggest__input-wrapper {
        input.geosuggest__input {
          border-bottom: 1px solid rgb(219, 217, 217);
          margin: 0px;
        }
      }
      ul.geosuggest__suggests {
        padding: 5px 0px;
        li {
          @extend .flex-by-row-left;
          align-items: flex-start;
          padding: 10px 20px;
          border-bottom: 1px solid var(--background-grey);
          height: 106px;
          span {
            // white-space: pre;
            display: block;
          }
        }
      }
    }
    button.save-ring {
      padding: 0px;
      @extend .flex-by-row-top-left;
      @extend .text-12-300;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      svg {
        border: 1px solid black;
        margin: 5px 10px 0px 0px;
        border-radius: 3px;
        padding: 2px;
        width: 17px;
        height: 17px;
        box-sizing: border-box;
      }
      span {
        text-align: left;
      }
    }
    button.save-ring.unchecked {
      svg {
        color: transparent;
      }
      span {
        text-align: left;
      }
    }
    input {
      @extend.text-16-400;
    }
    span {
      @extend.text-12-400;
      letter-spacing: 0.8px;
      text-transform: none;
    }
    .form-field-wrapper {
      padding: 10px 0px;
    }
    .form-field-wrapper.address-room-info {
      span {
        text-transform: capitalize;
      }
    }

    textarea {
      background: #f5f5f5;
      border: none;
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      @extend .text-12-400;
      text-transform: none;
      letter-spacing: 0.74px;
    }
    textarea::placeholder {
      text-transform: none;
    }
    .error-message::before {
      content: "";
    }
    .error-message::after {
      content: "";
    }
    .PhoneInputCountry {
      display: none;
    }
  }
  .form-row.shipping-disclaimer {
    margin-top: 50px;
    padding: 20px;
    background-color: var(--background-grey);
    text-align: center;
  }
}
.checkout-form .list .checkout {
  .form-section.delivery-info.paypal-delivery {
    // @extend .card;
    margin: 0px;
    padding: 20px;
    width: 100vw;
    .form-section-title {
      margin-bottom: 30px;
    }

    .form-row {
      width: 100%;
      // background-color: pink;
      margin-bottom: 20px;
      button.save-ring {
        padding: 0px;
        @extend .flex-by-row-left;
        @extend .text-12-300;
        font-size: 16px;
        font-weight: 400;
        svg {
          border: 1px solid black;
          margin: 0px 10px 0px 0px;
          border-radius: 3px;
          padding: 2px;
          width: 17px;
          height: 17px;
          box-sizing: border-box;
        }
      }
      button.save-ring {
        padding: 0px;
        @extend .flex-by-row-left;
        @extend .text-12-300;
        font-size: 16px;
        font-weight: 400;
        svg {
          border: 1px solid black;
          margin: 0px 10px 0px 0px;
          border-radius: 3px;
          padding: 2px;
          width: 17px;
          height: 17px;
          box-sizing: border-box;
        }
      }
      button.save-ring.unchecked {
        svg {
          color: transparent;
        }
      }
      .form-field-wrapper {
        width: 100%;
        @extend .flex-by-column-left;
        // align-items: flex-start;
        // color: rgb(117, 117, 117);
        label {
          @extend .text-14-300;
          text-transform: capitalize;
          font-weight: 500;
        }
        span {
          // text-align: right;
          @extend .text-14-300;
          font-weight: 300;
          padding: 8px 0px;
        }
      }
    }
  }
}
@media only screen and (min-width: 500px) {
  .form-section.delivery-info {
    .form-row {
      input {
        font-size: 12px;
      }
      button.save-ring {
        font-size: 12px;
      }
    }
  }
}
