@import "../tasting_menu/components/index.scss";
.container {
  position: relative;
  display: flex;
  // min-width: calc(var(--meal-height) * var(--meal-width-height-ratio));
  // max-width: calc(var(--meal-height) * var(--meal-width-height-ratio));
  min-width: 40%;
  max-width: 40%;
  align-self: stretch;
  // flex-grow: 1;
  object-fit: cover;
}
.pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fade 4.5s infinite;
  animation: fade 4.5s infinite;
}

// n: how many images you are using
// a: how long you want each image to display for
// b: how long you want each fade transition to last
// animation-duration = t = (a + b) * n
// animation-delay = t/n or a + b

// a: 1s
// b: 0.5s
// n: 3
// t: 4.5s
// d: 1.5s

// 0%
// a/t * 100% = 22%
// (a + b) / t * 100% = 33%
// 100% - (b/t * 100%) = 89%
// 100%

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  22% {
    opacity: 1;
  }
  33% {
    opacity: 0;
  }
  89% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height: var(--meal-height);
    display: block;
    .pic {
      height: var(--meal-height);
    }
  }
  .container.event {
    height: 40vh;
    .pic {
      height: 40vh;
    }
  }
}
