@import './MenuCalendar.scss';
.restaurant-commission {
  @extend .text-13-400;
  padding: 5px;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  @extend .flex-by-row-centered;
}
.connector:last-child {
  display: none;
}
.restaurant-commission.original {
  border: 1px solid black;
  position: relative;
}

.restaurant-commission.now {
  background-color: black;
  border: 1px solid black;
  color: white;
}
.restaurant-commission.target {
  background-color: var(--background-orange);
  border: 1px solid var(--background-orange);
  color: white;
}
.calendar-row.week-menu.stats {
  @extend .flex-by-column-centered;
  padding-top: 142px;
  position: relative;
  .stats-section {
    width: 100%;
    @extend .flex-by-row-between;
    height: 100px;
    .stats-row {
      @extend .text-title;
      letter-spacing: 1px;
    }
    .commission-progress {
      @extend .flex-by-row-right;
      width: auto;
      .connector {
        width: 10px;
        height: 2px;
        border-bottom: 2px solid black;
        flex-grow: 1;
        flex-shrink: 1;
      }

      // .restaurant-commission.no-commission {
      //   display: none;
      // }
    }
  }
  .stats-section.weekly {
    width: auto;
    .commission-progress {
      .connector {
        width: 25px;
      }
      .restaurant-commission {
        width: 35px;
        height: 35px;
        position: relative;
        font-size: 12px;
      }

      .restaurant-commission::after {
        display: block;
        position: absolute;
        @extend .text-12-300;
        bottom: -35px;
        color: black;
        text-transform: capitalize;
      }
      .restaurant-commission.original::after {
        content: 'Original';
      }
      .restaurant-commission.now::after {
        content: 'now';
      }
      .restaurant-commission.target::after {
        content: 'Target';
      }
    }
  }
  .weekly-details {
    width: 100%;
    @extend .flex-by-column-left;
    padding: 0px;
    .range-group {
      @extend .flex-by-row-left;
      flex-wrap: wrap;
      padding: 20px 20px 0px 50px;
      position: relative;
      width: 100%;
      border-bottom: 1px solid black;
      .range-name {
        position: absolute;
        top: 50px;
        left: -8px;
        // padding-left: 10px;
        @extend .text-12-300;
        line-height: 50px;
        height: 50px;
        width: 90px;
        // background-color: red;
        transform: rotate(270deg);
        text-align: center;
        // writing-mode: vertical-rl;
        // text-orientation: upright;
      }
      .meal-accounting {
        width: 260px;
        margin: 0px 20px 20px 0px;
        height: 140px;
        position: relative;
        @extend .card;
        padding: 20px;
        @extend .flex-by-column-centered;
        flex-grow: 0;
        flex-shrink: 0;
        display: inline-block;

        .restaurant-name {
          display: inline-block;
          width: 100%;
          @extend .overflow-ellipsis;
          @extend .text-12-300;
          margin-bottom: 10px;
        }
        .stats-section {
          height: 60px;
          .restaurant-commission {
            width: 40px;
            height: 40px;
            font-size: 12px;
          }
        }
      }
      .meal-accounting.need-to-negotiate {
        background-color: #ccc;
      }
    }
    .range-group:last-child {
      border-bottom: none;
    }
    // transition: height 0.3s ease-in-out;
    // height: 20px;
    overflow: auto;
    position: relative;
    top: 0px;
  }

  .weekly-details.show {
    transition: max-height 0.3s ease-in-out;
    max-height: 200vh;
  }
  .weekly-details.hide {
    max-height: 0px;
    transition: max-height 0.3s ease-in-out;
  }
  .meal-wrapper.weekly {
    width: 100%;
    @extend .flex-by-column-left;
    padding: 20px 0px;
    @extend .card;
    border-bottom: 1px solid #ccc;
    border-top: 1px solid #ccc;
    position: absolute;
    top: 0px;
    left: 0px;
    .weekly-summary {
      //   width: 100%;
      //   max-width: 500px;
      @extend .flex-by-row-between;
      .weekly-name {
        @extend .text-title;
        margin: 0px 20px 0px 50px;
        width: 300px;
      }
      .weekly.stats-section {
        @extend .flex-by-row-between;
        width: 300px;
      }
      button {
        margin-left: 80px;
        svg {
          font-size: 30px;
        }
      }
    }
  }
}
.edit-commission {
  background-color: white;
  z-index: 1;
  @extend .flex-by-column-centered;
  padding: 40px;
  position: fixed;
  top: var(--nav-height);
  .form-title {
    @extend .text-title;
  }
  .commission-value {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-top: 20px;
    @extend .text-12-400;
    font-size: 15px;
  }
  .commission-type {
    @extend .text-12-400;
    margin-top: 20px;
  }
  input {
    margin: 20px 0px 0px 0px;
    text-align: center;
  }

  button {
    @extend .action-black;
    margin-top: 50px;
  }
}
